import "./style.css";
import store from "../../../../store/store";
import React from "react";
import { useNavigate } from "react-router";
/*import {API_URL} from '@src/config.js';*/

export default function BasicMenu(props) {
  const navigate = useNavigate();
  return (
    <div className="BasicMenu">
      {props.list.map((item) => (
        <button
          onClick={() => {
            if (item.isExternal) {
              window.location.href = item.link;
            } else {
              navigate(item.link);
            }
          }}
          className="headline"
        >
          {item.icon && (
            <img src={require("../../../../assets/images" + item.icon)} />
          )}
          {item.text}
        </button>
      ))}
    </div>
  );
}
