import React from "react";
import { Link } from "react-router-dom";
// import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import "./style.css";
import store from "../../../store/store";
// import TopMenu from './TopNav.js';

import IntroWhite from "../../../assets/images/bottom-navbar/intro-white-icon.png";
import IntroYellow from "../../../assets/images/bottom-navbar/intro-yellow-icon.png";
import WalletWhite from "../../../assets/images/bottom-navbar/wallet-white-icon.png";
import WalletYellow from "../../../assets/images/bottom-navbar/wallet-yellow-icon.png";
import MarketWhite from "../../../assets/images/bottom-navbar/market-white-icon.png";
import MarketYellow from "../../../assets/images/bottom-navbar/market-yellow-icon.png";
import MegaphoneWhite from "../../../assets/images/bottom-navbar/megaphone-white-icon.png";
import MegaphoneYellow from "../../../assets/images/bottom-navbar/megaphone-yellow-icon.png";
import ProfileWhite from "../../../assets/images/bottom-navbar/profile-white-icon.png";
import ProfileYellow from "../../../assets/images/bottom-navbar/profile-yellow-icon.png";

const selectNavBarBottom = (state) => state.navBarsDM.bottom;
const selectNavBarsSelectedSection = (state) => state.navBarsSelectedSection;
const selectUser = (state) => state.user;

// const selectProfile = (state) => state.profile;
// const selectWallet = (state) => state.wallet;

export default function NavBottom(props) {
  const navigate = useNavigate();

  //   const profile = useSelector(selectProfile);
  //   const wallet = useSelector(selectWallet);

  const isDisplay = useSelector(selectNavBarBottom);
  const selectedSection = useSelector(selectNavBarsSelectedSection);
  const user = useSelector(selectUser);

  //which profile
  //   const [ProfileLink, setProfileLink] = useState("/Profile");
  //   let uu = store.subscribe(render);
  //   function render() {
  //     if (!profile) {
  //       setProfileLink("/Profile");
  //     } else {
  //       if (profile.accountType === 30) {
  //         setProfileLink("/Profile/AgencyIPO/Profile");
  //       } else {
  //         if (profile.accountType === 14) {
  //           setProfileLink("/Profile/SMI/Profile");
  //         } else {
  //           setProfileLink("/Profile");
  //         }
  //       }
  //     }
  //   }

  //   let navBottomPos =
  //     store.getState().window_size.height -
  //     (store.getState().window_size.width * 19) / 100;
  function updateViewportHeight() {
    store.dispatch({
      type: "ui/windowInnerSize/add",
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  }
  return (
    isDisplay && (
      <div className="NavBottom headline">
        {!user && (
          <div className="NavBottom__Btn">
            <img
              src={selectedSection === "Home" ? IntroYellow : IntroWhite}
              alt="Home botton"
              className={
                "NavBottom__BtnIcon " 
                // +
                // (selectedSection === "Home" ? "svg-yellow" : "svg-white")
              }
              onClick={async () => {
                updateViewportHeight();
                checkForRefresh("Home");
                store.dispatch({
                  type: "ui/openingViewDM/toggle",
                  payload: false,
                });
                // await persistor.purge();
                navigate("/");
                store.dispatch({
                  type: "ui/navBarsSelectedSection/update",
                  payload: "Home",
                });
              }}
            />

            <p
              className="NavBottom__BtnTxt "
              style={{ color: selectedSection === "Home" ? "#ffbf12" : "#fff" }}
            >
              Home
            </p>
          </div>
        )}
        <div className="NavBottom__Btn">
          <img
            src={selectedSection === "Wallet" ? WalletYellow : WalletWhite}
            className={
              "NavBottom__BtnIcon " 
              // +
              // (selectedSection === "Wallet" ? "svg-yellow" : "svg-white")
            }
            alt="Wallet button"
            onClick={() => {
              updateViewportHeight();
              checkForRefresh("Wallet");
              navigate(store.getState().user ? "/wallet" : "/demo");
              if (!store.getState().profile) {
                store.dispatch({
                  type: "ui/navBarsSelectedSection/update",
                  payload: "Wallet",
                });
              }
            }}
          />
          <p
            className="NavBottom__BtnTxt"
            style={{ color: selectedSection === "Wallet" ? "#ffbf12" : "#fff" }}
          >
            Wallet
          </p>
        </div>
        <div className="NavBottom__Btn">
          <img
            src={selectedSection === "Exchange" ? MarketYellow : MarketWhite}
            className={
              "NavBottom__BtnIcon "
              //  +
              // (selectedSection === "Exchange" ? "svg-yellow" : "svg-white")
            }
            alt="Exchange button"
            onClick={() => {
              updateViewportHeight();
              checkForRefresh("Exchange");
              navigate(store.getState().user ? "/exchange" : "/demo");
              if (!store.getState().profile) {
                store.dispatch({
                  type: "ui/navBarsSelectedSection/update",
                  payload: "Exchange",
                });
              }
              store.dispatch({
                type: "ui/exchangeSearchDM/toggle",
                payload: false,
              });
              store.dispatch({
                type: "app/salesFeedNewPageData/add",
                payload: null,
              });
              store.dispatch({
                type: "app/salesFeedPageIndex/add",
                payload: 1,
              });
              store.dispatch({
                type: "app/salesFeedSelectedCategory/add",
                payload: 3,
              });
              store.dispatch({
                type: "app/salesFeedPriceCategoryDirection/add",
                payload: "High",
              });
            }} /*onClick={()=>(!ShowTopMenu) ?SetShowTopMenu(true):null}*/
          />
          <p
            className="NavBottom__BtnTxt"
            style={{
              color: selectedSection === "Exchange" ? "#ffbf12" : "#fff",
            }}
          >
            Exchange
          </p>
        </div>
        <div className="NavBottom__Btn">
          <img
            src={selectedSection === "News" ? MegaphoneYellow : MegaphoneWhite}
            className={
              "NavBottom__BtnIcon " 
              // +
              // (selectedSection === "News" ? "svg-yellow" : "svg-white")
            }
            alt="Rewards"
            onClick={() => {
              updateViewportHeight();
              checkForRefresh("News");
              navigate(store.getState().user ? "/news" : "/demo");
              if (!store.getState().profile) {
                store.dispatch({
                  type: "ui/navBarsSelectedSection/update",
                  payload: "News",
                });
              }
            }} /*onClick={()=>(!ShowTopMenu) ?SetShowTopMenu(true):null}*/
          />
          <p
            className="NavBottom__BtnTxt"
            style={{ color: selectedSection === "News" ? "#ffbf12" : "#fff" }}
          >
            News
          </p>
        </div>
        <div className="NavBottom__Btn">
          <img
            src={selectedSection === "Profile" ? ProfileYellow : ProfileWhite}
            className={
              "NavBottom__BtnIcon " 
              // +
              // (selectedSection === "Profile" ? "svg-yellow" : "svg-white")
            }
            alt="Profile"
            onClick={() => {
              updateViewportHeight();
              checkForRefresh("Profile");
              navigate(store.getState().user ? "/profile" : "/demo");
              if (!store.getState().profile) {
                store.dispatch({
                  type: "ui/navBarsSelectedSection/update",
                  payload: "Profile",
                });
              }
            }} /*onClick={()=>(!ShowTopMenu) ?SetShowTopMenu(true):null}*/
          />
          <p
            className="NavBottom__BtnTxt"
            style={{
              color: selectedSection === "Profile" ? "#ffbf12" : "#fff",
            }}
          >
            Profile
          </p>
        </div>
      </div>
    )
  );

  function checkForRefresh(index) {
    if (selectedSection === index) {
      // console.log("jjkjk");
      store.dispatch({ type: "ui/toRefresh/toggle", payload: null });
      store.dispatch({ type: "ui/toRefresh/toggle", payload: true });
      window.scrollTo({ top: 0 });
    }
  }
}
