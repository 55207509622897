import "./style.css";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { API_URL } from "../../../config";

import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
import Loading from "../../loading";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectTokens = (state) => state.tokens;
const selectTypeformIds = (state) => state.typeFormIds;

export default function IssuerReg_BrandReport() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const tokens = useSelector(selectTokens);
  const navigate = useNavigate();

  const typeformIds = useSelector(selectTypeformIds);
  const [brandCalcResponse, setbrandCalcResponse] = useState(null);
  useEffect(() => {
    console.log(1);
    console.log(typeformIds.responseId);
    if (typeformIds.responseId) {
      console.log(2);
      fetchBrandCalcResponse(typeformIds.responseId);
    }
  }, [typeformIds]);

  function fetchBrandCalcResponse(typeformResponseId) {
    var axios = require("axios");

    var config = {
      method: "get",
      url: API_URL + "/api/issuers/potential?responseId=" + typeformResponseId,
      headers: {
        Authorization: tokens.accessToken, // can't call hooks from this function (to get the accessToken)
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setbrandCalcResponse(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getFullDate() {
    let date = new Date();
    let text =
      date.getDate() +
      " " +
      getMonthName(date.getMonth()) +
      " " +
      date.getFullYear() +
      " " +
      fixDigits(date.getHours()) +
      ":" +
      fixDigits(date.getMinutes());
    return text;
  }
  function getMonthName(DateNum) {
    const DatesNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return DatesNames[DateNum];
  }
  function fixDigits(num) {
    if (num < 10) {
      return "0" + num;
    } else {
      return num;
    }
  }

  function logIn() {
    var axios = require("axios");

    var config = {
      method: "get",
      url: API_URL + "/auth/fast-login",
      params: {
        role: "issuer",
      },
    };

    axios(config)
      .then(async function (response) {
        console.log(JSON.stringify(response.data));
        store.dispatch({
          type: "domain/user/addData",
          payload: response.data.user,
        });
        if (response.data.user.accountType < 4) {
          store.dispatch({
            type: "domain/signUpToken/addToken",
            payload: response.data.accessToken,
          });
          store.dispatch({
            type: "domain/tokens/addRefreshToken",
            payload: response.data.refreshToken,
          });
          // setdisplayContinueRegisterMsg(true);
        } else {
          store.dispatch({
            type: "domain/tokens/addTokens",
            payload: response.data,
          });
          navigate("/profile", { replace: true });
        }
      })
      .catch(function (error) {
           });
  }

  return brandCalcResponse === null ? (
    <Loading
      text={
        <p
          className="glow-yellow-white unnamed-character-style-1"
          style={{ textAlign: "center", fontSize: "18px", lineHeight: "25px" }}
        >
          Preparing <br /> your brand report…
        </p>
      }
    />
  ) : (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="ValidBrand Page headline"
        style={{
          height: windowInnerSize.height,
          textAlign: "center",
          paddingBottom: "90px",
        }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          style={{ top: "10vw" }}
          onClick={() => {
            navigate(-1);
          }}
        />

        <p
          className="glow-yellow-white unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "50px 0 0",
            fontSize: "32px",
          }}
        >
          BRAND REPORT
        </p>
        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "10px 0",
            fontSize: "16px",
            color: "#909090",
          }}
        >
          {getFullDate()}
        </p>
        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "10px 0",
            fontSize: "11px",
            lineHeight: "15px",
            color: "#707070",
          }}
        >
          This short ‘Brand Report’ is based on your information
          <br />
          and our brand calculator, which includes a 1%
          <br />
          conversion rate projection over 12 months
        </p>

        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "30px 0 10px",
            fontSize: "18px",
            lineHeight: "23px",
            color: "#909090",
            fontWeight: "bolder",
          }}
        >
          your current brand value
          <br />
          is in the range of
        </p>
        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "25px 0",
            fontSize: "38px",
            lineHeight: "66px",
            color: "#fff",
            fontWeight: "bolder",
          }}
        >
          ±$
          {brandCalcResponse.currentBrandValue.min}
          -$
          {brandCalcResponse.currentBrandValue.max}
        </p>

        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "30px 0 10px",
            fontSize: "18px",
            lineHeight: "23px",
            color: "#909090",
            fontWeight: "bolder",
          }}
        >
          Your Approximate earnings <br />
          (next 12 months)
        </p>
        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "25px 0",
            fontSize: "38px",
            lineHeight: "66px",
            color: "#fff",
            fontWeight: "bolder",
          }}
        >
          ±$
          {brandCalcResponse.earnings.min}
          -$
          {brandCalcResponse.earnings.max}
        </p>

        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "30px 0 10px",
            fontSize: "18px",
            lineHeight: "23px",
            color: "#909090",
            fontWeight: "bolder",
          }}
        >
          Your Brand worth Goal <br />
          (next 24 months){" "}
        </p>
        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "25px 0",
            fontSize: "38px",
            lineHeight: "66px",
            color: "#fff",
            fontWeight: "bolder",
          }}
        >
          ±$
          {brandCalcResponse.goalBrandValue.min}
          -$
          {brandCalcResponse.goalBrandValue.max}
        </p>

        <p
          className="headline"
          style={{
            position: "relative",
            margin: "25px 5vw",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#909090",
            fontWeight: "lighter",
          }}
        >
          To get listed on PassionStocks Market, book a 15-minute meeting with
          our team for verification, VIP registration & guidance.
        </p>

        <button
          className="btn--yellow-black headline"
          style={{
            position: "relative",
            margin: "25px 0 ",
            fontSize: "16px",
            lineHeight: "28px",
            fontWeight: "bolder",
            padding: "0 38px",
            borderRadius: "75px",
            background:
              API_URL === "https://demo-api.passionstocks.com"
                ? "#909090"
                : "#ffbf12",
          }}
        >
          Book a meeting
        </button>
        {API_URL === "https://demo-api.passionstocks.com" && (
          <div>
            <p
              className="headline"
              style={{
                position: "relative",
                margin: "25px 5vw",
                fontSize: "14px",
                lineHeight: "21px",
                color: "#ffbf12",
                fontWeight: "lighter",
              }}
            >
              Verification and setting up the account on the platform is done at
              the creator's meeting with our team. <br />
              In the demo version you can now switch to a fake creator account
            </p>
            <button
              className="btn--yellow_border-white"
              style={{
                margin: "20px auto 0",
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: "normal",
              }}
              onClick={logIn}
            >
              Demo: Creator profile
            </button>
          </div>
        )}
      </div>
    </AnimateKeyframes>
  );
}
