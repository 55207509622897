import "./style.css";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/import store from '../../../store/store';

import fixFractionDigits from "../../../helpers/fixFractionDigits";

export function StockTradeHistory(props) {
  function getTradesList(TradeHistoryList) {
    const THListItem = TradeHistoryList
      ? TradeHistoryList
      : [
          //template example:
          // {
          //   IDate: "08/02",
          //   IVolume: "+1 Stk",
          //   IMarketPrice: "P140",
          //   IPrice: "P221",
          //   IROI: "P9,5",
          // },
          // {
          //   IDate: "08/02",
          //   IVolume: "-1 Stk",
          //   IMarketPrice: "P135",
          //   IPrice: "P311",
          //   IROI: "P9,5",
          // }
        ];
    let TradeHListHtml = [];

    let userId = store.getState().profile.id;
    for (let i = 0; i < THListItem.length; i++) {
      TradeHListHtml.push(
        <li key={"TH" + i}>
          <span>
            {TradeHistoryList
              ? new Date(THListItem[i].date).getDate() +
                "/" +
                (new Date(THListItem[i].date).getMonth() + 1)
              : THListItem[i].IDate}
          </span>
          <span>
            {(THListItem[i].buyer === userId ? "+ " : "- ") +
              THListItem[i].volume +
              " PS"}
          </span>
          <span>
            {THListItem[i].currency.symbol +
              fixFractionDigits(THListItem[i].price)}
          </span>
          <span
            className={
              THListItem[i].buyer === userId
                ? "NegativeNum"
                : "PositiveGreenNum"
            }
          >
            {THListItem[i].currency.symbol +
              fixFractionDigits(THListItem[i].price * THListItem[i].volume)}
          </span>
          <span
            className={
              THListItem[i].profit < 0
                ? "NegativeNum"
                : THListItem[i].profit > 0
                ? "PositiveGreenNum"
                : ""
            }
          >
            {THListItem[i].seller === userId && THListItem[i].profit
              ? (THListItem[i].profit < 0 ? "-" : "") +
                THListItem[i].currency.symbol +
                Math.abs(fixFractionDigits(THListItem[i].profit))
              : ""}
          </span>
        </li>
      );
    }
    return <ul>{TradeHListHtml}</ul>;
  }
  return (
    <div className="StockTradeHistory">
      <p id="TradeHistoryTitle" className="StockTradeHistory__Title">history</p>
      <svg id="TradeHTitleLine" className="StockTradeHistory__TitleLine" height="0.5vw" width="33%">
        <line
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          stroke="white"
          stroke-width="100%"
          stroke-linecap="butt"
        />
      </svg>
      <p id="THColumns" className="StockTradeHistory__ColumnsTitles unnamed-character-style-1">
        <span>Date</span>
        <span>Volume</span>
        <span>
          Market
          <br />
          Price
        </span>
        <span>Price</span>
        <span>Profit</span>
      </p>
      <svg id="TradeHListLine" className="StockTradeHistory__ColumnsLine" height="0.4vw" width="100%">
        <line
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          stroke="white"
          stroke-width="5vw"
          stroke-linecap="butt"
        />
      </svg>
      <div id="TradeHistoryList" className="StockTradeHistory__List">
        {getTradesList(props.investmentData.trades)}
      </div>
      <div id="TotalTradeH" className="StockTradeHistory__TotalContainer">
        <div id="DaysOwnedDiv" className="STTotal">
          <p>{props.investmentData.daysHolding}</p>
          <p>
            Days <br /> owned
          </p>
        </div>
        <div id="StockHoldingDiv" className="STTotal">
          <p>{props.investmentData.volume}PS</p>
          <p>
            Stock <br /> Holding
          </p>
        </div>
        <div id="CurrentPriceDiv" className="STTotal">
          <p>
            {props.investmentData.stock.currency.symbol +
              fixFractionDigits(props.investmentData.stock.marketPrice)}
          </p>
          <p>
            Current <br /> Price
          </p>
        </div>
        <div id="TotalSpendDiv" className="STTotal">
          <p>
            {props.investmentData.currency.symbol +
              fixFractionDigits(props.investmentData.totalSpend)}
          </p>
          <p>
            Total <br /> Spend
          </p>
        </div>
        <div id="TotalROIMadeDiv" className="STTotal">
          <p>
            {props.investmentData.totalProfit < 0 ? "-" : ""}
            {props.investmentData.currency.symbol}
            {fixFractionDigits(Math.abs(props.investmentData.totalProfit))}
          </p>
          <p>
            Total
            <br /> Profit {/*<br /> Made*/}
          </p>
        </div>
      </div>
    </div>
  );
}
