import "./TwoSteps.css";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/
import { useEffect, useState } from "react";
export default function TwoSteps(props) {
  const [forCalc, setforCalc] = useState(400);
  useEffect(() => {
    document.getElementById(
      props.stepSelected === 1 ? "OpacityButton__2" : "OpacityButton__1"
    ).style.opacity = 0.5;
    setTimeout(() => {
      document.getElementById(
        props.stepSelected === 1 ? "OpacityButton__1" : "OpacityButton__2"
      ).style.opacity = 1;
    }, 700);

    let c1 = document.getElementById(
      props.stepSelected === 1 ? "container1ForCalc" : "container2ForCalc"
    );
    if (c1) {
      setforCalc(c1.offsetHeight);
    }
  }, [props.stepSelected]);

  return (
    <div className="TwoSteps">
      <div className="" style={{ textAlign: "center" }}>
        <p
          className="unnamed-character-style-1"
          style={{
            margin: 0,
            color: "#ffbf12",
            lineHeight: "8vw",
            fontSize: "6vw",
          }}
        >
          2 STEPS
        </p>
        <p
          className="unnamed-character-style-1"
          style={{ margin: 0, lineHeight: "6vw", fontSize: "5vw" }}
        >
          FROM{" "}
          <span
            style={{ color: props.stepSelected === 1 ? "#ffbf12" : "#fff" }}
          >
            PASSION
          </span>{" "}
          TO{" "}
          <span
            style={{ color: props.stepSelected === 2 ? "#ffbf12" : "#fff" }}
          >
            STOCKS
          </span>
        </p>
        <div className="OpacityButtons">
          <div className="OpacityButtons__Background" />
          <div
            id="OpacityButton__1"
            className="OpacityButtons__Button unnamed-character-style-1"
            style={{
              // opacity: props.stepSelected === 1 ? 1 : 0.5,
              top: 0,
              left: 0,
            }}
            onClick={() => {
              props.setstepSelected(1);
            }}
          >
            1
          </div>
          <div
            id="OpacityButton__2"
            className="OpacityButtons__Button unnamed-character-style-1"
            style={{
              // opacity: props.stepSelected === 2 ? 1 : 0.5,
              top: 0,
              right: 0,
            }}
            onClick={() => {
              props.setstepSelected(2);
            }}
          >
            2
          </div>
          <div
            className="OpacityButtons__Ring"
            style={{
              top: 0,
              left: props.stepSelected === 1 ? 0 : "112px",
              transition: "all 0.7s",
            }}
          />
        </div>

        {props.stepSelected === 1 && (
          <div style={{ position: "relative", marginTop: "15vw" }}>
            <div style={{ position: "relative", marginBottom: "25vw" }}>
              <p
                className="unnamed-character-style-1"
                style={{ color: "#ffbf12" }}
              >
                STEP 1
              </p>
              <p
                className="unnamed-character-style-1"
                style={{
                  fontSize: "6.25vw",
                  lineHeight: "8vw",
                  marginBottom: "0",
                }}
              >
                Registration Process <br />
                for
                <br />
                PAssionstocks market
              </p>
              <svg height="2vw" width="35vw">
                <line
                  x1="0"
                  x2="100%"
                  y1="0"
                  y2="0"
                  stroke="#fff"
                  stroke-width="100%"
                  stroke-linecap="butt"
                />
              </svg>
            </div>

            <div style={{ position: "relative", margin: "15vw 5vw 0" }}>
              <div>
                <div id="container1ForCalc">
                  <svg
                    className="TwoSteps__TheProcessStagesLine"
                    height={forCalc + 40 + 60}
                    width="43"
                  >
                    <line
                      x1="0"
                      x2="100%"
                      y1="0"
                      y2="0"
                      stroke="#ffff"
                      stroke-width="100%"
                      stroke-linecap="butt"
                    />
                  </svg>{" "}
                  <div>
                    <div className="TwoSteps__ProcessNumber unnamed-character-style-1">
                      1
                    </div>
                    <p className="TwoSteps__Paragraph">
                      <h2>Creator Verification</h2>
                      To access the Brand Calculator, please click ‘Check if
                      you’re qualified’ for verification.
                    </p>
                  </div>
                  <div>
                    <div className="TwoSteps__ProcessNumber unnamed-character-style-1">
                      2
                    </div>
                    <p className="TwoSteps__Paragraph">
                      <h2>Brand Calculator</h2>
                      After verification, you’ll access the brand calculator to
                      obtain your ‘Brand Report,’ which includes your brand
                      value and its potential for listing on a stock exchange,
                      complete with timelines and goals.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="TwoSteps__ProcessNumber unnamed-character-style-1">
                    3
                  </div>
                  <p className="TwoSteps__Paragraph">
                    <h2>PassionStocks Market </h2>
                    Congratulations! <br />
                    You’ve entered the gateway to your personal brand’s journey
                    for the stock exchange right here on PassionStocks Market.
                    By being part of the PassionStocks Market, you’ll work
                    towards achieving the goals outlined in your brand report
                    and the timeline to eventually get listed on the stock
                    exchange.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.stepSelected === 2 && (
          <div style={{ position: "relative", marginTop: "15vw" }}>
            <div style={{ position: "relative", marginBottom: "25vw" }}>
              <p
                className="unnamed-character-style-1"
                style={{ color: "#ffbf12" }}
              >
                STEP 2
              </p>
              <p
                className="unnamed-character-style-1"
                style={{
                  fontSize: "6.25vw",
                  lineHeight: "8vw",
                  marginBottom: "0",
                }}
              >
                Passionstocks market
                <br />
                For
                <br />
                stock exchange
              </p>
              <svg height="2vw" width="35vw">
                <line
                  x1="0"
                  x2="100%"
                  y1="0"
                  y2="0"
                  stroke="#fff"
                  stroke-width="100%"
                  stroke-linecap="butt"
                />
              </svg>
            </div>
            <div style={{ position: "relative", margin: "15vw 5vw 0" }}>
              <div>
              <div id="container2ForCalc">
                  <svg
                    className="TwoSteps__TheProcessStagesLine"
                    height={forCalc + 40 + 60}
                    width="43"
                  >
                    <line
                      x1="0"
                      x2="100%"
                      y1="0"
                      y2="0"
                      stroke="#ffff"
                      stroke-width="100%"
                      stroke-linecap="butt"
                    />
                  </svg>{" "}
                  <div>
                    <div className="TwoSteps__ProcessNumber unnamed-character-style-1">
                      1
                    </div>
                    <p className="TwoSteps__Paragraph">
                      <h2>My brand</h2>
                      After you qualify for PassionStocks Market, access the ‘My
                      Brand’ dashboard where we help you with insights,
                      achievable goals, and tracking your path to a stock
                      exchange listing.
                    </p>
                  </div>
                  <div>
                    <div className="TwoSteps__ProcessNumber unnamed-character-style-1">
                      2
                    </div>
                    <p className="TwoSteps__Paragraph">
                      <h2>My profile</h2>
                      Your profile is where you share: <br />
                      1. Your background. <br />
                      2. Your current projects and how others can join you.{" "}
                      <br />
                      3. Giveaways.
                    </p>
                  </div>
                  <div>
                    <div className="TwoSteps__ProcessNumber unnamed-character-style-1">
                      3
                    </div>
                    <p className="TwoSteps__Paragraph">
                      <h2>PassionStocks Market</h2>
                      At PassionStocks Market, your community can become a part
                      of your brand and enjoy journey-based rewards as a gesture
                      of gratitude. If you go public, you can also share stocks
                      with them.
                    </p>
                  </div>
                  <div>
                    <div className="TwoSteps__ProcessNumber unnamed-character-style-1">
                      4
                    </div>
                    <p className="TwoSteps__Paragraph">
                      <h2>Megaphone </h2>
                      The megaphone is where you announce your community
                      giveaways, based on conditions aligned with your brand’s
                      values.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="TwoSteps__ProcessNumber unnamed-character-style-1">
                    5
                  </div>
                  <p className="TwoSteps__Paragraph">
                    <h2>Stock Exchange {"(IPO)"} </h2>
                    Congratulations! You’ve reached the stock exchange by simply
                    being yourself and pursuing your passion. You’ll find
                    success sooner than you can imagine. Make a living by
                    staying true to who you are.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
