import "./style.css";
import { AnimateKeyframes } from "react-simple-animate";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import store from "../../../../../../store/store";
import BackPage from "../../../../../../assets/images/common/back-page.png";
import DeleteIcon from "../../../../../../assets/images/menu/delete.png";
import BulletProcess from "../../../../../../components/common/BulletProcess";
import UpdateDeliveredStoryMention from "../../../../../../components/common/UpdateDeliveredStoryMention";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function DeleteAccount_IssuerStoryMention(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state !== "delete-account") {
      navigate("/menu");
    }
  }, [location]);

  const handleConfirmBtn = () => {
    navigate("/delete-account/issuer-giveaway-obligation", {
      state: "delete-account",
    });
  };
  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="DeleteAccount Page headline">
        {" "}
        <div style={{ display: "flex",justifyContent:'space-between', flexDirection: "column", minHeight: "97vh" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              position: "relative",
              marginTop: "20vw",
            }}
          >
            <img
              src={BackPage}
              alt=""
              // id=""
              className="back-btn"
              style={{
                position: "relative",
                top: 0,
                left: 0,
                marginRight: "12px",
              }}
              onClick={() => {
                navigate(-1);
              }}
            />
            <h1
              className="headline__menu"
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <img
                src={DeleteIcon}
                alt="Delete account"
                // className="Menu_AccountAndSetting__Icon"
              />
              Delete Account
            </h1>
          </div>
          <p
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "lighter",
              margin: "0 10vw",
            }}
          >
            If you intend to delete your account, please note that you are
            unable to do so until you fulfill your commitments to the
            individuals who have partnered with you. They have paid for a story
            mention, which must be delivered as agreed upon before proceeding
            with the account deletion.
            <br />
            <br />
            Please slide the toggle button to mark this task as complete.
            <br />
            <br />
            For your convenience, you can click on the share button to quickly
            obtain and share all the mentions at once.
          </p>

<UpdateDeliveredStoryMention deliveryList/>
          <p
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "lighter",
              margin: "0 10vw",
            }}
          >
            To maintain transparency and avoid any potential misunderstandings,
            we recommend taking a screenshot that includes all the mentions you
            have made. <br />
            This will serve as a record in case of any future inquiries or
            concerns.
          </p>
          <button
            className="DeleteAccount__Btn headline"
            onClick={handleConfirmBtn}
          >
            Confirm
          </button>

          <BulletProcess amount={3} checkedIndex={0} />
        </div>
      </div>
    </AnimateKeyframes>
  );
}
