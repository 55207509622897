import "./style.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";
import React from "react";
/*import {API_URL} from '@src/config.js';*/

// import {persistStore} from 'redux-persist';
import store from "../../../store/store";

// import { persistor } from "../../../store/store";

import AavatarImg from "../../../components/common/AvatarImg";

import BackPage from "../../../assets/images/common/back-page.png";
import PSLogoBW from "../../../assets/images/common/passionstocks-icon.png";
import FacebookLogoBW from "../../../assets/images/common/facebook-icon-white.png";
import InstagramLogoBW from "../../../assets/images/common/instagram-icon-white.png";
import YoutubeLogoBW from "../../../assets/images/common/youtube-icon-white.png";
import AcademyIcon from "../../../assets/images/menu/academy.png";

import clearStoreWithoutConst from "../../../utiles/clearStoreWithoutConst";
import loadUser from "../../../loaders/user";
import BasicMenu from "../../../components/common/menus/Basic";
import { API_URL } from "../../../config";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectProfile = (state) => state.profile;

export default function Menu() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Menu",
  });
  const navigate = useNavigate();
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const profile = useSelector(selectProfile);

  const userList = [
    {
      text: "Account & Settings",
      link: "/account-and-setting",
      icon: "/menu/setting.png",
    },
    {
      text: "Support",
      link: "/support",
      icon: "/menu/chat.png",
    },
    {
      text: "FAQS",
      isExternal:true,
      link: "https://passionstocks.helpsite.com",
      icon: "/menu/question.png",
    },
    {
      text: "Legal",
      link: "/legal",
      icon: "/menu/legal.png",
    },
    {
      text: "About",
      link: "/about",
      icon: "/menu/info.png",
    },
    {
      text: "Mission",
      link: "/mission",
      icon: "/menu/goal.png",
    },
    { text: "Careers", link: "/careers", icon: "/menu/bag.png" },

    {
      text: "Report A Bug",
      link: "/report-a-bug",
      icon: "/menu/bug-report.png",
    },
    {
      text: "Offer A Feature",
      link: "/offer-a-feature",
      icon: "/menu/light.png",
    },
  ];
  const visitorList = [
    {
      text: "About",
      link: "/about",
      icon: "/menu/info.png",
    },
    {
      text: "Mission",
      link: "/mission",
      icon: "/menu/goal.png",
    },
    {
      text: "FAQS",
      isExternal:true,
      link: "https://passionstocks.helpsite.com",
         icon: "/menu/question.png",
    },
    { text: "Careers", link: "/careers", icon: "/menu/bag.png" },
    {
      text: "Legal",
      link: "/legal",
      icon: "/menu/legal.png",
    },
    {
      text: "Support",
      link: "/support",
      icon: "/menu/chat.png",
    },
  ];

  const handleLogOut = async () => {
    if (store.getState().tokensAgentViewMode.accessToken) {
      // when logged out from smi member in the agency
      let tempTokens = store.getState().tokensAgentViewMode;
      // console.log(tempTokens);
      clearStoreWithoutConst();
      datadogRum.clearUser();

      store.dispatch({ type: "domain/tokens/addTokens", payload: tempTokens });
      store.dispatch({
        type: "domain/tokensAgentViewMode/addTokens",
        payload: { accessToken: null, refreshToken: null },
      });
      loadUser(tempTokens);
      navigate("/profile");
    } else {
      clearStoreWithoutConst();
      navigate("/");
    }
  };
  return (
    windowInnerSize && (
      <div
        className="Menu headline"
        // style={{
        //   height: windowInnerSize.height,
        // }}
      >
        <div
          className="Page--flex"
          style={{
            padding: "10px 0",
            // minHeight: "780px",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img
              src={BackPage}
              alt=""
              // id="BackPageTrade"
              className="back-btn"
              style={{
                position: "relative",
                top: 0,
                left: 0,
                margin: "20vw 0 20px",
              }}
              onClick={() => {
                navigate(-1);
              }}
            />

            <BasicMenu list={profile ? userList : visitorList} />
          </div>

          <div>
            <div style={{marginTop:'100px'}}>
              <img
                src={AcademyIcon}
                alt="PassionStocks academy icon"
                className="Menu__AcademyIcon"
              />
              <p className="Menu__PSAcademyTxt">PassionStocks Academy</p>
            </div>

            <p className="Menu__JoinTxt">
              Join the PassionStocks Academy to embark on a learning journey
              about personal branding and its path to the stock exchange.
            </p>
            <button className="Menu__JoinBtn headline">
              Join the waitlist{" "}
            </button>

            {!profile && (
              <div>
                <p className="Menu__ToJoinTxt">
                  To join the waitlist, please sign up first
                </p>
                <button
                  className="Menu__LogOutBtn headline"
                  onClick={() => {
                    navigate(
                      API_URL === "https://demo-api.passionstocks.com"
                        ? "/auth/sign-in"
                        : "/auth/sign-up"
                    );
                  }}
                >
                  Sign up
                </button>
              </div>
            )}

            {profile && (
              <button
                className="Menu__LogOutBtn headline"
                onClick={handleLogOut}
              >
                Log Out
              </button>
            )}

            <div className="Menu__SM">
              <img src={PSLogoBW} alt="" className="Menu__SMItem"></img>
              <a href="https://www.facebook.com/PassionStocks">
                <img src={FacebookLogoBW} alt="" className="Menu__SMItem"></img>
              </a>
              <a href="https://www.instagram.com/passionstocks/">
                <img
                  src={InstagramLogoBW}
                  alt=""
                  className="Menu__SMItem"
                ></img>
              </a>
              <a href="https://www.youtube.com/channel/UCewSZEyV9qce9dnuUTY5Qqw">
                <img src={YoutubeLogoBW} alt="" className="Menu__SMItem"></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
