import "./WhatsTheVibe.css";

import React from 'react';
/*import {API_URL} from '@src/config.js';*/

export default function WhatsTheVibe(props) {
  let fontScale = 6;
  return (
    <div className="WhatsTheVibe">
      <div className="WhatsTheVibe__HeadlinesContainer glow-yellow-white unnamed-character-style-1">
        <p
          style={{
            fontSize: `${fontScale}vw`,
            lineHeight: `${fontScale + 0.7}vw`,
          }}
        >
          JOURNEY
        </p>
        <p
          style={{
            fontSize: `${fontScale * 1.15}vw`,
            lineHeight: `${fontScale * 1.15 + 0.7}vw`,
          }}
        >
          CHALLENGE
        </p>
        <p
          style={{
            fontSize: `${fontScale * 1.3}vw`,
            lineHeight: `${fontScale * 1.3 + 0.7}vw`,
          }}
        >
          EXPERIENCE
        </p>
        <p
          style={{
            marginTop: "4.5vw",
            fontSize: `${fontScale * 1.45}vw`,
            lineHeight: `${fontScale * 1.45 + 0.7}vw`,
          }}
        >
          UNCOVER YOUR
        </p>
        <p
          style={{
            fontSize: `${fontScale * 1.6}vw`,
            lineHeight: `${fontScale * 1.6 + 0.7}vw`,
          }}
        >
          INNER POTENTIAL
        </p>
      </div>

      <p className="WhatsTheVibe__EveryTxt">
        Every entrepreneur holds a treasury of tales from their quest to list
        their company on a stock exchange, and now we are bringing this
        transformative experience to the creator world.
        <br />
        <br />
        It’s not solely about the destination of reaching the stock exchange;
        <br />
        <br />
        it’s about embracing the exhilarating journey to get there.
        <br />
        <br />
        Along the way, you will unearth an inner potential that you may have
        never seen before.
        <br />
        <br />
        The challenges, experiences, and stories that shape your path will push
        you to new heights and reveal the depths of your capabilities.
        <br />
        <br />
        Get ready to embark on a remarkable journey of personal growth,
        self-discovery, and unlocking your untapped potential with
        PassionStocks.
      </p>
    </div>
  );
}
