// config.js
const API_URL ="https://demo-api.passionstocks.com"
//const API_URL ="http://localhost:144"
//const API_URL ="https://test-api.passionstocks.com"
//const API_URL ="https://api.passionstocks.com"
//const API_URL ="https://stg-api.passionstocks.com"


// const DEBUG_MODE = true;
export { API_URL };


