import "./style.css";
import store from "../../../store/store";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React from "react";
import { API_URL } from "../../../config.js";

import BackPage from "../../../assets/images/common/back-page.png";
import emojyCop from "../../../assets/images/common/emojy-cop.png";
import tiktokIcon from "../../../assets/images/common/tiktok-letter.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectTokens = (state) => state.tokens;

export default function IssuerRegTiktokUsername() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const tokens = useSelector(selectTokens);

  const navigate = useNavigate();

  const handleConnectTiktok = () => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: API_URL + "/api/auth/tiktok/info?state=" + "hhh",
      headers: {
        Authorization: tokens.accessToken, // can't call hooks from this function (to get the accessToken)
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        window.location.replace(response.data.url);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="IssuerRegTiktikUsername Page headline"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "24vw 0 5vw",
          height: windowInnerSize.height,
        }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          style={{ top: "10vw" }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <img
          src={emojyCop}
          alt="Emojy Cop"
          style={{ display: "block", margin: "60px auto 15px", width: "134px" }}
        />
        <p
          className="unnamed-character-style-1"
          style={{
            fontSize: "30px",
            textTransform: "none",
            fontWeight: "bolder",
          }}
        >
          Creator Verification
        </p>
        <p
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: "lighter",
            textAlign: "center",
            position: "relative",
            margin: "15px 10vw",
          }}
        >
          Before accessing the brand calculator, please connect your TikTok
          account for identity verification.
          <br />
          Once verified, you can proceed to use the calculator and schedule a
          meeting with our team.{" "}
        </p>
        {/* <Link to="/creator-registration/welcome-to-brand-calculator"> */}
        <div onClick={handleConnectTiktok}>
          {" "}
          <img
            src={tiktokIcon}
            alt="Tiktok icon"
            style={{
              width: "80px",
              display: "block",
              margin: "30px auto 10px",
            }}
          />
          <p
            style={{
              fontSize: "20px",
              lineHeight: "27px",
              fontWeight: "bold",
              position: "relative",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            Connect
          </p>
        
        </div>
        {API_URL === "https://demo-api.passionstocks.com" && (
            <button
              className="btn--yellow_border-white"
              style={{ margin: "20px auto 0",fontSize:'20px',lineHeight:'30px',fontWeight:"normal" }}
            onClick={()=>{navigate("/creator-registration/welcome-to-brand-calculator")}}>
              Demo: Continue as verified
            </button>
          )}
        {/* </Link> */}
        <div style={{ margin: "50px 10vw 5px" }}>
          <svg height="1px" width="60vw">
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="#909090"
              stroke-linecap="butt"
            />
          </svg>
          <p style={{ color: "#909090" }}>
            If you don’t have a TikTok account or face any issues, contact us at
            support@passionstocks.com.
            <br /> If you’re a creator manager, please click here.{" "}
          </p>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
