import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";

import AvatarImg from "../../../../components/common/AvatarImg";
import PassionPrdct from "../../../../components/common/PassionPrdct";

import "./style.css";
import store from "../../../../store/store";

import loadProfile from "../../../../loaders/profile";

import EditIcon from "../../../../assets/images/common/edit.png";
import FullStar from "../../../../assets/images/common/star-full.png";
import EmptyStar from "../../../../assets/images/common/star-empty.png";

const selectProfile = (state) => state.profile;
const selectTokens = (state) => state.tokens;
const selectPPTPendingList = (state) => state.pptPendingList;
const selectToRefreshed = (state) => state.toRefresh;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Profile(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: true },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Profile",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const tokens = useSelector(selectTokens);
  useEffect(() => {
    if (tokens) {
      loadProfile(tokens);
    }
  }, []);

  const isRefreshed = useSelector(selectToRefreshed);

  //Animation:
  const [animateSlide, setanimateSlide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setanimateSlide(true);
    }, 0);
    return () => {
      setanimateSlide(false);
    };
  }, []);

  const profile = useSelector(selectProfile);
  const PPTPendingList = useSelector(selectPPTPendingList);

  const [yuyu, setyuyu] = useState(null);
  const [ProfilePhotoUpdate, setProfilePhotoUpdate] = useState(null);
  useEffect(() => {
    if (yuyu !== props.EditImage && store.getState().profile) {
      setyuyu(props.EditImage);
      setProfilePhotoUpdate(null);
      setTimeout(
        setProfilePhotoUpdate(store.getState().profile.profile_photo_path),
        1500
      );
      // window.location.reload();
    }
  }, [props.EditImage]);
  //
  // // console.log('hjhjhjhj' + store.getState().opening_view);

  return (
    isRefreshed &&
    profile && (

      <div
        className={"InvestorProfile Page " + (!animateSlide ? "animate-to-left" : null)}
        style={{ height: windowInnerSize.height }}
      >
        <div className="InvestorProfile__Data">
          <Link to={profile ? "/profile/edit" : null}>
            <img src={EditIcon} alt="" className="InvestorProfile__EditIcon" />
          </Link>
          <div className="InvestorProfile__AvatarPosition">
            <AvatarImg
              isProfileDemo={profile ? true : false}
              EditImage={props.EditImage}
              rootImage={ProfilePhotoUpdate}
            />
          </div>
          <div className="InvestorProfile__StarsLine">{getRateStars(profile ? profile.stars : 0)}</div>
          <p className="InvestorProfile__Name headline">
            {profile !== null ? profile.name : "Name"}
          </p>
          <p className="InvestorProfile__Username headline">
            {profile !== null ? "@" + profile.username : "@Username"}
          </p>
          {/*<p id="MyBrokerBtn" className="headline">My broker</p>*/}
          
          {/* <Link to="/money-calculator">
            <p className="InvestorProfile__DiscoverYP headline">
              Discover your Potential
            </p>
          </Link> */}
          
        </div>
        {PPTPendingList ? (
          PPTPendingList.length > 0 ? (
            <div className="InvestorProfile__PrdctPendingList">
              <p id="PrdctsPendingTxt" className="headline">
                Passionproducts pending:
              </p>
              {getPassionPrdctsList()}
            </div>
          ) : null
        ) : null}
      </div>
    )
  );

  function getPassionPrdctsList() {
    // console.log(PPTPendingList);
    let PPTPLElements = [];
    for (let i = 0; i < PPTPendingList.length; i++) {
      PPTPLElements.push(<PassionPrdct PPTData={PPTPendingList[i]} />);
    }

    return PPTPLElements;
  }

  function getRateStars(num) {
    let FullStars = num;
    let EmptyStars = 5 - num;
    let StarsCounter = 5;
    let StarsLine = [];

    for (let i = 0; i < FullStars; i++, StarsCounter--) {
      StarsLine.push(
        <img
          key={StarsCounter}
          className="RateStars"
          src={FullStar}
          alt=""
          id="FullStar"
        />
      );
    }
    for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
      StarsLine.push(
        <img
          key={StarsCounter}
          className="RateStars"
          src={EmptyStar}
          alt=""
          id="FullStar"
        />
      );
    }
    return StarsLine;
  }
}
