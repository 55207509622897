import "./style.css";
import ArrowUp from "../../../assets/images/common/arrow-up.png";
import ArrowDown from "../../../assets/images/common/arrow-down.png";
import AvatarDefault from "../../../assets/images/common/avatar-default.png";

import React from "react";

import { useState, useEffect } from "react";

import CreativeCard_TotalHolding from "./components/TotalHolding.jsx";
import StarsRanking from "../StarsRanking/index.jsx";

export default function CreativeCard(props) {
  const [cardData, setcardData] = useState(null);
  const [isGrowth, setisGrowth] = useState(null);
  useEffect(() => {
    setcardData(props.cardData);

    if (props.cardData) {
      if (props.cardData?.lastChange?.amount) {
        if (props.cardData?.lastChange?.amount > 0) {
          setisGrowth(true);
        } else {
          if (props.cardData?.lastChange.amount < 0) {
            setisGrowth(false);
          }
        }
      }
    }
  }, [props.cardData]);

  const managerCardStyle = {
    color: "#2e2e2e ",
    background: "#fff",
  };
  const issuerCardStyle = {
    color: "#ffffff",
  };

  return cardData ? (
    <div
      className="CreativeCard headline"
      style={props.isManager ? managerCardStyle : issuerCardStyle}
    >
      {/* {props.isManager && <div className="CreativeCard__opacityBackground" />} */}

      {props.isManager && (
        <div className="CreativeCard__StarsPosition">
          <StarsRanking
            ranking={0}
            isManager={props.isManager}
            style={{ width: "130px", justifyContent: "flex-end" }}
          />
        </div>
      )}

      {props.totalHolding && (
        <CreativeCard_TotalHolding
          totalHolding={props.totalHolding}
          isManager={props.isManager}
        />
      )}

      <div className="CreativeCard__FlexContainer" style={{    justifyContent: props.totalHolding ? "center" : "space-around",
}}>
        <div style={{ position: "relative" }}>
          <div
            className={
              "CreativeCard__BrandPic " +
              (cardData ? cardData.ringColour + "RC" : null)
            }
            style={{
              backgroundImage: cardData
                ? `url(${cardData.profilePic})`
                : props.EditImage
                ? `url(${props.EditImage})`
                : AvatarDefault,
            }}
          />

          <p
            className={
              "CreativeCard__Rating " +
              (cardData ? cardData.ringColour + "RN" : null)
            }
          >
            {cardData ? cardData.ranking : "#"}
          </p>
        </div>

        <div style={{ width: "45vw" }}>
          <p className="CreativeCard__BrandName">
            {cardData ? cardData.name : null}
          </p>
          <p className="CreativeCard__Price">
            {isGrowth !== null ? (
              isGrowth ? (
                <img src={ArrowUp} alt="" className="CreativeCard__Arrow" />
              ) : (
                <img src={ArrowDown} alt="" className="CreativeCard__Arrow" />
              )
            ) : null}
            {cardData.currency ? cardData.currency : "--"}
            {cardData
              ? cardData.currentPrice % 1 === 0
                ? cardData.currentPrice
                : cardData.currentPrice.toFixed(2)
              : null}
            <span
              style={{
                color: "#909090",
                fontSize: "10px",
                fontWeight: "lighter",
              }}
            >
              {" "}
              /Unit
            </span>
          </p>

          {props.isManager ? (
            <p className="CreativeCard__Niches">Manager</p>
          ) : (
            <p className="CreativeCard__Niches">
              {cardData ? getNiches(cardData.niches) : null}
            </p>
          )}

          <p className="CreativeCard__Location">
            {cardData ? cardData.city : null}
          </p>
        </div>
      </div>
    </div>
  ) : null;
}

function getNiches(nichesArr) {
  let niches = "SMI";
  for (let i = 0; i < nichesArr.length; i++) {
    if (i === 0) {
      niches = nichesArr[i];
    } else {
      niches += "|" + nichesArr[i];
    }
  }
  return niches;
}
