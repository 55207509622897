import "./TotalHolding.css";
import React from "react";
/*import {API_URL} from '@src/config.js';*/
export default function CreativeCard_TotalHolding(props) {
  return (
    <div className="CreativeCard__TotalHolding">
      <p
        style={{
          fontSize: "12px",
          color: "#909090",
          fontWeight: "lighter",
        }}
      >
        <span
          style={{
            fontSize: "17px",
            color: "#ffbf12",
            fontWeight: "bolder",
          }}
        >
          {props.totalHolding.units}
        </span>{" "}
        Units
      </p>
      <p style={{ fontSize: "12px", color: props.isManager?"#2e2e2e":"#fff", fontWeight: "lighter" }}>
        {props.totalHolding.value}
      </p>
    </div>
  );
}
