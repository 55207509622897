import "./style.css";
import store from "../../../../../../../store/store";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/
import { useNavigate } from "react-router-dom";
import AgentCreativeCard from "../../../../../../../components/common/creative-cards/Agent";
import StockCreativeCard from "../../../../../../../components/common/creative-cards/Influencer";
import searchForSMI from "../../../../loaders/searchForSMI";
import NoMatch from "../NoMatch";

// const selectSearchResult = (state) => state.exchangeSearchResult;
const selectExchangeScrollEvent = (state) => state.exchangeScrollEvent;
const selectNewPage = (state) => state.salesFeedNewPageData;
const selectExchangeSearchListElements = (state) =>
  state.exchangeSearchListElements;
const selectSalesFeedWaitingForData = (state) => state.salesFeedWaitingForData;

export default function ResultsList() {
  const newPage = useSelector(selectNewPage);
  const exchangeScrollEvent = useSelector(selectExchangeScrollEvent);
  const exchangeSearchListElements = useSelector(
    selectExchangeSearchListElements
  );
  const waitingForData = useSelector(selectSalesFeedWaitingForData);

  const [feedListElements, setfeedListElements] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (feedListElements) {
      if (
        store.getState().salesFeedNewPageData.length > 0 &&
        !store.getState().salesFeedWaitingForData &&
        store.getState().exchangeSearchDM
      ) {
        store.dispatch({
          type: "app/salesFeedWaitingForData/toggle",
          payload: true,
        });
        let temp = store.getState().salesFeedPageIndex + 1; // ???
        store.dispatch({
          type: "app/salesFeedPageIndex/add",
          payload: temp,
        });
        searchForSMI(
          store.getState().exchangeSearchSMIName,
          store.getState().tokens,
          temp
        );
        // store.dispatch({
        //   type: "app/salesFeedWaitingForData/toggle",
        //   payload: false,
        // });
      } else {
        // if (store.getState().salesFeedNewPageData.length === 0) {
        //   setdisplayFakeLoader(true);
        //   setTimeout(()=>{setdisplayFakeLoader(false)},500)
        // }
      }
    }
  }, [exchangeScrollEvent]);

  useEffect(() => {
    // const getSalesElements = async () => addSalesElements();
    // console.log(store.getState().exchangeSearchDM);
    if (store.getState().exchangeSearchDM && newPage) {
      if (!store.getState().salesFeedWaitingForData && newPage !== 0) {
        if (newPage.length === 0 && store.getState().salesFeedPageIndex === 1) {
          setfeedListElements(null);
        } else {
          addSearchResultsElements();
        }
      }
    }
    function addSearchResultsElements() {
      // console.log(store.getState().exchangeSearchListElements);
      // console.log(store.getState().salesFeedNewPageData);

      let tempFeedElements = [];
      // if (pageIndex === 1) {
      if (!feedListElements||store.getState().salesFeedPageIndex === 1) {
        tempFeedElements = [];
      } else {
        tempFeedElements = feedListElements;
      }
      let cardData;
      for (let i = 0; i < newPage.length; i++) {
        // if (newPage[i].user.accountType < 32) {
        cardData = {
          profilePic: newPage[i].user.profilePic,
          name: newPage[i].user.name,
          username: newPage[i].user.username,
          ranking: newPage[i].ranking,
          ringColour: newPage[i].ringColour,
          socialMedia: {
            tiktok: null,
            instagram: { follows: 120 },
            youtube: null,
          } /* profile.data.influencer.socialMedia */,
          niches: newPage[i].niches,
          city: newPage[i].user.city,
          currentPrice: newPage[i].stock.marketPrice
            ? newPage[i].stock.marketPrice
            : 0,
          currency:newPage[i].stock.currency.symbol,
          lastChange: newPage[i].stock.lastChange
            ? newPage[i].stock.lastChange
            : { amount: 0 },
        };
        // }
        tempFeedElements.push(
          <div key={i} id="SearchElement">
            {newPage[i].user.accountType < 32 ? (
              <StockCreativeCard cardData={cardData} />
            ) : (
              <AgentCreativeCard
                cardData={cardData}
                // AgencyExchangeSearch={newPage[i]}
              />
            )}
            <div className="ResultList__SearchElementsBtnsContainer">
              <Link
                to={
                  // newPage[i].user.accountType < 32
                  // ?
                  "/public-profile/" + newPage[i].id
                  // : "/agent-ipoed-profile/" + newPage[i].id
                }
              >
                <p id="ViewProfileBtnSR">VIEW PROFILE</p>
              </Link>
              <p
                id="SearchResultInvestBtn"
                onClick={() => {
                  store.dispatch({
                    type: "app/salesFeedSelectedCategory/add",
                    payload: 5,
                  });
                  store.dispatch({
                    type: "app/salesFeedNewPageData/add",
                    payload: null,
                  });
                  store.dispatch({
                    type: "app/salesFeedPageIndex/add",
                    payload: 1,
                  });
                  store.dispatch({
                    type: "ui/exchangeSearchDM/toggle",
                    payload: false,
                  });

                  navigate("/exchange/" + newPage[i].user.username);
                  const firstNameInput = document.getElementById(
                    "Exchange__SearchSMI"
                  );
                  firstNameInput.value = "";
                }}
              >
                Invest
              </p>
            </div>
          </div>
        );
      }
      setfeedListElements(tempFeedElements);
      // store.dispatch({
      //   type: 'ui/exchangeSearchListElements/add',
      //   payload: tempFeedElements,
      // });
    }
  }, [newPage]);

  return (
    <div>
      {feedListElements}
      {!waitingForData && !feedListElements ? <NoMatch /> : null}
    </div>
  );
}
