import React from "react";
import { useEffect } from "react";

import HomeSlide1Bckg from "../../../assets/images/home/img-1.png";
import LongLogo from "../../../assets/images/common/ps-logo-one-row.png";
import MenuHome from "../../../assets/images/common/menu-icon.png";

import "./HeadView.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function HeadView(props) {
  const windowInnerSize = useSelector(selectWindowInnerSize);

  useEffect(() => {
    let ScrollDiv1 = setTimeout(function () {
      document.getElementById("ScrollDivHomeS1").scroll({
        top: 40,
        behavior: "smooth",
      });
    }, 4000);

    let ScrollDiv2 = setTimeout(function () {
      document.getElementById("ScrollDivHomeS1").scroll({
        top: 80,
        behavior: "smooth",
      });
    }, 8000);
    let ScrollDiv3 = setTimeout(function () {
      document.getElementById("ScrollDivHomeS1").scroll({
        top: 120,
        behavior: "smooth",
      });
    }, 12000);
    let ScrollDiv4 = setTimeout(function () {
      document
        .getElementById("ScrollDivHomeS1")
        .scrollTo({ top: 0, behavior: "smooth" });
    }, 16000);

    let interval = setInterval(function () {
      setTimeout(function () {
        document.getElementById("ScrollDivHomeS1").scroll({
          top: 40,
          behavior: "smooth",
        });
      }, 4000);
      setTimeout(function () {
        document.getElementById("ScrollDivHomeS1").scroll({
          top: 80,
          behavior: "smooth",
        });
      }, 8000);
      setTimeout(function () {
        document.getElementById("ScrollDivHomeS1").scroll({
          top: 120,
          behavior: "smooth",
        });
      }, 12000);
      setTimeout(function () {
        document
          .getElementById("ScrollDivHomeS1")
          .scrollTo({ top: 0, behavior: "smooth" });
      }, 16000);
    }, 20000);

    return () => {
      clearTimeout(ScrollDiv1);
      clearTimeout(ScrollDiv2);
      clearTimeout(ScrollDiv3);
      clearTimeout(ScrollDiv4);
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      id="OpeningSlideHDiv"
      // style={{ height: windowInnerSize.height, width: "100vw" }}
    >
      {!props.isMicroSiteVersion ? (
        <Link to="/menu">
          <div id="MenuHomeBlur" />
          <img src={MenuHome} alt="" id="MenuHome" />
        </Link>
      ) : null}

      <div className="Slide1">
        <img src={HomeSlide1Bckg} alt="" className="Backg"></img>
        <img src={LongLogo} alt="" className="LongLogoS1"></img>
        <p id="WhereTxtHome" className="unnamed-character-style-1">
          WHERE PEOPLE{" "}
        </p>
        <div id="ScrollDivHomeS1" className="unnamed-character-style-1">
          <p id="InvestTxtW">INVEST</p>
          <p id="BelieveTxtW">BELIEVE</p>
          <p id="TrustTxtW">TRUST</p>
        </div>
        <p id="InPeopleTxtHome" className="unnamed-character-style-1">
          IN PEOPLE
        </p>
      </div>
    </div>
  );
}
