import "./style.css";
import store from "../../../../store/store";
import React from 'react';
import {API_URL} from '../../../../config.js';

// import unauthorized from '../../../functions/unauthorized';

import BackPage from "../../../../assets/images/common/back-page.png";
import AddIcon from "../../../../assets/images/common/add.png";
import { Link } from "react-router-dom";
// import axios from 'axios';
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";

import loadBankBen from "./loadBankBen";

const selectTokens = (state) => state.tokens;
const selectBankBenList = (state) => state.bankBenificiaryList;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function BeneficiarySelection() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  const tokens = useSelector(selectTokens);
  const bankBenList = useSelector(selectBankBenList);

  useEffect(() => {
    if (tokens) {
      loadBankBen(tokens);
    }
  }, []);

  const handleDeleteBankBe = async function (Bid) {
    var axios = require("axios");

    var config = {
      method: "delete",
      url: API_URL + "/api/banks/" + Bid,
      headers: {
        Authorization: tokens.accessToken,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        loadBankBen(tokens);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="BeneficiarySelection Page Page--padding-8vw headline"
        style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          className="BeneficiarySelection__BackBtn"
          onClick={() => {
            navigate(-1);
          }}
        />
          <p className="BeneficiarySelection__WithdrawToTxt">Withdraw to</p>
          <div className="BeneficiarySelection__BanksList">
            {getBanksList()}
            <Link to="/wallet/withdrawal/adding-bank-beneficiary">
              <p className="BeneficiarySelection__AddBBBtn">
                Add Bank Beneficiary
                <img
                  src={AddIcon}
                  alt=""
                  className="BeneficiarySelection__AddIcon"
                />
              </p>
            </Link>
          </div>
      </div>
    </AnimateKeyframes>
  );

  function getBanksList() {
    if (bankBenList) {
      let BanksElements = [];
      for (let i = 0; i < bankBenList.length; i++) {
        BanksElements.push(
          <div className="BeneficiarySelection__BankBenDiv">
            <Link to={"/wallet/withdrawal/amount/" + bankBenList[i].id}>
              <p key={i} className="BeneficiarySelection__BankItem">
                {bankBenList[i].bankNumber +
                  " Bank *" +
                  bankBenList[i].branchCode}
              </p>
            </Link>
            <p
              className="BeneficiarySelection__DeleteBankBen"
              onClick={() => handleDeleteBankBe(bankBenList[i].id)}
            >
              x
            </p>
          </div>
        );
      }

      return (
        <div className="BeneficiarySelection__BanksListElements">
          {BanksElements}
        </div>
      );
    }else{// console.log(98989989);
    }
  }
}
