import "./style.css";
import React, { useState, useEffect } from "react";
import store from "../../../../../store/store";
import "../../../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import FetchUnitsData from "./components/FetchUnitsData";
import GeneratesSlideItems from "./components/GeneratesSlideItems";
import ReactImageGallery from "react-image-gallery";
import ArrowToSlide from "../ArrowToSlide";
import Loader from "../../../../../components/common/Loader";

import IssuerUnitSlide from "./components/IssuerUnitSlide";
import InvestedUnitsSlide from "./components/InvestedUnitsSlide";
import ClientsUnitsSlide from "./components/ClientsUnitsSlide";

export default function BrandUnitsSlider(props) {
  // index: 0-total 1-investor 2-issuer 3-clients 4-my-firm

  useEffect(() => {}, [props.portfolioMode]);

  return (
    <div className="BrandUnitsSlider">
      <IssuerUnitSlide portfolioMode={props.portfolioMode} />
      <InvestedUnitsSlide portfolioMode={props.portfolioMode} />
      <ClientsUnitsSlide portfolioMode={props.portfolioMode} />

      {/* <FetchUnitsData
        loading={loading}
        portfolioMode={props.portfolioMode}
        setloading={setloading}
        setissuerUnit={setissuerUnit}
        setinvestorUnits={setinvestorUnits}
        setclientsUnits={setclientsUnits}
      />

      <GeneratesSlideItems
        loading={loading}
        issuerUnit={issuerUnit}
        investorUnits={investorUnits}
        clientsUnits={clientsUnits}
        setslidesItems={setslidesItems}
      />

      {slidesItems && (
        <div style={{ position: "relative" }}>
          <ArrowToSlide slideToRight={slideToRight} slideToLeft={slideToLeft} />

          <ReactImageGallery
            ref={imageGallery}
            items={slidesItems}
            showNav={false}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            autoPlay={false}
            infinite={false}
            onSlide={(index) => {
              console.log(index);
              updateArrowSlideStatus(index);
            }}
          />
        </div>
      )}
      {loading && (
        <div style={{ height: "40px", width: "40px" }}>
          <Loader />
        </div>
      )} */}
    </div>
  );
}
