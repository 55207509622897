import store from "../../../store/store";
import { API_URL } from "../../../config";
export default function loadMyAgentData(tokens) {
  var axios = require("axios");

  // last active on:
  var configLastActive = {
    method: "get",
    url:
      API_URL + "/api/influencers/agent-last-active",
    headers: {
      Authorization: tokens.accessToken, // can't call hooks from this function (to get the accessToken)
    },
  };

  axios(configLastActive)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      store.dispatch({
        type: "app/my-agent/lastActiveOn/add",
        payload: response.data,
      });
    })
    .catch(function (error) {
      // console.log(error);
      store.dispatch({
        type: "app/my-agent/lastActiveOn/delete",
        payload: null,
      });
    });

  // agent data:
  var configAgentData = {
    method: "get",
    url: API_URL + "/api/influencers/agent-data",
    headers: {
      Authorization: tokens.accessToken, // can't call hooks from this function (to get the accessToken)
    },
  };

  axios(configAgentData)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      store.dispatch({
        type: "app/my-agent/agentData/add",
        payload: response.data,
      });
    })
    .catch(function (error) {
      // console.log(error);
      store.dispatch({
        type: "app/my-agent/agentData/delete",
        payload: null,
      });
    });

  // agent metrics:
  var configAgentMetrics = {
    method: "get",
    url: API_URL + "/api/influencers/agent-metrics",
    headers: {
      Authorization: tokens.accessToken, // can't call hooks from this function (to get the accessToken)
    },
  };

  axios(configAgentMetrics)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      store.dispatch({
        type: "app/my-agent/agentMetrics/add",
        payload: response.data,
      });
    })
    .catch(function (error) {
      // console.log(error);
      store.dispatch({
        type: "app/my-agent/agentMetrics/delete",
        payload: null,
      });
    });

  // agent activity log:
  var configActivityLog = {
    method: "get",
    url:
      API_URL + "/api/influencers/agent-activity-log",
    headers: {
      Authorization: tokens.accessToken, // can't call hooks from this function (to get the accessToken)
    },
  };

  axios(configActivityLog)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      store.dispatch({
        type: "app/my-agent/activityLog/add",
        payload: response.data,
      });
    })
    .catch(function (error) {
      // console.log(error);
      store.dispatch({
        type: "app/my-agent/activityLog/delete",
        payload: null,
      });
    });
}
