import "./CreatorsHubBtn.css";
import "../../../styles/XD_home.css";
import { Link } from "react-router-dom";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

export default function CreatorsHubBtn() {
  const agentView = null; // useSelector(selectAgentView);
  const isInfluencer = null; // useSelector(selectIsInfluencer);

  return (
    !agentView &&
    !isInfluencer && (
      <Link to="/creators-hub">
        <p className="CreatorsHubBtn unnamed-character-style-1 ">Creators Hub</p>
      </Link>
    )
  );
}
