import "./style.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";
import React from "react";
/*import {API_URL} from '@src/config.js';*/

import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
import AboutUsBckgImg from "../../../assets/images/about-us/background.png";
import CircleAU from "../../../assets/images/about-us/circle.png";
import RectaAU from "../../../assets/images/about-us/recta.png";
import ChartIceAU from "../../../assets/images/about-us/chart-ice.png";
import ArrowRightAU from "../../../assets/images/common/arrow-right.png";
import EmojiHumble from "../../../assets/images/common/emoji-humble.png";
import BreakLine from "../../../components/common/lines/BreakLine";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Mission() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Home",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(-100vw)",
        "transform: translateX(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="About Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          style={{ top: "5vw" }}
          onClick={() => {
            navigate(-1);
          }}
        />

        <img src={AboutUsBckgImg} alt="" className="Mission__Img" />
        <p className="Mission__Headline unnamed-character-style-1">
          We’re on a Mission
          <br />
          To bring some
          <br />
          <span>Passion</span>
          <br />
          To the financial world
        </p>

        <div className="Mission__Shadow" />

        <BreakLine
          type="GlowBlue"
          style={{
            margin: "40px auto",
          }}
        />

        <p className="Mission__Paragraph1">
          <img src={CircleAU} alt="" className="Mission__Circle" />
          Humans are beings brimming with passion, embodying emotions, energy,
          and a sense of community. We radiate laughter and embrace vibrant
          colors, encompassing everything in-between, yet each of us is
          exquisitely distinct.
          <br />
          <br />
          Every individual is born with a remarkable array of talents and
          boundless potential.
          <br />
          <br />
          Like a circle, we represent a contained unit bursting with infinite
          possibilities, waiting to be explored and unleashed.
 </p>


        <p className="Mission__Paragraph2">          <img src={RectaAU} alt="" className="Mission__Rect" />

          When contemplating the finance world, one’s initial mental image might
          conjure an image of an aged gentleman sporting a distinguished
          mustache and a cigar clenched between his lips 👀
          <br />
          <br />
          Alternatively, it might evoke the image of someone with a hoodie,
          their head covered, sitting in a messy room, surrounded by the glow of
          computer screens running endless lines of unclear code.
          <br />
          <br />
          Over the years, not much has fundamentally altered, except that it has
          grown more convoluted and daunting.
          <br />
          <br />
          It remains inflexible, impersonal, and dreary, even though we, as
          humans, wield it as a tool. Unfortunately, it fails to accommodate our
          unique needs and aspirations.
        </p>

        <img src={ChartIceAU} alt="" className="Mission__Chart" />

        <p className="Mission__Paragraph3">
          Our objective is to ingeniously merge the realms of passion and
          finance, forging an entirely new entity that transcends traditional
          boundaries.
          <br />
          <br />
         <span style={{display:'flex',justifyContent:'left',alignItems:'center'}}> Who can resist ice cream?{" "}
          <img src={EmojiHumble} alt="emoji-humble" style={{ width: "29px", padding:'4px'}} />
      </span>  </p>

        <Link to="/creators-hub">
          <div className="Mission__LearnBtnContainer">
            <p className="Mission__LearnBtn unnamed-character-style-1">
              Creators Hub
            </p>
            <img src={ArrowRightAU} alt="" className="Mission__LearnBtnArrow" />
          </div>
        </Link>
      </div>
    </AnimateKeyframes>
  );
}
