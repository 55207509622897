import React from "react";

import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import $ from "jquery";
import axios from "axios";
import { useNavigate } from "react-router";
import { AnimateKeyframes } from "react-simple-animate";
import PhoneInput from "react-phone-input-2";
// import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
// import "react-html5-camera-photo/build/css/index.css";
import { API_URL } from "../../../config";
import AvatarImage from "../../../components/common/AvatarImg";
import Loader from "../../../components/common/Loader";
import ActionsMenu from "../../../components/common/menus/Actions";

import "./style.css";
import store from "../../../store/store";
import isVarNegative from "../../../helpers/isVarNegative";

import EditIcon from "../../../assets/images/common/edit.png";
import SMIProfileBckgImage from "../../../assets/images/profile/cover-img-default.jpg";
import DefaultAvatar from "../../../assets/images/common/avatar-default.png";
import BackPage from "../../../assets/images/common/back-page.png";
// import EyeIcon from '../../../images/icons/EyeIcon.png';
import Done from "../../../assets/images/common/confirm.png";

const selectProfile = (state) => state.profile;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function EditProfile(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Profile",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();
  //

  const profile = useSelector(selectProfile);

  const [Name, setName] = useState(
    store.getState().profile.name ? store.getState().profile.name : null
  );
  // const [Email, setEmail] = useState(null);
  const [PhoneNumber, setPhoneNumber] = useState(
    store.getState().profile.phoneNumber
      ? store.getState().profile.phoneNumber
      : null
  );
  const [City, setCity] = useState(
    store.getState().profile.city ? store.getState().profile.city : null
  );
  const [Niche1, setNiche1] = useState(
    isVarNegative(store.getState().profile.influencer) &&
      store.getState().profile.influencer?.niches.length > 0
      ? store.getState().profile.influencer.niches[0]
      : null
  );
  const [Niche2, setNiche2] = useState(
    isVarNegative(store.getState().profile.influencer) &&
      store.getState().profile.influencer?.niches.length > 1
      ? store.getState().profile.influencer.niches[1]
      : null
  );
  const [Niche3, setNiche3] = useState(
    /* isVarNegative(store.getState().profile.influencer&&*/ store.getState()
      .profile.influencer.niches?.length > 2
      ? store.getState().profile.influencer.niches[2]
      : null
  );
  const [MyPassion, setMyPassion] = useState(
    store.getState().profile.bio ? store.getState().profile.bio : null
  );

  const handleName = (event) => {
    if (event.target.value.length > 0) {
      setName(event.target.value);
    } else {
      setName(null);
    }
  };
  const handleEmail = (event) => {
    // if ((event.target.value.length > 0) && (validateEmail(event.target.value))) {
    //     setName(event.target.value);
    // }
    // else {
    //     setName(null);
    // }
  };
  const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleCity = (event) => {
    if (event.target.value.length > 0) {
      setCity(event.target.value);
    } else {
      setCity(null);
    }
  };

  const getImage = () => {
    document.getElementById("SMIAvatarImageEdit").click();
  };

  const [UpdateImg, setUpdateImg] = useState(null);
  const [ImageChanged, setImageChanged] = useState(null);
  const handleImgChanged = async (event) => {
    setUpdateImg(URL.createObjectURL(event.target.files[0]));
    await store.dispatch({
      type: "ui/editAvatarImgUrl/add",
      payload: URL.createObjectURL(event.target.files[0]),
    });
    setImageChanged(true);
  };

  const getCoverImage = () => {
    document.getElementById("SMICoverImageEdit").click();
  };
  const [UpdateCoverImg, setUpdateCoverImg] = useState(null);
  const [CoverImageChanged, setCoverImageChanged] = useState(null);
  const handleCoverImgChanged = (event) => {
    // var output = document.getElementById('output');
    setUpdateCoverImg(URL.createObjectURL(event.target.files[0]));
    store.dispatch({
      type: "ui/editCoverImgUrl/add",
      payload: URL.createObjectURL(event.target.files[0]),
    });
    setCoverImageChanged(true);
  };

  const [editButtonIndex, seteditButtonIndex] = useState(); // Which one of the edit buttons was pressed 'cover' or 'avatar' - use to defined the actions menu

  function getDefaultPic() {
    let temp =
      editButtonIndex === "cover" ? SMIProfileBckgImage : DefaultAvatar;
    if (editButtonIndex === "cover") {
      // setCoverImageChanged(true);
      setUpdateCoverImg(temp);
      store.dispatch({
        type: "ui/editCoverImgUrl/add",
        payload: temp,
      });
    } else {
      // setImageChanged(true);
      setUpdateImg(temp);
    }
    var axios = require("axios");
    // var FormData = require("form-data");
    // var data = new FormData();

    var config = {
      method: "delete",
      url: API_URL +"/api/user/profile-pic",
      headers: {
        Authorization: store.getState().tokens.accessToken
      },
      // data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function getGalleryPicture() {
    if (editButtonIndex === "cover") {
      getCoverImage();
    } else {
      getImage();
    }
  }
  const [takePicture, settakePicture] = useState(null);
  function getPictureTaken() {
    settakePicture(true);
  }

  function handleTakePhoto(dataUri) {
    settakePicture(false);
    setUpdateImg(dataUri);
    // Do stuff with the photo...
    // console.log("takePhoto");
  }
  function handleTakePhotoAnimationDone(dataUri) {
    // Do stuff with the photo...
    // console.log("takePhoto");
  }
  function handleCameraError(error) {
    // console.log("handleCameraError", error);
  }

  function handleCameraStart(stream) {
    // console.log("handleCameraStart");
  }

  function handleCameraStop() {
    // console.log("handleCameraStop");
  }

  const [ShowLoaderEP, setShowLoaderEP] = useState(false);
  const [EditPSubmit, setEditPSubmit] = useState(false);
  const submitEditProfile = async () => {
    // document.getElementById("SubmitEdit").click();
    // setEditPSubmit(true);
    setShowLoaderEP(true);
    if (ImageChanged) {
      const imagefile = document.querySelector("#SMIAvatarImageEdit");

      var form = new FormData();
      if (imagefile.files.length > 0) {
        form.append("image", imagefile.files[0]);
      } else {
        // var fs = require('fs');
        // console.log(window.location.origin + "/avatar-default.png");
        form.append("image", window.location.origin + "/avatar-default.png");
      }
      var settings = {
        url: API_URL + "/api/users/profile-pic",
        method: "POST",
        timeout: 0,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        data: form,
        headers: { Authorization: store.getState().tokens.accessToken },
      };

      $.ajax(settings).done(function (response) {
        // console.log(JSON.parse(response));
        setEditPSubmit(true);
        store.dispatch({
          type: "domain/profile/addData",
          payload: JSON.parse(response),
        });
        setImageChanged(false);
      });
    }

    if (CoverImageChanged) {
      const imagefile = document.querySelector("#SMICoverImageEdit");

      var formCover = new FormData();
      formCover.append("image", imagefile.files[0]);

      var settings = {
        url: API_URL + "/api/users/cover-pic",
        method: "POST",
        timeout: 0,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        data: formCover,
        headers: { Authorization: store.getState().tokens.accessToken },
      };

      $.ajax(settings).done(function (response) {
        // console.log(JSON.parse(response));
        setEditPSubmit(true);
        store.dispatch({
          type: "domain/profile/addData",
          payload: JSON.parse(response),
        });
        setImageChanged(false);
      });
    }

    if (
      Name ||
      Niche1 ||
      Niche2 ||
      Niche3 ||
      PhoneNumber ||
      City ||
      MyPassion
    ) {
      if (!ShowLoaderEP) {
        setShowLoaderEP(true);
      }
      let niches = [];
      if (profile.influencer) {
        if (Niche1) {
          niches.push(Niche1);
        } else {
          if (profile.influencer.niches[0]) {
            niches.push(profile.influencer.niches[0]);
          }
        }
        if (Niche2) {
          niches.push(Niche2);
        } else {
          if (profile.influencer.niches[1]) {
            niches.push(profile.influencer.niches[1]);
          }
        }
        if (Niche3) {
          niches.push(Niche3);
        } else {
          if (profile.influencer.niches[2]) {
            niches.push(profile.influencer.niches[2]);
          }
        }
      }
      let data = {
        name: Name ? Name : profile.name,
        // email: Email ? Email : profile.email,
        // username: SignUpParam.username,
        phoneNumber: PhoneNumber ? PhoneNumber : profile.phoneNumber,
        city: City ? City : profile.city,
        bio: MyPassion ? MyPassion : profile.bio,
        niches: niches,
      };
      // console.log(data);
      await axios({
        method: "PUT",
        url: `${API_URL}/api/users/edit`,
        headers: {
          authorization: store.getState().tokens.accessToken,
        },
        data: data,
      })
        .then(function (res) {
          store.dispatch({
            type: "domain/profile/addData",
            payload: res.data,
          });
          setEditPSubmit(true);
          // console.log(res.data);
        })
        .catch(function (error) {
          // console.log("error update profile");
          setShowLoaderEP(false);
          // console.log(error);
        });
    }

    // if ((Niche1) || (Niche2)) {

    //     if (!ShowLoaderEP) {
    //         setShowLoaderEP(true);
    //     }
    //     let nichesString;
    //     if ((Niche1) && (Niche2)) {
    //         nichesString = Niche1 + ',' + Niche2;
    //         setNiches([Niche1, Niche2]);
    //     }
    //     else {
    //         if (Niche1) {
    //             nichesString = Niche1 + (profile.influencer.niches.length > 1 ? profile.influencer.niches[1] : '');
    //             if (profile.influencer.niches.length > 1) {
    //                 setNiches([Niche1, profile.influencer.niches[1]]);
    //             }
    //             else {
    //                 setNiches([Niche1])
    //             }
    //         }
    //         if (Niche2) {
    //             nichesString = (profile.influencer.niches.length > 0 ? profile.influencer.niches[0] : '') + Niche2;
    //             if (profile.influencer.niches.length > 0) {
    //                 setNiches(profile.influencer.niches[0], Niche2);
    //             }
    //             else {
    //                 setNiches([Niche2])
    //             }
    //         }
    //     }
    //     await axios({
    //         method: 'POST',
    //         url: `https://web.passionstocks.com/api/influencers/` + profile.influencer.id + `/update?api_token=` + profile.token,
    //         params: {
    //             niches: nichesString
    //         },
    //     }).then(function (res) {
    //         // console.log('update Niches');
    //         EditPSubmit = true;

    //     }).catch(function (error) {
    //         // console.log('error update Niches');
    //         setShowLoaderEP(false);
    //     })
    // }

    // if (EditPSubmit) {
    //     if (ImageChanged) {
    //         setImageChanged(false);
    //     }
    //     // if (EditPSubmit) {
    //     //     await store.dispatch({ type: 'appConfig/edit_profile', payload: +store.getState().edit_profile + 1 })
    //     //     EditPSubmit = null;
    //     // }
    // }
  };
  useEffect(() => {
    if (EditPSubmit) {
      navigate("/profile");
    }
  }, [EditPSubmit]);

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="EditProfile Page"
        style={{
          position: "relative",
          height: windowInnerSize.height,
          top: "15vw",
        }}
      >
        <img
          src={BackPage}
          alt=""
          className="EditProfile__BackBtn back-btn"
          onClick={() => navigate(-1)}
        />
        <div style={{ position: "relative", paddingBottom: "20vw" }}>
          <div style={{ position: "relative" }}>
            {profile.accountType >= 8 && (
              <div style={{ position: "relative" }}>
                <div
                  className="EditProfile__Background"
                  style={{
                    backgroundImage: `url(${
                      UpdateCoverImg
                        ? UpdateCoverImg
                        : profile.coverPic
                        ? profile.coverPic
                        : SMIProfileBckgImage
                    })`,
                  }}
                />

                <img
                  for="AgencyCoverImageEditN"
                  src={EditIcon}
                  alt=""
                  className="EditProfile__EditCoverImgBtn"
                  onClick={
                    () => {
                      seteditButtonIndex("cover");
                    } /*getCoverImage*/
                  }
                />
              </div>
            )}

            <div
              className="EditProfile__AvatarContainer"
              style={{
                margin: profile.accountType < 8 ? "15vw 0vw 0" : "-23vw 0 0",
              }}
            >
              <div className="EditProfile__AvatarPositionSize">
                <AvatarImage
                  UpdateImg={UpdateImg}
                  EditImage={props.EditImage}
                />
                <img
                  for="AgencyAvatarImageEditN"
                  src={EditIcon}
                  alt=""
                  className="EditProfile__EditAvatarIcon"
                  onClick={
                    () => {
                      seteditButtonIndex("avatar");
                    } /*getImage*/
                  }
                />
              </div>
            </div>

            {editButtonIndex && (
              <ActionsMenu
                displayMenu={editButtonIndex}
                actions={[
                  { text: "Delete Photo", action: getDefaultPic },
                  { text: "Take Photo", action: getPictureTaken },
                  { text: "Choose Photo", action: getGalleryPicture },
                ]}
                cancel={true}
                setdisplayMenu={seteditButtonIndex}
              />
            )}
           

            <form
              method="PUT"
              enctype="multipart/form-data"
              style={{ display: "none" }}
            >
              <input
                id="SMIAvatarImageEdit"
                name="AgencyAvatarImageEditN"
                type="file"
                accept="image/*"
                onChange={handleImgChanged}
              />
              <input
                id="SMICoverImageEdit"
                name="AgencyCoverImageEditN"
                type="file"
                accept="image/*"
                onChange={handleCoverImgChanged}
              />
              <input
                id="SubmitEdit"
                formmethod="put"
                type="submit"
                value="submit"
              />
            </form>

            <div style={{ paddingTop: "15vw", position: "relative" }}>
              <input
                type="text"
                value={
                  profile.name ? Name /*profile.influencer.niches[0]*/ : null
                }
                placeholder={profile.name || Name === "" ? "Full Name" : ""}
                className="EditProfile__Input"
                onChange={handleName}
              ></input>
              <p
                className="EditProfile__Input "
                style={{
                  fontFamily: "sans-serif",
                  lineHeight: "8vw",
                  color: "#707070",
                  height: "10vw",
                  margin: "0 auto 3vw",
                  fontWeight: "normal",
                }}
              >
                {profile.email}
              </p>
              {/* <input
                type="email"
                focus={false}
                value={/*"Email" profile.email}
                style={{ color: "#b8b8b8" }}
                className="EditProfile__Input"
                onChange={handleEmail}
              ></input> */}
              <div style={{ position: "relative" }}>
                <PhoneInput
                  placeholder={"phone number"}
                  enableSearch={true}
                  disableSearchIcon={false}
                  country={"us"}
                  value={PhoneNumber}
                  onChange={(phone) => setPhoneNumber(phone)}
                  containerClass={"EditProfile__container-phone-number"}
                  inputClass={"EditProfile__PhoneNumber-input"}
                  buttonClass={"EditProfile__PhoneNumber-button"}
                  dropdownClass={"SignUpForm__PhoneNumber-dropdown"}
                />
              </div>
              {profile.accountType < 32 && (
                <p
                  className="EditProfile__Input "
                  style={{
                    fontFamily: "sans-serif",
                    lineHeight: "8vw",
                    color: "#707070",
                    height: "10vw",
                    margin: "0 auto 3vw",
                    fontWeight: "normal",
                  }}
                >
                  Birthday {profile.birthday}
                </p>
              )}
              <input
                type="text"
                value={
                  profile.city ? City /*profile.influencer.niches[0]*/ : null
                }
                placeholder={profile.city ? "City" : ""}
                className="EditProfile__Input"
                onChange={handleCity}
              />
              {profile.accountType >= 8 && profile.accountType < 32 && (
                <div
                  // id="DescribeThreeWordDiv"
                  className="EditProfile__SMINiches headline"
                >
                  <p>Insert up to 3 Niches</p>
                  <div className="EditProfile__SMINichesContainer">
                    <input
                      type="text"
                      maxlength={10}
                      value={
                        profile.influencer.niches.length > 0
                          ? Niche1 /*profile.influencer.niches[0]*/
                          : null
                      }
                      placeholder={
                        profile.influencer.niches.length < 1 || Niche1 === ""
                          ? "Insert niche"
                          : ""
                      }
                      className="EditProfile__SMINicheInput input-default"
                      onChange={(e) => {
                        setNiche1(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      maxlength={10}
                      value={
                        profile.influencer.niches.length > 1
                          ? Niche2 /*profile.influencer.niches[1]*/
                          : null
                      }
                      placeholder={
                        profile.influencer.niches.length < 2 || Niche2 === ""
                          ? "Insert niche"
                          : ""
                      }
                      className="EditProfile__SMINicheInput input-default"
                      onChange={(e) => {
                        setNiche2(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      maxlength={10}
                      value={
                        profile.influencer.niches.length > 2
                          ? Niche3 /*profile.influencer.niches[2]*/
                          : null
                      }
                      placeholder={
                        profile.influencer.niches.length < 3 || Niche3 === ""
                          ? "Insert niche"
                          : ""
                      }
                      className="EditProfile__SMINicheInput input-default"
                      onChange={(e) => {
                        setNiche3(e.target.value);
                      }}
                    />
                    {/* <input type="text" placeholder={""/*Userprofile.username} id="DescribeThirdW"></input> */}
                  </div>
                </div>
              )}
              {profile.accountType >= 8 && (
                <div className="EditProfile__MyPassion headline">
                  <p>{profile.accountType < 32 ? "My Passion" : "My Bio"}</p>
                  <textarea
                    name="Text1"
                    cols="40"
                    rows="5"
                    maxlength={500}
                    value={
                      profile.bio
                        ? MyPassion /*profile.influencer.niches[2]*/
                        : null
                    }
                    placeholder={
                      !profile.bio || MyPassion === ""
                        ? "Tell your followers why they should invest in you!"
                        : ""
                    }
                    // id="InputMyPassion"
                    className="EditProfile__InputMyPassion headline"
                    onChange={(e) => {
                      setMyPassion(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>

            <div className="EditProfile__LoaderPosition">
              {ShowLoaderEP && <Loader />}
            </div>

            <img
              src={Done}
              alt=""
              className="EditProfile__Done"
              onClick={submitEditProfile}
            />
          </div>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
