import React, { useState, useEffect } from "react";

import axios from "axios";
import { API_URL } from "../../../../config";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import store from "../../../../store/store";
import "./StockComment.css";
import BullPic from "../../../../assets/images/common/going-up.png";
import BearPic from "../../../../assets/images/common/going-down.png";
import ResponseBack from "../../../../assets/images/common/response.png";
// import Like from '../../images/icons/Like.png';
// import ProfilePic from '../../images/icons/AvatarImage.png';
import RedHurtAC from "../../../../assets/images/common/hurt-full-red.png";
import EmptyHurtAC from "../../../../assets/images/common/hurt-empty.png";

const selectTokens = (state) => state.tokens;
const selectStockView = (state) => state.sale;

export default function StockComment(props) {
  const tokens = useSelector(selectTokens);
  const sale = useSelector(selectStockView);

  // let isLogged = store.getState().profile !== null;

  // let AvatarLogged = isLogged ? (store.getState().profile.profilePic) : null;

  const [BullBearCookies, setBullBearCookies] = useState(null);

  useEffect(() => {
    return () => {
      setBullBearCookies(null);
      store.dispatch({ type: "domain/sale/delete", payload: null });
    };
  }, []);

  useEffect(() => {
    setBullBearCookies(props.SaleOffer ? props.SaleOffer.lastAction : null);
  }, [props.SaleOffer]);

  // using for quiqe react and update - UX
  const [Bull, setBull] = useState(0);
  const [Bear, setBear] = useState(0);
  const [Comment, setComment] = useState(0);

  useEffect(() => {
    return () => {
      setBull(0);
      setBear(0);
      setComment(0);
    };
  }, []);

  const [updatedSale, setupdatedSale] = useState(null);
  useEffect(() => {
    if (sale) {
      if (sale.id === props.SaleOffer.id) {
        setupdatedSale(sale);
        setBull(0);
        setBear(0);
        setComment(0);
        // await store.dispatch({ type: 'exchange/addStockView', payload: null });
      }
    }
  }, [sale]);

  useEffect(() => {
    setBullBearCookies(
      updatedSale ? updatedSale.lastAction : props.SaleOffer.lastAction
    );
  }, [updatedSale]);

  const handleBull = () => {
    var axios = require("axios");

    if (BullBearCookies !== "bull") {
      var config = {
        method: "post",
        url:
          API_URL +
          "/api/sales/" +
          props.SaleOffer.id +
          "/bull",
        headers: {
          Authorization: tokens.accessToken,
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setBullBearCookies("bull");
          setBull(Bull + 1);
          setupdatedSale(response.data);
          // updateSaleCommentComponent();
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      var config = {
        method: "delete",
        url:
          API_URL +
          "/api/sales/" +
          props.SaleOffer.id +
          "/bull",
        headers: {
          Authorization: tokens.accessToken,
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setBullBearCookies(null);
          setBull(Bull - 1);
          setupdatedSale(response.data);
          // updateSaleCommentComponent();
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };

  const handleBear = () => {
    var axios = require("axios");

    if (BullBearCookies !== "bear") {
      var config = {
        method: "post",
        url:
          API_URL +
          "/api/sales/" +
          props.SaleOffer.id +
          "/bear",
        headers: {
          Authorization: tokens.accessToken,
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setBullBearCookies("bear");
          setBear(Bear + 1);
          setupdatedSale(response.data);
          // updateSaleCommentComponent();
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      var config = {
        method: "delete",
        url:
          API_URL +
          "/api/sales/" +
          props.SaleOffer.id +
          "/bear",
        headers: {
          Authorization: tokens.accessToken,
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setBullBearCookies(null);
          setBear(Bear - 1);
          setupdatedSale(response.data);
          // updateSaleCommentComponent();
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };
  const [AddCommentText, setAddCommentText] = useState("");
  function submitComment() {
    var config = {
      method: "post",
      url:
        API_URL +
        "/api/sales/" +
        props.SaleOffer.id +
        "/comment",
      headers: {
        Authorization: tokens.accessToken,
      },
      data: {
        comment: AddCommentText,
        replyTo: null,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        setComment(Comment + 1);
        setAddCommentText("");

        updateSaleCommentComponent();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function updateSaleCommentComponent() {
    var config = {
      method: "get",
      url:
        API_URL + "/api/sales/" + props.SaleOffer.id,
      headers: {
        Authorization: tokens.accessToken,
      },
    };

    axios(config)
      .then(function (response) {
        store.dispatch({
          type: "domain/sale/add",
          payload: response.data,
        });
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return props.SaleOffer ? (
    <div
      className={
        !props.WithoutShortcutAddComment
          ? "StockComment"
          : "StockCommentAllCommentsSlide"
      }
    >
      <div id="SaleButtonsSC">
        <img src={BullPic} alt="" id="Bull" />
        <p
          id="BullTxt"
          className={
            "headline " + (BullBearCookies === "bull" ? "shadowBull" : "")
          }
          onClick={handleBull}
        >
          Bull
        </p>
        <Link
          to={
            store.getState().profile
              ? "/sale/comments/" + props.SaleOffer.id
              : "/LogIn"
          }
        >
          <p id="CommentTxt" className="headline">
            Comment
          </p>
        </Link>
        <img src={BearPic} alt="" id="Bear" />
        <p
          id="BearTxt"
          className={
            "headline " + (BullBearCookies === "bear" ? "shadowBear" : "")
          }
          onClick={handleBear}
        >
          Bear
        </p>
        <svg id="CommentsListLine" height="0.8vw" width="100%">
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#5D5D5D"
            stroke-width="8vw"
            stroke-spannecap="butt"
          />
        </svg>
        <p id="StockResponseDetails" className="headline">
          <span>
            Bull{" "}
            {updatedSale
              ? updatedSale.engagement?.bulls
              : props.SaleOffer.engagement?.bulls}
          </span>
          <span>| </span>
          <span>
            Views{" "}
            {updatedSale
              ? updatedSale.engagement?.views
              : props.SaleOffer.engagement?.views}
          </span>
          <span>|</span>
          <span>
            Comments{" "}
            {updatedSale
              ? updatedSale.engagement?.comments
              : props.SaleOffer.engagement?.comments}
          </span>
          <span>|</span>
          <span>
            Bear{" "}
            {updatedSale
              ? updatedSale.engagement?.bears
              : props.SaleOffer.engagement?.bears}
          </span>
        </p>
      </div>
      {!props.WithoutShortcutAddComment ? (
        <div id="CommentsPreviewSC">
          {props.SaleOffer.lastComment || updatedSale?.lastComment ? (
            <div id="LastCommentSC">
              <p className="headline" id="LastComment">
                <span>
                  {updatedSale
                    ? updatedSale.lastComment.username
                    : props.SaleOffer.lastComment.username}
                </span>{" "}
                {" " +
                  (updatedSale
                    ? updatedSale.lastComment.text
                    : props.SaleOffer.lastComment.text)}
              </p>
              <img src={ResponseBack} alt="ResponseBack" id="ResponseBack" />
              <i id="ResponseBackValue" className="headline">
                {updatedSale
                  ? updatedSale.lastComment.replies
                  : props.SaleOffer.lastComment.replies}
              </i>
              <img
                src={
                  updatedSale
                    ? updatedSale.lastComment.lastAction
                      ? RedHurtAC
                      : EmptyHurtAC
                    : props.SaleOffer.lastComment.lastAction
                    ? RedHurtAC
                    : EmptyHurtAC
                }
                alt="Like"
                id="Like"
              />
              <i id="LikeValue" className="headline">
                {updatedSale
                  ? updatedSale.lastComment.likes
                  : props.SaleOffer.lastComment.likes}
              </i>
              <Link
                to={
                  store.getState().profile
                    ? "/sale/comments/" + props.SaleOffer.id
                    : "/LogIn"
                }
              >
                <p id="ViewCommentsExchange" className="headline">
                  View all comments
                </p>
              </Link>
            </div>
          ) : (
            <div />
          )}
<div style={{position:'relative',paddingTop:'4vw'}}>
          <div
            id="ProfilePic1"
            style={{
              backgroundImage: `url(${store.getState().profile.profilePic})`,
            }}
          />
          <textarea
            name="Text1"
            cols="40"
            rows="1"
            placeholder={"Add a comment..."}
            id={"AddCommentSaleOffer"}
            className="headline"
            value={AddCommentText}
            onChange={(event) => setAddCommentText(event.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                submitComment();
              }
            }}
          /></div>
        </div>
      ) : null}
    </div>
  ) : null;
}
function cutLongComment(comment) {
  if (comment) {
    return comment.text.length + comment.username.length > 32
      ? comment.text.substring(0, 39 - comment.username.length) + " ..."
      : comment.text;
  }
}
