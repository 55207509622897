import "./NetWorth.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React from 'react';
import fixFractionDigits from "../../../../../../helpers/fixFractionDigits";
import UpArrow from "../../../../../../assets/images/common/arrow-up.png";
import DownArrow from "../../../../../../assets/images/common/arrow-down.png";

const selectWallet = (state) => state.wallet;

export default function NetWorth(props) {
  const wallet = useSelector(selectWallet);

  return (
    <div className="NetWorth headline">
      <p>
        <div className="flex-space_between" style={{ alignItems: "baseline" }}>
          <img src={UpArrow} alt="" className="NetWorth__ArrowNetWorth" />
          {wallet.currency.symbol + " "}
          {fixFractionDigits(wallet.totalEquity)}
        </div>
        <span>Net Worth</span>
      </p>
      <p style={{ marginTop: "20px" }}>
        <div className="flex-space_between" style={{ alignItems: "baseline" }}>
          <img src={DownArrow} alt="" className="NetWorth__ArrowAvaBalance" />
          {wallet.currency.symbol + " "}
          {wallet.withdrawalValue % 1 === 0
            ? wallet.withdrawalValue
            : wallet.withdrawalValue.toFixed(2)}
        </div>
        <span>Withdraw Value </span>
      </p>
    </div>
  );
}
