import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { AnimateKeyframes } from "react-simple-animate";
import { API_URL } from "../../../config";
// import Avatar_image from '../../Menu/Avatar_image';
import StockCreativeCard from "../../../components/common/creative-cards/Influencer";
import AgencyMemberTag from "../../../components/common/AgentMemberTag";
import Graph_user_data from "../../../components/common/graphs/GraphUserData";
import StockResponses from "../../../components/common/StockEngagement";
// import UpdateDeliveredStoryMention from '../../common/UpdateDeliveredStoryMention';
import SocialMediaValues from "../../../components/common/SocialMediaValues";
import SharePopUp from "../../../components/common/SharePopUp";

import "./style.css";
import store from "../../../store/store";

// import load3rdProfile from '../../../loaders/3rdProfile';

// import EditIcon from '../../../assets/images/common/EditIcon.png';
import SMIProfileBckgImage from "../../../assets/images/profile/cover-img-default.jpg";
import FullStar from "../../../assets/images/common/star-full.png";
import EmptyStar from "../../../assets/images/common/star-empty.png";
// import SMIProfileBckgImage from '../../../assets/images/common/SMIProfileBckgImage.jpg';
import SharingBtn from "../../../assets/images/common/share.png";
import InstagramBigIcon from "../../../assets/images/common/instagram-icon.png";
import Bull from "../../../assets/images/common/going-up.png";
import Bear from "../../../assets/images/common/going-down.png";
import fixFractionDigits from "../../../helpers/fixFractionDigits";

const selectTokens = (state) => state.tokens;
const select3rdProfile = (state) => state.influencer3rdProfile;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Profile3rd(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: true },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  // const [CreativeData, setCreativeData] = useState(null);
  let { influencerId } = useParams();

  const tokens = useSelector(selectTokens);
  const smi3rdProfile = useSelector(select3rdProfile);

  useEffect(() => {
    if (tokens) {
      // load3rdProfile(tokens, influencerId);
      updateViews1Sec(influencerId);
      setTimeout(() => updateViews3Sec(influencerId), 3000);
    }
    return () => {
      store.dispatch({
        type: "domain/influencer3rdProfile/delete",
        payload: null,
      });
    };
  }, []);

  const [FollowBtnTxt, setFollowBtnTxt] = useState("");
  const [cardData, setcardData] = useState(null);
  useEffect(() => {
    if (smi3rdProfile) {
      let tempCardData = {
        profilePic: smi3rdProfile.user.profilePic,
        name: smi3rdProfile.user.name,
        username: smi3rdProfile.user.username,
        ranking: smi3rdProfile.ranking,
        ringColour: smi3rdProfile.ringColour,
        socialMedia: {
          tiktok: null,
          instagram: { follows: 120 },
          youtube: null,
        } /* profile.data.influencer.socialMedia */,
        niches: smi3rdProfile.niches,
        city: smi3rdProfile.user.city,
        currentPrice: smi3rdProfile.stock.marketPrice
          ? smi3rdProfile.stock.marketPrice
          : 0,
        lastChange: smi3rdProfile.stock.lastChange,
        currency: smi3rdProfile.stock.currency.symbol,
      };
      setcardData(tempCardData);

      if (smi3rdProfile.engagement.isFollowing) {
        setFollowBtnTxt("Following");
      } else {
        setFollowBtnTxt("Follow");
      }
    }
  }, [smi3rdProfile]);

  const handleFollowBtn = (event) => {
    if (FollowBtnTxt === "Follow") {
      var axios = require("axios");

      var config = {
        method: "post",
        url:
          API_URL +
          "/api/influencers/" +
          smi3rdProfile.id +
          "/follow",
        headers: {
          Authorization: tokens.accessToken,
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setFollowBtnTxt("Following");
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      var axios = require("axios");

      var config = {
        method: "delete",
        url:
          API_URL +
          "/api/influencers/" +
          smi3rdProfile.id +
          "/follow",
        headers: {
          Authorization: tokens.accessToken,
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setFollowBtnTxt("Follow");
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };

  const [StatsOrPrdct, setStatsOrPrdct] = useState("Stats");

  const [SharePopUpDisplay, setSharePopUpDisplay] = useState(false);

  return smi3rdProfile && cardData ? (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className=" Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <div
          className="Influencer3rdProfile__BckgImage"
          style={{
            backgroundImage: `url(${
              smi3rdProfile.user.coverPic
                ? smi3rdProfile.user.coverPic
                : SMIProfileBckgImage
            })`,
          }}
        />

        <div className="Influencer3rdProfile__CreativeCardPosition">
          <StockCreativeCard cardData={cardData} />
        </div>
        <div className="Influencer3rdProfile__ResponsesPos">
          <StockResponses engagement={smi3rdProfile.engagement} />
        </div>

        <div className="Influencer3rdProfile__InteractBtnsContainer">
          <Link to={"/exchange/" + smi3rdProfile.user.username}>
            <p className="Influencer3rdProfile__InvestBtn">Invest</p>
          </Link>
          {smi3rdProfile.user.id !== store.getState().profile.id && (
            <p
              className={
                FollowBtnTxt === "Follow"
                  ? "Influencer3rdProfile__FollowTxtBtn"
                  : "Influencer3rdProfile__FollowingTxtBtn"
              }
              onClick={handleFollowBtn}
            >
              {FollowBtnTxt}
            </p>
          )}

          <img
            src={SharingBtn}
            alt="Share"
            className="Influencer3rdProfile__SharingBtn"
            onClick={() => {
              if (!SharePopUpDisplay) {
                setSharePopUpDisplay(true);
              }
            }}
          />
        </div>
        {SharePopUpDisplay && (
          <div
            className="Influencer3rdProfile__BackFromShare"
            onClick={() => {
              if (SharePopUpDisplay) {
                setSharePopUpDisplay(false);
              }
            }}
          />
        )}
        {SharePopUpDisplay && (
          <div className="Influencer3rdProfile__SharePUS">
            <SharePopUp
              SMIid={influencerId}
              SMIname={smi3rdProfile.name}
              brandUsername={smi3rdProfile.user.username}
              sharingType={0x2}
            />
          </div>
        )}

        <p className="Influencer3rdProfile__Metrics headline">
          Growth <span>{fixFractionDigits(smi3rdProfile.stock.growth)}%</span>
          ROI made for investors{" "}
          <span>{fixFractionDigits(smi3rdProfile.ROI) + "% "}</span>
          <img
            src={
              smi3rdProfile.ROI > 0 ? Bull : smi3rdProfile.ROI < 0 ? Bear : null
            }
            alt=""
            id="TradeVolumePic"
          />{" "}
          <br />
          Trade Frequency <span>{smi3rdProfile.tradeFrequency}</span>
          Highest price{" "}
          <span>
            {smi3rdProfile.stock.currency.symbol +
              fixFractionDigits(smi3rdProfile.stock.highestPrice)}
          </span>{" "}
          <br />
          Trade Volume <span>{smi3rdProfile.tradeVolume}</span>{" "}
          <img
            src={smi3rdProfile.tradeVolume > 0 ? Bull : null}
            alt=""
            id="TradeVolumePic"
          />{" "}
          {"  "}
          Stock views <span>{smi3rdProfile.engagement.stockViews}</span>
        </p>
        {smi3rdProfile.agent ? (
          <div className="Influencer3rdProfile__AgentTagSizePos">
            <AgencyMemberTag smi3rdProfile={smi3rdProfile} />
          </div>
        ) : null}
        <p className="Influencer3rdProfile__Description">
          {smi3rdProfile.myPassion}
        </p>

        <p className="Influencer3rdProfile__RemainingStocks">
          Remaining Passionstocks
          <span> / {smi3rdProfile.release.onSale}</span>
        </p>

        <div className="Influencer3rdProfile__SocialMPos">
          <SocialMediaValues
            SocialMedia={smi3rdProfile.socialMedia}
            dominatingPlatform={smi3rdProfile.dominatingPlatform.platform}
          />
        </div>

        <div className="Influencer3rdProfile__StatsPrdctContainer">
          <div className="Influencer3rdProfile__PrdctStatsBtns">
            <p className="Influencer3rdProfile__StatsBtn" onClick={setAsStats}>
              Stats
            </p>
            <p
              className="Influencer3rdProfile__ProductsBtn"
              onClick={setAsProducts}
            >
              Passion<br/> Products & Projects
            </p>
          </div>
          <svg
            height="0.5vw"
            width="84vw"
            className="Influencer3rdProfile__BasicLine"
          >
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="#ffbf12"
              stroke-width="10%"
              stroke-linecap="butt"
            />
          </svg>
          <svg
            height="0.85vw"
            width={StatsOrPrdct === "Stats" ? "20vw" : "50vw"}
            className={
              StatsOrPrdct === "Stats"
                ? "Influencer3rdProfile__StatsSwitchLine"
                : "Influencer3rdProfile__PrdctSwitchLine"
            }
          >
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="#ffbf12"
              stroke-width="10%"
              stroke-linecap="butt"
            />
          </svg>
          {StatsOrPrdct === "Stats" && (
            <div className={"Influencer3rdProfile__StockInformation"}>
              <div className="Influencer3rdProfile__GraphPos">
                <Graph_user_data SMI3rdP={true} />
              </div>
            </div>
          )}
          {StatsOrPrdct !== "Stats" && (
            <div className={"Influencer3rdProfile__ProductsInformation"}>
              <div style={{ height: "100vw" }}>
                <div id="DefaultDiv">
                  <p id="DefaultTxt">Default</p>
                  <img src={InstagramBigIcon} alt="" id="InstagramBigIcon" />
                  <p id="ISMTxt">
                    Instagram <br /> Story Mention
                  </p>
                  <p id="OneTxt">1</p>
                  <p id="PSTxt">
                    Passion <br />
                    Stock
                  </p>
                </div>
                <Link to="/unavailable">
                  <p id="SMBuyBtn">BUY</p>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </AnimateKeyframes>
  ) : null;
  function setAsStats() {
    if (StatsOrPrdct !== "Stats") {
      setStatsOrPrdct("Stats");
    }
  }
  function setAsProducts() {
    if (StatsOrPrdct !== "Products") {
      setStatsOrPrdct("Products");
    }
  }

  function getRateStars() {
    let FullStars = 4;
    let EmptyStars = 1;
    let StarsCounter = 5;
    let StarsLine = [];

    for (let i = 0; i < FullStars; i++, StarsCounter--) {
      StarsLine.push(
        <img
          key={StarsCounter}
          className="RateStars"
          src={FullStar}
          alt=""
          id="FullStar"
        />
      );
    }
    for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
      StarsLine.push(
        <img
          key={StarsCounter}
          className="RateStars"
          src={EmptyStar}
          alt=""
          id="FullStar"
        />
      );
    }

    return StarsLine;
  }
  function getCurrentPrice(influencerId, Token) {
    let stockdata = async () => {
      await axios({
        method: "GET",
        url:
          `https://web.passionstocks.com/api/influencers/` +
          influencerId +
          "/stocks?=api_token=" +
          Token,
      })
        .then(function (response) {
          // console.log(response);
          let StockData = response.data;
          return StockData[0].current_price;
        })
        .catch(function (error) {
          // console.log(error);
        });
    };
  }
  function updateViews1Sec(profileId) {
    var axios = require("axios");

    var config = {
      method: "post",
      url: API_URL + "/api/sales/view",
      headers: {
        Authorization: tokens.accessToken,
      },
      params: {
        type: "PROFILE",
        profileId: profileId,
      },
    };

    axios(config)
      .then(async function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  function updateViews3Sec(profileId) {
    var axios = require("axios");

    var config = {
      method: "post",
      url: API_URL + "/api/sales/3-seconds-view",
      headers: {
        Authorization: tokens.accessToken,
      },
      params: {
        type: "PROFILE",
        profileId: profileId,
      },
    };

    axios(config)
      .then(async function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
}
