import React from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import "./style.css";
import store from "../../store/store";

import BackPage from "../../assets/images/common/back-page.png";

import { useEffect } from "react";
// import loadProfile from "../../loadProfile";
import loadWallet from "../../loaders/wallet";

const selectProfile = (state) => state.profile;
const selectIPORelease = (state) => state.ipoSharesInformation;
const selectTokens = (state) => state.tokens;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function MyPassionstocks(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const tokens = useSelector(selectTokens);
  const profile = useSelector(selectProfile);
  const IPOrelease = useSelector(selectIPORelease);

  useEffect(() => {
    if (tokens && profile) {
      loadWallet(tokens, profile.accountType);
    }
  }, []);

  const [PromtMessage, setPromtMessage] = useState(null);

  const navigate = useNavigate();

  // const [IPOData, setIPOData] = useState(null);

  // useEffect(() => {
  //     // console.log(props.InfluencersIPORlsOffer);
  //     if (props.InfluencersIPORlsOffer !== null)
  //         setIPOData(props.InfluencersIPORlsOffer);
  // }, [props.InfluencersIPORlsOffer]);

  const [InputAskIPO, setInputAskIPO] = useState(null);
  const [IsInputInRange, setIsInputInRange] = useState(true);

  const handleInputOnChange = (event) => {
    if (event.target.value <= IPOrelease.issued && event.target.value) {
      setInputAskIPO(event.target.value);
      if (!IsInputInRange) {
        setIsInputInRange(true);
      }
    } else {
      setInputAskIPO(null);
      if (IsInputInRange) {
        setIsInputInRange(false);
      }
    }
  };

  const handleSubmit = async (event) => {
    if (InputAskIPO !== null) {
      await axios({
        method: "POST",
        url: API_URL + "/api/sales/ipo-sale/",
        headers: {
          Authorization: store.getState().tokens.accessToken,
        },
        data: {
          volume: InputAskIPO,
        },
      })
        .then(async function (response) {
          setPromtMessage(
            "Stock released successfully" /*'release'+InputAskIPO+ 'stocks to the market'*/
          );

          // console.log("release" + InputAskIPO + "stocks to the market");
          // console.log(response.data);

          setTimeout(function () {
            navigate("/exchange");
          }, 3000);
        })
        .catch(function (error) {
          setPromtMessage("Error to release to the market");
          // console.log("error to release the the market");
          console.log(error);
          setTimeout(function () {
            setPromtMessage(null);
          }, 3000);
        });
    }
  };
  const handleRequestPS = async () => {
    await axios({
      method: "POST",
      url: API_URL + "/api/influencers/request-stock",
      headers: {
        Authorization: store.getState().tokens.accessToken,
      },
    })
      .then(async function (response) {
        setPromtMessage("Asked for more IPO");
        // console.log("Asked for more IPO");
        // console.log(response.data);

        setTimeout(function () {
          navigate("/profile");
        }, 3000);
      })
      .catch(function (error) {
        setPromtMessage("Error to ask for more IPO");
        // console.log("Error to ask for more IPO");
        // console.log(error);
        setTimeout(function () {
          setPromtMessage(null);
        }, 3000);
      });
  };

  return (
    <div>
      {IPOrelease ? (
        <div id="SMIMyPSDiv" className="Page headline"
        style={{height: windowInnerSize.height}}>
          {/* <Link to="/Profile/SMI/Profile"> */}
            <img src={BackPage} alt="" id="BackPageSMIMyPS" onClick={()=>{navigate(-1)}}/>
          {/* </Link> */}
          <p id="MyPSReStocksTitle">
            Remaining Passionstocks<spam>{" / " + IPOrelease.issued}</spam>
          </p>

          <p id="TotalPSAuthAmount" className="unnamed-character-style-1">
            {IPOrelease.totalAuthorized}
          </p>
          <p id="TotalPSATxt">Total Passionstocks Authorised</p>

          <div id={IPOrelease.issued > 0 ? "IssuedPSDiv" : "HideIssuedDiv"}>
            <p id="IssuedPSAmount" className="unnamed-character-style-1">
              {IPOrelease.issued}
            </p>
            <p id="IssuedPSTxt">Issued Passionstocks Available</p>

            <p id="ReleaswExpTxt" className="unnamed-character-style-1">
              Release your PassionStocks <br />
              to the exchange. <br />
              <br />
              Remember to keep supply and
              <br />
              demand in mind
            </p>
            <input
              type="number"
              placeholder="0"
              id="InputReleaseAmount"
              onChange={handleInputOnChange}
            />
            <p id="PSTxtInput">Units</p>

            <p id="GoLiveBtn" onClick={handleSubmit}>
              GO Live
            </p>
          </div>
          <div
            id={IPOrelease.issued === 0 ? "ZeroIssuedIPO" : "HideIssuedDiv"}
          >
            <p id="IssuedPSAmount" className="unnamed-character-style-1">
              0
            </p>
            <p id="IssuedPSTxt">Issued Passionstocks Available</p>

            <p id="ZeroReleaswExpTxt" className="unnamed-character-style-1">
              Currently you have no PassionStocks
              <br />
              left to release on the exchange.
              <br />
              <br />
              Request more passionstocks and
              <br />
              you’ll be notified when more
              <br />
              will be issued.
            </p>
            <input
              type="number"
              readonly
              placeholder="0"
              id="ZeroInputReleaseAmount"
            />
            <p id="ZeroPSTxtInput">PS</p>

            <p id="ReqPSBtn" onClick={handleRequestPS}>
              Request Passionstocks
            </p>
          </div>
          {/* <div>
                <p id="SoldOutStats"><i>{1}</i> <span>{"10STK"}</span> for <span>{"PC1700"}</span><btn>Sold Out</btn></p>
                <p id="OnMarketStats"><i>{2}</i> <span>{"20STK"}</span> for <span>{"PC1700"}</span><btn>Remove Sale</btn></p>

            </div> */}

          <p id={PromtMessage ? "MessageStatus" : "HideM"} className="headline">
            {PromtMessage}
          </p>
        </div>
      ) : null}
    </div>
  );
}
