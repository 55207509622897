import "./style.css";
import store from "../../../../../../../store/store";
import { useState } from "react";
import { useSelector } from "react-redux";
import React from 'react';
import {API_URL} from '../../../../../../../config.js';
import axios from "axios";
import EmojiPic from "../../../../../../../assets/images/common/emoji-oops.png";
import InstagramIcon from "../../../../../../../assets/images/common/instagram-icon.png";
import Done from "../../../../../../../assets/images/common/confirm.png";

const selectTokens = (state) => state.tokens;
const selectSearchValue = (state) => state.exchangeSearchSMIName;

export default function NoMatch(props) {
  const tokens = useSelector(selectTokens);

  const searchValue = useSelector(selectSearchValue);
  // console.log(searchValue);
  const [instaHandleInput, setinstaHandleInput] = useState(null);
  const [isRequestSuccessed, setisRequestSuccessed] = useState(null);

  const handleSubmitGetNewSMI = () => {
    if (instaHandleInput && instaHandleInput !== "") {
      var config = {
        method: "post",
        url:
          API_URL +
          "/api/influencers/request-influencer",
        headers: {
          Authorization: tokens.accessToken,
        },
        data: {
          handle: "@" + instaHandleInput,
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setisRequestSuccessed(true);
        })
        .catch(function (error) {
          // console.log(error);
          setisRequestSuccessed(false);
        });
      setTimeout(() => {
        setisRequestSuccessed(null);
        setinstaHandleInput("");
        const firstNameInput = document.getElementById("Exchange__SearchSMI");
        firstNameInput.value = "";
        store.dispatch({
          type: "ui/exchangeSearchDM/toggle",
          payload: false,
        });
      }, 4000);
    } else {
      !instaHandleInput && setinstaHandleInput("");
    }
  };

  return (
    <div className="ExSeNoMatch headline">
      <img src={EmojiPic} alt="" className="ExSeNoMatch__Emoji" />
      <p className="ExSeNoMatch__NotFoundTxt unnamed-character-style-1">
        NOT FOUND
      </p>
      <p className="ExSeNoMatch__TheyAreTxt headline">
        "{searchValue}" has not joined the future yet. Confirm their instagram
        handle below and we will reach out.
      </p>
      <input
        type="text"
        placeholder="Username here"
        value={instaHandleInput}
        className={
          "ExSeNoMatch__SMIInstagramHandle " +
          (instaHandleInput === "" ? "input-wrong" : "")
        }
        onChange={(e) => setinstaHandleInput(e.target.value)}
      />
      <img
        src={InstagramIcon}
        alt=""
        className="ExSeNoMatch__SMIInstagramIcon"
      />
      <img
        src={Done}
        alt=""
        className="ExSeNoMatch__Done"
        onClick={handleSubmitGetNewSMI}
      />

      {isRequestSuccessed ? (
        <p className="ExSeNoMatch__UserRequestIndicatorMsg">
          Your request has been
          <br /> successfully received!
        </p>
      ) : (
        isRequestSuccessed === false && (
          <p className="ExSeNoMatch__UserRequestIndicatorMsg">
            An error occurred,
            <br /> please try again later.
          </p>
        )
      )}
    </div>
  );
}
