import "./Management.css";

import { useNavigate } from "react-router";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

export default function MyBrandManagement(props) {
  const list = [
    { text: "My Brand Unit Holders", link: "/my-brand" },
    { text: "Brand Fees & Tips", link: "/my-brand/management/fees-and-tips" },
    { text: "Brand Units Structure", link: "/my-brand" },
    { text: "Miscellaneous Assets", link: "/my-brand" },
    { text: "Brand Establishment Checklist", link: "/my-brand" },
    { text: "My Manager Activity", link: "/my-brand" },
  ];

  const navigate = useNavigate();
  return (
    <div className="MyBrandManagement headline">
      <div className="MyBrandManagement__BtnsContainer">
        {list.map((item) => (
          <p onClick={()=>{navigate(item.link)}}>{item.text}</p>
        ))}
      </div>
    </div>
  );
}
