import "./style.css";
import React from "react";

import FullStar from "../../../assets/images/common/star-full.png";
import EmptyStar from "../../../assets/images/common/star-empty.png";
import FullStarBlack from "../../../assets/images/common/star-full-black.png";
import EmptyStarBlack from "../../../assets/images/common/star-empty-black.png";

export default function StarsRanking(props) {
  function getStarsRanking(num) {
    let FullStars = num;
    let EmptyStars = 5 - num;
    let StarsCounter = 5;
    let StarsLine = [];

    for (let i = 0; i < FullStars; i++, StarsCounter--) {
      StarsLine.push(
        <img
          key={StarsCounter}
          className="StarsRanking__Star"
          src={props.isManager ? FullStarBlack : FullStar}
          alt=""
          id="FullStar"
        />
      );
    }
    for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
      StarsLine.push(
        <img
          key={StarsCounter}
          className="StarsRanking__Star"
          src={props.isManager ? EmptyStarBlack : EmptyStar}
          alt=""
          id="FullStar"
        />
      );
    }

    return StarsLine;
  }

  return (
    <div
      className="StarsRanking"
      style={props.style ? props.style : null}
    >
      {getStarsRanking(props.ranking)}
    </div>
  );
}
