import "./PorfolioNavBar.css";

import store from '../../../../store/store';
import { useSelector } from "react-redux";
import { ROLES } from "../../../../tools/role";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import totalWhite from "../../../../assets/images/wallet/stocks-navbar/total-white.png";
import totalYellow from "../../../../assets/images/wallet/stocks-navbar/total-yellow.png";
import investorWhite from "../../../../assets/images/wallet/stocks-navbar/investor-white.png";
import investorYellow from "../../../../assets/images/wallet/stocks-navbar/investor-yellow.png";
import issuerWhite from "../../../../assets/images/wallet/stocks-navbar/issuer-white.png";
import issuerYellow from "../../../../assets/images/wallet/stocks-navbar/issuer-yellow.png";
import clientsWhite from "../../../../assets/images/wallet/stocks-navbar/clients-white.png";
import clientsYellow from "../../../../assets/images/wallet/stocks-navbar/clients-yellow.png";

const selectUser = (state) => state.profile;

export default function PorfolioNavBar(props) {
  const user = useSelector(selectUser);
  if (user.accountType & ROLES.MANAGER) {
    return (
      <div class="WalletPorfolioNavBar__ManagerBtnsContainer">
        <img
          src={props.portfolioMode === 0 ? totalYellow : totalWhite}
          alt="Total data button"
          class="WalletPorfolioNavBar__IconBtn"
          onClick={() => {
            if (props.portfolioMode !== 0) {
              props.setPortfolioMode(0);
            }
          }}
        />
        {user.accountType & ROLES.ISSUER ? (
          <div class="WalletPorfolioNavBar__ManagerMiddleBtnsContainer">
            <img
              src={props.portfolioMode === 1 ? investorYellow : investorWhite}
              alt="Investor data button"
              class="WalletPorfolioNavBar__IconBtn"
              style={{ margin: "3vw 1vw" }}
              onClick={() => {
                if (props.portfolioMode !== 1) {
                  props.setPortfolioMode(1);
                }
              }}
            />
            <img
              src={props.portfolioMode === 2 ? issuerYellow : issuerWhite}
              alt="Issuer data button"
              class="WalletPorfolioNavBar__IconBtn"
              style={{ margin: "3vw 1vw" }}
              onClick={() => {
                if (props.portfolioMode !== 2) {
                  props.setPortfolioMode(2);
                  store.dispatch({
                    type: "ui/walletArrowsSlidesStatus/add",
                    payload: {
                      left: false,
                      right: false,
                    },
                  });
                }
              }}
            />
          </div>
        ) : (
          <img
            src={props.portfolioMode === 1 ? investorYellow : investorWhite}
            alt="Investor data button"
            class="WalletPorfolioNavBar__IconBtn"
            style={{ margin: "3vw 1vw" }}
            onClick={() => {
              if (props.portfolioMode !== 1) {
                props.setPortfolioMode(1);
              }
            }}
          />
        )}
        <img
          src={props.portfolioMode === 3 ? clientsYellow : clientsWhite}
          alt="Clients data button"
          class="WalletPorfolioNavBar__IconBtn"
          onClick={() => {
            if (props.portfolioMode !== 3) {
              props.setPortfolioMode(3);
            }
          }}
        />
      </div>
    );
  }
  if (user.accountType & ROLES.ISSUER) {
    return (
      <div class="WalletPorfolioNavBar__IssuerBtnsContainer">
        <img
          src={props.portfolioMode === 1 ? investorYellow : investorWhite}
          alt="Investor data button"
          class="WalletPorfolioNavBar__IconBtn"
          onClick={() => {
            if (props.portfolioMode !== 1) {
              props.setPortfolioMode(1);
            }
          }}
        />
        <img
          src={props.portfolioMode === 0 ? totalYellow : totalWhite}
          alt="Total data button"
          class="WalletPorfolioNavBar__IconBtn"
          onClick={() => {
            if (props.portfolioMode !== 0) {
              props.setPortfolioMode(0);
            }
          }}
        />

        <img
          src={props.portfolioMode === 2 ? issuerYellow : issuerWhite}
          alt="Issuer data button"
          class="WalletPorfolioNavBar__IconBtn"
          onClick={() => {
            if (props.portfolioMode !== 2) {
              props.setPortfolioMode(2);
            }
          }}
        />
      </div>
    );
  }
  // if (user.accountType & ROLES.INVESTOR) {
  //   <div />;
  // }
}
