
import store from '../../../../store/store';
import axios from 'axios';
import {API_URL} from '../../../../config.js';

export default async function searchForSMI(name, tokens, page) {
    // console.log('search for ' + name);
    await axios({
        method: "GET",
        url: `${API_URL}/api/influencers`,
        headers: {
            Authorization: tokens.accessToken,
        },
        params: {
            name: name,
            username: name,
            page: page
        },
    })
        .then(function (response) {
            // // console.log("got search data");
            // console.log(response.data);
            // store.dispatch({
            //     type: "app/exchangeSearchResult/add",
            //     payload: response.data.users,
            // });
            store.dispatch({ type: 'app/salesFeedNewPageData/add', payload: response.data.users });
            store.dispatch({
                type: "app/salesFeedWaitingForData/toggle",
                payload: false,
            });
        })
        .catch(function (error) {
            // console.log("error search");
            // console.log(error);
            store.dispatch({
                type: "app/salesFeedNewPageData/add",
                payload: "error",
            });
            store.dispatch({
                type: "app/salesFeedWaitingForData/toggle",
                payload: false,
            });
        });

}