import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import "./style.css";
import store from "../../../store/store";

import AvatarDefault from "../../../assets/images/common/avatar-default.png";
// import AvatarImgProfileDemo from '../../images/icons/AvatarImgProfileDemo.png'

const selectProfile = (state) => state.profile;

const Avatar_image = (props) => {
  const profile = useSelector(selectProfile);
  let AvatarImgUrl;
  if (profile) {
    AvatarImgUrl = profile.profilePic ? profile.profilePic : AvatarDefault;
  }

  return (
    <div
      id="AvatarImg"
      style={{
        backgroundImage:
        props.pic?
        `url(${props.pic})`
        : props.EditImage
          ? `url(${props.EditImage})`
          : props.OwnProfileMyAgency
          ? `url(${
              "https://web.passionstocks.com/" +
              store.getState().profile.influencer.agency.profile_photo_path
            })`
          : `url(${
              props.UpdateImg
                ? props.UpdateImg
                : AvatarImgUrl
                ? AvatarImgUrl
                : props.isProfileDemo
                ? /*AvatarImgProfileDemo*/ AvatarDefault
                : AvatarDefault
            })`,
      }}
    />
  );
};

export default Avatar_image;
