import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffect } from "react";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import AgentIPOed from "./agent-ipoed";
import Influencer from "./influencer";

import load3rdProfile from "../../loaders/3rdProfile";
import { ROLES } from "../../tools/role";

const selectPublicProfile = (state) => state.influencer3rdProfile;
const selectTokens = (state) => state.tokens;

export default function PublicProfileLayout() {
  const publicProfile = useSelector(selectPublicProfile);
  const tokens = useSelector(selectTokens);

  let { influencerId } = useParams();

  useEffect(() => {
    load3rdProfile(tokens, influencerId);
  },[]);

    return publicProfile?publicProfile.user.accountType & ROLES.AGENT ?<AgentIPOed />: <Influencer />:null

}
