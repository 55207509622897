import "./style.css";
import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import store from "../../../../store/store";

import BackPage from "../../../../assets/images/common/back-page.png";
import info from "../../../../assets/images/common/info.png";

import SwitchBtn from "../../../../components/common/SwitchBtn";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function MyBrandFeesAndTips(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="MyBrandFeesAndTips Page headline"
        style={{ height: windowInnerSize.height, padding: "20vw 0" }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          onClick={() => {
            navigate(-1);
          }}
        />

        <p className="MyBrandFeesAndTips__Headline unnamed-character-style-1">
          Buy In Criteria
        </p>
        <p className="text-13 text-center padding-12vw">
          Customize your community’s buying conditions in your brand right here.
          <br />
          <br />
          Tailor the terms to suit your unique brand vision and requirements.
        </p>

        <div style={{ margin: "40px 5vw" }}>
          <h2 className="text-h2 unnamed-character-style-1">
            BRAND FEES & TIPS{" "}
          </h2>
          <p className="text-13">
            Please enter the desired fees and tips for your community - when
            they are buying in your brand.
            <br />
            <br />
            Additionally, indicate the percentage cut you would like to receive
            from each transaction when a brand unit is traded. Tailor these
            parameters below to ensure they align with your preferences. Take
            control of your brand’s financial dynamics and create a sustainable
            ecosystem.
          </p>
          <p className="text-13 text-center" style={{ color: "#ffbf12" }}>
            Fees are your profit. Set them wisely.
          </p>
        </div>

        <div>
          <div className="flex-row-center">
            <img
              src={info}
              alt="Info"
              style={{ width: "22px", height: "22px", marginRight: "10px" }}
            />
            <h2
              className="text-0 text-h2 unnamed-character-style-1"
              style={{ textTransform: "none" }}
            >
              Buying In Fee
            </h2>
          </div>
          <p className="text-13 text-center padding-8vw">
            As part of the beta version, the buying in fee for all users is
            currently set to 0.
          </p>
        </div>
        <div>
          <div className="flex-row-center">
            <img
              src={info}
              alt="Info"
              style={{ width: "22px", height: "22px", marginRight: "10px" }}
            />
            <h2
              className="text-h2 unnamed-character-style-1"
              style={{ textTransform: "none",margin:"5px 0" }}
            >
              Brand Fee
            </h2>
          </div>
          <div className="flex-row-center">
            <input type="number" className="input-percent" />
            <p className="text-0 text-13">%</p>
          </div>
          <p className="text-13 text-center padding-8vw">
            You have the flexibility to charge a brand fee ranging from 0% to
            99%.
            <br />
            <br />
            We recommend charging between 2% to 10% for each transaction,
            allowing you to accommodate both new and loyal buyers.{" "}
          </p>

          <div className="flex-space_around">
            <div className="flex-column-center">
              <SwitchBtn />
              <div className="flex-row-center">
                <img
                  src={info}
                  alt="Info"
                  style={{ width: "22px", height: "22px", marginRight: "10px" }}
                />
                <h2 className="text-15">First Hand Sale</h2>
              </div>
            </div>
            <div className="flex-column-center">
              <SwitchBtn />
              <div className="flex-row-center">
                <img
                  src={info}
                  alt="Info"
                  style={{ width: "22px", height: "22px", marginRight: "10px" }}
                />
                <h2 className="text-15">Second Hand Sale</h2>
              </div>
            </div>
          </div>
        </div>

        <svg height="2px" width="50%" style={{ margin: "30px auto",display:'block' }}>
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="rgb(112, 112, 112)"
            stroke-width="5vw"
            stroke-linecap="butt"
          />
        </svg>

        <div>
          <div className="flex-row-center">
            <img
              src={info}
              alt="Info"
              style={{ width: "22px", height: "22px", marginRight: "10px" }}
            />
             <h2
              className="text-h2 unnamed-character-style-1"
              style={{ textTransform: "none",margin:"5px 0" }}
            >
           Tip</h2>
          </div>

          <div className="text-13 flex-row-center " style={{margin:'20px 0'}}>
            <div className=" flex-row-center">
              $
              <input type="number" className="input-percent" />
            </div>
            <p style={{margin:'0 26px'}}>or</p>
            <div className="flex-row-center">
              <input type="number" className="input-percent" /> %
            </div>
          </div>

          <div className="flex-space_around">
            <div className="flex-column-center">
              <SwitchBtn />
              <div className="flex-row-center">
                <img
                  src={info}
                  alt="Info"
                  style={{ width: "22px", height: "22px", marginRight: "10px" }}
                />
                <h2 className="text-15">First Hand Sale</h2>
              </div>
            </div>
            <div className="flex-column-center">
              <SwitchBtn />
              <div className="flex-row-center">
                <img
                  src={info}
                  alt="Info"
                  style={{ width: "22px", height: "22px", marginRight: "10px" }}
                />
                <h2 className="text-15">Second Hand Sale</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
