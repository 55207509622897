import "./style.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import store from "../../../../../store/store";

import loadExchangeData from "../../loaders/exchangeData";
import SaleOfferCard from "../../../../../components/common/SaleOfferCard";
import StockComment from "../StockComment";
import Loader from "../../../../../components/common/Loader";

const selectTokens = (state) => state.tokens;
const selectSelectedCategory = (state) => state.salesFeedSelectedCategory;
const selectPriceCategoryDirection = (state) =>
  state.salesFeedPriceCategoryDirection;
// const selectPageIndex = (state) => state.salesFeedPageIndex;
const selectNewPage = (state) => state.salesFeedNewPageData;
const selectExchangeSearchDM = (state) => state.exchangeSearchDM;
const selectSalesFeedWaitingForData = (state) => state.salesFeedWaitingForData;
const selectExchangeScrollEvent = (state) => state.exchangeScrollEvent;

export default function SalesFeed(props) {
  const tokens = useSelector(selectTokens);
  const selectedCategory = useSelector(selectSelectedCategory);
  const priceCategoryDirection = useSelector(selectPriceCategoryDirection);
  const newPage = useSelector(selectNewPage);
  const exchangeSearchDM = useSelector(selectExchangeSearchDM);
  const waitingForData = useSelector(selectSalesFeedWaitingForData);
  const exchangeScrollEvent = useSelector(selectExchangeScrollEvent);

  let { influencerUsername } = useParams();

  useEffect(() => {
    if (influencerUsername) {
      store.dispatch({
        type: "app/salesFeedSelectedCategory/add",
        payload: 5,
      });
      store.dispatch({
        type: "app/salesFeedNewPageData/add",
        payload: null,
      });
      store.dispatch({
        type: "app/salesFeedPageIndex/add",
        payload: 1,
      });
      store.dispatch({
        type: "ui/exchangeSearchDM/toggle",
        payload: false,
      });
    }
  }, [influencerUsername]);

  const [feedListElements, setfeedListElements] = useState(null);

  useEffect(() => {
    return () => {
      store.dispatch({
        type: "app/salesFeedNewPageData/add",
        payload: null,
      });
    };
  }, []);

  // load data of first page of sales when new category selected:
  useEffect(() => {
    // setwaitingForData(true);

    setfeedListElements(null);

    // using to prevent load multi data when scrolling
    store.dispatch({
      type: "app/salesFeedWaitingForData/toggle",
      payload: true,
    });

    // const getSales = async () => getSalesPageData();
    new getSalesPageData(selectedCategory, 1);

    store.dispatch({
      type: "app/salesFeedWaitingForData/toggle",
      payload: false,
    });
  }, [selectedCategory, priceCategoryDirection]);

  // update sales list elements:
  useEffect(() => {
    // const getSalesElements = async () => addSalesElements();
    if (!store.getState().exchangeSearchDM && newPage) {
      new addSalesElements();
    }
  }, [newPage]);

  const [displayFakeLoader, setdisplayFakeLoader] = useState(false);
  // Scroll feed:
  // useEffect(async () => {
  //   const exchangePage = document.getElementById("ExchangeDiv");
  //   exchangePage.addEventListener("scroll", () => {
  //     if (
  //       exchangePage.offsetHeight + exchangePage.scrollTop >=
  //       exchangePage.scrollHeight - 2
  //     ) {
  //       // console.log(store.getState().salesFeedNewPageData);
  //       // console.log(store.getState().salesFeedWaitingForData);
  //       // console.log(store.getState().salesFeedSelectedCategory);
  //       // don't use useSelector
  //       if (
  //         store.getState().salesFeedNewPageData.length > 0 &&
  //         !store.getState().salesFeedWaitingForData
  //       ) {
  //         store.dispatch({
  //           type: "app/salesFeedWaitingForData/toggle",
  //           payload: true,
  //         });
  //         let temp = store.getState().salesFeedPageIndex + 1; // ???
  //         store.dispatch({
  //           type: "app/salesFeedPageIndex/add",
  //           payload: temp,
  //         });
  //         new getSalesPageData(
  //           store.getState().salesFeedSelectedCategory,
  //           temp
  //         );
  //         // store.dispatch({
  //         //   type: "app/salesFeedWaitingForData/toggle",
  //         //   payload: false,
  //         // });
  //       } else {
  //         // if (store.getState().salesFeedNewPageData.length === 0) {
  //         //   setdisplayFakeLoader(true);
  //         //   setTimeout(()=>{setdisplayFakeLoader(false)},500)
  //         // }
  //       }
  //     }
  //   });
  //   return () => {
  //     exchangePage.removeEventListener("scroll",null)
  //   }

  // }, []);
  useEffect(() => {
    // console.log("scroll");
    if (
      store.getState().salesFeedNewPageData?.length > 0 &&
      !store.getState().salesFeedWaitingForData &&
      !store.getState().exchangeSearchDM
    ) {
      store.dispatch({
        type: "app/salesFeedWaitingForData/toggle",
        payload: true,
      });
      let temp = store.getState().salesFeedPageIndex + 1; // ???
      store.dispatch({
        type: "app/salesFeedPageIndex/add",
        payload: temp,
      });
      new getSalesPageData(store.getState().salesFeedSelectedCategory, temp);
      // store.dispatch({
      //   type: "app/salesFeedWaitingForData/toggle",
      //   payload: false,
      // });
    } else {
      // if (store.getState().salesFeedNewPageData.length === 0) {
      //   setdisplayFakeLoader(true);
      //   setTimeout(()=>{setdisplayFakeLoader(false)},500)
      // }
    }
  }, [exchangeScrollEvent]);

  // exclude
  useEffect(() => {
    if (!exchangeSearchDM && store.getState().salesFeedSelectedCategory !== 5) {
      const firstNameInput = document.getElementById("Exchange__SearchSMI");
      firstNameInput.value = "";

      // store.dispatch({
      //   type: "app/salesFeedNewPageData/add",
      //   payload: null,
      // });
      // store.dispatch({
      //   type: "app/salesFeedPageIndex/add",
      //   payload: 1,
      // });
      // setfeedListElements(null);

      // store.dispatch({
      //   type: "app/salesFeedSelectedCategory/add",
      //   payload: 3,
      // });
      // store.dispatch({
      //   type: "app/salesFeedPriceCategoryDirection/add",
      //   payload: "High",
      // });
    }
  }, [exchangeSearchDM]);

  function getSalesPageData(switchNum, pageParm) {
    switch (switchNum) {
      case 1:
        new loadExchangeData(tokens, pageParm, "P2P", null, null, null);
        // console.log("p2p");
        break;
      case 2:
        new loadExchangeData(tokens, pageParm, "IPO", null, null, null);
        // console.log("ipo");
        break;
      case 3:
        new loadExchangeData(
          tokens,
          pageParm,
          null,
          "price",
          priceCategoryDirection === "High" ? "DESC" : "ASC",
          null
        );
        // console.log("price");
        break;
      case 4:
        new loadExchangeData(tokens, pageParm, "potential", null, null, null);
        // console.log("potential");
        break;
      case 5:
        new loadExchangeData(
          tokens,
          pageParm,
          null,
          null,
          null,
          influencerUsername
        );
        // console.log("smi");
        break;

      default:
        // console.log("default switch case");
        break;
    }
  }
  function addSalesElements() {
    // console.log(newPage);
    let tempFeedElements = [];
    // if (pageIndex === 1) {
    if (!feedListElements) {
      tempFeedElements = [];
    } else {
      tempFeedElements = feedListElements;
    }
    for (let i = 0; i < newPage.length; i++) {
      tempFeedElements.push(
        <div key={i} className="StockCardCommentE">
          {/* <Link to={"/exchange/sale-view/" + newPage[i].id}> */}
          <SaleOfferCard SaleOffer={newPage[i]} />
          {/* </Link> */}
          <StockComment SaleOffer={newPage[i]} />
        </div>
      );
    }
    setfeedListElements(tempFeedElements);
    // console.log(tempFeedElements);
  }

  return (
    !exchangeSearchDM && (
      <div id="SalesFeed" className="SalesFeed">
        {feedListElements}
        {(waitingForData || displayFakeLoader) && (
          <div className="SalesFeed__Loader">
            <Loader />
          </div>
        )}
        {feedListElements
          ? feedListElements.length === 0 && (
              <p className="SalesFeed__NoResultsTxt headline">
                There is no end to passion!
                <br /> More passion stocks coming soon
              </p>
            )
          : null}

        {feedListElements === 0 && (
          <p className="SalesFeed__UnableTxt headline">
            Unable to view data at this time -<br /> server is updating
          </p>
        )}
      </div>
    )
  );
}
