import "./style.css";
import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import React from "react";
import store from '../../store/store';
import PSLogo from '../../assets/images/common/ps-logo-one-row.png';

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Desktop(props) {
  const windowInnerSize = useSelector(selectWindowInnerSize);

  useEffect(() => {
    store.dispatch({
      type: "ui/windowInnerSize/add",
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  }, [])
  
  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="Page headline" style={{ height: windowInnerSize.height }}>
        <div
          className="Desktop__MessageContainer"
          style={{
            width: windowInnerSize.width * 0.8,
            height: windowInnerSize.height * 0.8,
            marginTop:windowInnerSize.height*0.1,
          }}
        >
          <img src={PSLogo} alt="PassionStocks Logo" className="Desktop__PSLogo"/>
          <p className="Desktop__Text unnamed-character-style-1">
          In order to access the demo site,<br/> please open the mobile version.
          <br /><br/> In this version you can continue to<br/> the landing page.
          </p><button
            className="Desktop__Button btn--yellow-white headline"
            onClick={() => {
              window.location.href = "https://passionstocks.com";
            }}
          >
            {" "}
            continue
          </button>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
