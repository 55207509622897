import StockHolding from "../../StockHolding.jsx";
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { API_URL } from "../../../../../../config.js";
import { ROLES } from "../../../../../../tools/role.js";
import Loader from "../../../../../../components/common/Loader/index.jsx";

const selectProfile = (state) => state.profile;
const selectTokens = (state) => state.tokens;

export default function IssuerUnitSlide(props) {
  const profile = useSelector(selectProfile);
  const tokens = useSelector(selectTokens);

  const [issuerUnit, setissuerUnit] = useState(null);

  useEffect(() => {
    setissuerUnit(null);

    if (
      (props.portfolioMode === 0 || props.portfolioMode === 2) &&
      profile.accountType & ROLES.ISSUER
    ) {
      setissuerUnit(0);
      fetchData();
    }
  }, [props.portfolioMode]);

  async function fetchData() {
    console.log("fetch issuer units");
    try {
      const response = await axios.get(
        API_URL + "/api/influencers/shares-to-release",
        {
          headers: {
            Authorization: tokens.accessToken,
          },
        }
      );
      console.log("issuer units:");
      console.log( response.data);
      
      setissuerUnit(response.data);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("Response data:", error.response.data); // Response data
        console.log("Status code:", error.response.status); // Status code
        console.log("Headers:", error.response.headers); // Response headers
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Request made but no response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error during request setup:", error.message);
      }
      setissuerUnit(null);
    }
  }
  return issuerUnit ? (
    <div className="BrandUnits__IssuerUnit">
      <StockHolding
        isIssuer={true}
        isManager={profile.accountType & ROLES.MANAGER ? true : false}
        stockHolding={issuerUnit}
      />
    </div>
  ) : issuerUnit === 0 ? (
    <div
      style={{
        position: "relative",
        margin: "0 auto ",
        padding: "90px 0",
        boxSizing: "initial",
        height: "40px",
        width: "40px",
      }}
    >
      <Loader />
    </div>
  ) : null;
}
