import "./style.css";
import store from "../../../../store/store";

import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import BackPage from "../../../../assets/images/common/back-page.png";
import PPPic1 from "../../../../assets/images/education/passion-prdct/pic-1.png";
import PPPic2 from "../../../../assets/images/education/passion-prdct/pic-2.png";
import PPPic3 from "../../../../assets/images/education/passion-prdct/pic-3.png";
import PPPic4 from "../../../../assets/images/education/passion-prdct/pic-4.png";
import PPPic5 from "../../../../assets/images/education/passion-prdct/pic-5.png";
import PPX from "../../../../assets/images/education/passion-prdct/x.png";
import ArrowDownPP from "../../../../assets/images/common/arrow-down-yellow-circle.png";
import { Link } from "react-router-dom";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function PassionProducts() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Notification",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        id="PassionProductsDiv"
        className="Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <div style={{ height: "468vw" }}>
          <img
            src={BackPage}
            alt=""
            id="BackPageNot"
            onClick={() => {
              navigate(-1);
            }}
          />
          <p id="PPTitle" className="unnamed-character-style-1">
            PASSIONPRODUCTS
          </p>
          <img src={PPPic1} alt="" id="PPPic1" />

          <p id="WhatQstTitlePP">
            <span>What is a passionproduct? </span>
            <br />
            A digital product that the social media <br /> influencer offers to
            their supporters who
            <br /> have bought their passionstocks for non-
            <br />
            commercial use. For example a <br />
            passionproduct can be an instagram story
            <br /> mention.{" "}
          </p>

          <img src={PPPic2} alt="" id="PPPic2" />

          <p id="HowQstTitlePP">
            <span>
              What happens when I receive
              <br /> passionproduct?{" "}
            </span>
            <br />
            Once you receive your passionproduct the <br />
            stock you traded-in will go back to your
            <br /> influencer.{" "}
          </p>

          <img src={PPPic3} alt="" id="PPPic3" />

          <p id="WhatDoQstTPP">
            <span>
              I no longer want a<br /> passionproduct?
            </span>
            <br />
            A trade-in can take up to 6 months. If it has
            <br /> not yet been delivered, you can cancel the
            <br /> trade-in and get your stock back.
          </p>
          <img src={PPX} alt="" id="PPX" />
          <img src={PPPic4} alt="" id="PPPic4" />

          <p id="WhereCanQstTPP">
            <span>
              Where can I get a<br /> passionporduct?
            </span>
            <br />
            In your wallet you can trade-in your
            <br /> influencer passionstock for a <br />
            passionproduct from that influencer.
          </p>

          <img src={PPPic5} alt="" id="PPPic5" />
          <Link to={"/exchange"}>
            <p id="GoToPP">Go to the exchange?</p>
            <img src={ArrowDownPP} alt="" id="ArrowDownPP" />
          </Link>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
