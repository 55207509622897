import "./style.css";
import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import store from '../../../store/store';
import BasicMenu from "../../../components/common/menus/Basic";

import BackPage from "../../../assets/images/common/back-page.png";
import SettingIcon from "../../../assets/images/menu/setting.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Menu_AccountAndSetting(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  const list = [
    {
      text: "Change Password",
      link: "/account-and-setting/change-password",
      icon: "/menu/key.png",
    },
    {
      text: "Payment Method",
      link: "/account-and-setting/payment-method",
      icon: "/menu/wallet.png",
    },
    {
      text: "Banking Details",
      link: "/account-and-setting/banking-details",
      icon: "/menu/bank.png",
    },
    { text: "Edit Profile", link: "/profile/edit", icon: "/menu/profile.png" },
    {
      text: "Notifications Preferences",
      link: "/account-and-setting/notifications-preferences",
      icon: "/menu/bell.png",
    },
    {
      text: "Delete Account",
      link: "/delete-account",
      icon: "/menu/delete.png",
    },
  ];

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="Menu_AccountAndSetting Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <div style={{ position: "relative", marginTop: "20vw" }}>
          <img
            src={BackPage}
            alt=""
            // id="BackPageTrade"
            className="back-btn"
            style={{
              top: 0,
              left: 0,
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <h1 className="headline__menu" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <img
              src={SettingIcon}
              alt="Account and setting"
              // className="Menu_AccountAndSetting__Icon"
            />
            Account & Settings
          </h1>
        </div>

        <BasicMenu list={list} />
      </div>
    </AnimateKeyframes>
  );
}
