import "./ArrowToSlide.css";
import React from "react";
// import { API_URL } from "../../../../config";

import { useSelector } from "react-redux";
import classnames from "classnames";

import ArrowR from "../../../../assets/images/common/arrow-to-slide-right.png";
import ArrowL from "../../../../assets/images/common/arrow-to-slide-left.png";
import { useState,useEffect } from "react";

const selectArrowsSlideStatus = (state) => state.walletArrowsSlideStatus;

export default function ArrowToSlide(props) {
  const arrowsSlideStatusSelector = useSelector(selectArrowsSlideStatus);
  const [arrowsSlideStatus, setarrowsSlideStatus] = useState(null);
  useEffect(() => {
    if (props.arrowsSliderStatus) {
      setarrowsSlideStatus(props.arrowsSliderStatus);
    } else {
      setarrowsSlideStatus(arrowsSlideStatusSelector);
    }
  }, [props.arrowsSliderStatus, arrowsSlideStatusSelector]);

  return (
    <div>
      <img
        src={ArrowR}
        alt=""
        id="ArrowR"
        className={classnames("arrow_right", {
          "arrow_right--hidden": !arrowsSlideStatus?.right,
        })}
        onClick={() => {
          props.slideToRight();
        }}
      />
      <img
        src={ArrowL}
        alt=""
        id="ArrowL"
        className={classnames("arrow_left", {
          "arrow_left--hidden": !arrowsSlideStatus?.left,
        })}
        onClick={() => {
          props.slideToLeft();
        }}
      />
    </div>
  );
}
