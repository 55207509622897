import "./style.css";
import "../style.css";
import store from "../../../../../../store/store";
import { AnimateKeyframes } from "react-simple-animate";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ROLES } from "../../../../../../tools/role";

import BackPage from "../../../../../../assets/images/common/back-page.png";
import DeleteIcon from "../../../../../../assets/images/menu/delete.png";

const selectProfile = (state) => state.profile;

export default function DeleteAccount_Investor(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const [brandUnitsSelectedCheckbox, setBrandUnitsSelectedCheckbox] =
    useState(null);
  const [balanceSelectedCheckbox, setBalanceSelectedCheckbox] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state !== "delete-account") {
      navigate("/menu");
    }
  }, [location]);

  const profile = useSelector(selectProfile);

  const handleConfirmBtn = () => {
    navigate(
      profile.accountType & ROLES.ISSUER
        ? "/delete-account/issuer-story-mention"
        : "/delete-account/we-are-sorry",
      { state: "delete-account" }
    );
  };
  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="DeleteAccount Page headline">
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            position: "relative",
            marginTop: "20vw",
          }}
        >
          <img
            src={BackPage}
            alt=""
            // id=""
            className="back-btn"
            style={{
              position: "relative",
              top: 0,
              left: 0,
              marginRight: "12px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <h1
            className="headline__menu"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete account"
              // className="Menu_AccountAndSetting__Icon"
            />
            Delete Account
          </h1>
        </div>

        <div>
          <p
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              textTransform: "uppercase",
              textAlign: "center",
              fontWeight: "lighter",
              margin: "15px 5vw"

            }}
          >
            What would you like to do
            <br /> with the brand units in your portfolio?
          </p>
          <div
            className="headline"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              margin: "0 auto",
              position: "relative",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={brandUnitsSelectedCheckbox === 0}
                onChange={() => {
                  setBrandUnitsSelectedCheckbox(
                    brandUnitsSelectedCheckbox === 0 ? null : 0
                  );
                }}
                style={{
                  width: "20px",
                  background: "#ffbf12",
                }}
              />
              <p
                className="headline "
                style={{ fontSize: "18px", margin: "0" }}
              >
                Back to the creator
              </p>
            </div>{" "}
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={brandUnitsSelectedCheckbox === 1}
                onChange={() => {
                  setBrandUnitsSelectedCheckbox(
                    brandUnitsSelectedCheckbox === 1 ? null : 1
                  );
                }}
                style={{
                  width: "20px",
                  background: "#ffbf12",
                }}
              />
              <p
                className="headline "
                style={{ fontSize: "18px", margin: "0" }}
              >
                Delete them
              </p>
            </div>{" "}
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={brandUnitsSelectedCheckbox === 2}
                onChange={() => {
                  setBrandUnitsSelectedCheckbox(
                    brandUnitsSelectedCheckbox === 2 ? null : 2
                  );
                }}
                style={{
                  width: "20px",
                  background: "#ffbf12",
                }}
              />
              <p
                className="headline "
                style={{ fontSize: "18px", margin: "0" }}
              >
                I don’t care
              </p>
            </div>
          </div>
          <div>
            <p
             style={{
              fontSize: "16px",
              lineHeight: "22px",
              textTransform: "uppercase",
              textAlign: "center",
              fontWeight: "lighter",
              margin: "25px 5vw"
            }}
            >
              What would you like to do <br />
              with the available balance in your wallet?{" "}
            </p>
            <button
              className="btn--yellow_border-white"
              style={{ fontSize: "14px", lineHeight: "30px",display:'block',margin:'0 auto' }}
            >
              Withdraw
            </button>
            <div style={{ display: "flex",justifyContent:'center', alignItems: "center",margin:'15px auto',position:'relative' }}>
              <input
                type="checkbox"
                checked={brandUnitsSelectedCheckbox === 0}
                onChange={() => {
                  setBalanceSelectedCheckbox(
                    balanceSelectedCheckbox === 0 ? null : 0
                  );
                }}
                style={{
                  width: "20px",
                  background: "#ffbf12",
                }}
              />
              <p
                className="headline "
                style={{ fontSize: "18px", margin: "0" }}
              >
                I don't care
              </p>
            </div>{" "}
          
          </div>
        </div>

        <button className="DeleteAccount__Btn headline"  onClick={handleConfirmBtn}>
          Confirm
        </button>
      </div>
    </AnimateKeyframes>
  );
}
