import "./style.css";
import React from "react";
export default function BreakLine(props) {
  return (
    <svg
      className={"BreakLine " + (props.type ? "BreakLine--" + props.type : "")}
      height={props.height ? props.height : "1px"}
      width={props.width ? props.width : "87px"}
      style={props.style}
    >
      <line
        x1="0"
        x2="100%"
        y1="0"
        y2="0"
        stroke={props.storkeColor ? props.storkeColor : "#707070"}
        stroke-width="100%"
        stroke-linecap="butt"
      />
    </svg>
  );
}
