import "./style.css";
import { AnimateKeyframes } from "react-simple-animate";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import clearStoreWithoutConst from "../../../../../../utiles/clearStoreWithoutConst";

import BackPage from "../../../../../../assets/images/common/back-page.png";
import DeleteIcon from "../../../../../../assets/images/menu/delete.png";

export default function DeleteAccount_WeAreSorry() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state !== "delete-account") {
      navigate("/menu");
    }
  }, [location]);

  const handleDeleteAccountBtn = () => {
    clearStoreWithoutConst();
    navigate("/delete-account/confirmation", {
      state: "delete-account",
      replace: true,
    });
  };

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="DeleteAccount Page headline">
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            position: "relative",
            marginTop: "20vw",
          }}
        >
          <img
            src={BackPage}
            alt=""
            // id=""
            className="back-btn"
            style={{
              position: "relative",
              top: 0,
              left: 0,
              marginRight: "12px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <h1
            className="headline__menu"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete account"
              // className="Menu_AccountAndSetting__Icon"
            />
            Delete Account
          </h1>
        </div>

        <p
          style={{ fontSize: "11px", lineHeight: "15px", textAlign: "center" }}
        >
          We are sorry to see you go.
          <br />
          <br />
          Before confirming the deletion of your account, please note that this
          action is permanent and cannot be revised.
          <br />
          <br />
          Additionally, we would like to remind you that PassionStocks may
          retain necessary data as required and/or allowed by law, as specified
          in the terms and conditions, privacy policy, and cookie policy. These
          policies outline the guidelines for data retention and usage and are
          available for reference to anyone visiting our sites.
          <br />
          <br />
          If you have any concerns or inquiries, please feel free to contact us
          at our support center.
        </p>

        <button className="DeleteAccount__Btn" onClick={handleDeleteAccountBtn}>
          Delete account
        </button>
      </div>
    </AnimateKeyframes>
  );
}
