import "./style.css";
import store from "../../../store/store";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import InstagramBigIcon from "../../../assets/images/common/instagram-icon.png";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import load3rdProfile from "../../../loaders/3rdProfile";

const selectTokens = (state) => state.tokens;

export default function PassionPrdct(props) {
  const tokens = useSelector(selectTokens);

  const navigate = useNavigate();
  return (
    <div id="PassionPrdctDiv" className="headline">
      <img src={InstagramBigIcon} alt="" id="SMInstaLogo" />
      <p id="SMAmount">{props.PPTData.smVolume} x Story mention</p>
      <p id="SMIHashtag">{props.PPTData.handle}</p>

      <p
        id="TradeBackBtn"
        onClick={async () => {
          load3rdProfile(tokens, props.PPTData.providerId);
          // // console.log(props.PPTData.id);
          store.dispatch({
            type: "app/pptConvertionTradeData/add",
            payload: props.PPTData,
          });
          navigate("/ppt/convert-product-to-stock");
        }}
      >
        Trade back to PassionStock
      </p>
    </div>
  );
}
