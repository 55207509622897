import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import isVarNegative from "../../../../helpers/isVarNegative";
import AgentNotIPOedCreativeCard from "../../../../components/common/creative-cards/AgentNotIPOed";
import GraphUserData from "../../../../components/common/graphs/GraphUserData";
import StockResponses from "../../../../components/common/StockEngagement";
import UpdateDeliveredStoryMention from "../../../../components/common/UpdateDeliveredStoryMention";
import SocialMediaValues from "../../../../components/common/SocialMediaValues";
import AgencyMembersSlider from "./components/MembersSlider";
import SharePopUp from "../../../../components/common/SharePopUp";
import PassionPrdct from "../../../../components/common/PassionPrdct";

import "./style.css";
import store from "../../../../store/store";

import AgencyProfileBckgImage from "../../../../assets/images/profile/cover-img-default.jpg";
import EditIcon from "../../../../assets/images/common/edit.png";
import FullStar from "../../../../assets/images/common/star-full.png";
import EmptyStar from "../../../../assets/images/common/star-empty.png";
import SharingBtn from "../../../../assets/images/common/share.png";

import loadProfile from "../../../../loaders/profile";
import loadWallet from "../../../../loaders/wallet";
import fixFractionDigits from "../../../../helpers/fixFractionDigits";

const selectProfile = (state) => state.profile;
const selectTokens = (state) => state.tokens;
const selectWallet = (state) => state.wallet;
const selectPPTPendingList = (state) => state.pptPendingList;
const selectPPTDeliveryList = (state) => state.pptDeliveryList;
const selectAgencyTokenStorage = (state) => state.token_storage;
const selectToRefreshed = (state) => state.toRefresh;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Profile(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: true },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Profile",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const tokens = useSelector(selectTokens);
  const profile = useSelector(selectProfile);
  const wallet = useSelector(selectWallet);
  const PPTPendingList = useSelector(selectPPTPendingList);
  const PPTDeliveryList = useSelector(selectPPTDeliveryList);
  const isRefreshed = useSelector(selectToRefreshed);

  // console.log(tokens);
  useEffect(() => {
    if (tokens) {
      loadProfile(tokens);
      if (profile?.agent) {
        loadWallet(tokens, profile.accountType);
      }
    }
  }, []);

  const [StatsOrPrdct, setStatsOrPrdct] = useState("Stats");
  const [StatsAgencyOrClients, setStatsAgencyOrClients] = useState("Agency");
  
  let cardData = profile
    ? {
        name: profile.name,
        city: profile.city,
        profilePic: profile.profilePic,
      }
    : null;

  
  const [SharePopUpDisplay, setSharePopUpDisplay] = useState(false);

  const isAgencyStats = useSelector(selectAgencyTokenStorage);

  //Animation:
  const [animateSlide, setanimateSlide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setanimateSlide(true);
    }, 0);
    return () => {
      setanimateSlide(false);
    };
  }, []);
  return profile && !isAgencyStats /* needed!*/ && isRefreshed ? (
    <div
      className={
        "AgentNotIPOedProfile Page headline " +
        (!animateSlide ? "animate-to-left" : null)
      }
      style={{ height: windowInnerSize.height }}
    >
      <div
        className="AgentNotIPOedProfile__Background"
        style={{
          backgroundImage: `url(${
            profile.coverPic ? profile.coverPic : AgencyProfileBckgImage
          })`,
        }}
      />

      <Link to="/profile/edit">
        <img src={EditIcon} alt="" className="AgentNotIPOedProfile__EditIcon" />
      </Link>
      <div style={{ position: "relative" }}>
        <div className="AgentNotIPOedProfile__CreativeCardPosition">
          <AgentNotIPOedCreativeCard cardData={cardData} />
        </div>
      </div>

      <div className="AgentNotIPOedProfile__BtnContainer">
        <Link to={"/unavailable" /*"/Profile/Agency/Dashboard"*/}>
          <p className="AgentNotIPOedProfile__DashboardBtn">Dashboard</p>
        </Link>
        <Link to="/profile/edit">
          <p className="AgentNotIPOedProfile__EditBtn">Edit</p>
        </Link>

        {/* <img src={SharingBtn} alt="" id="AgencySharingBtn" /> */}
        <img
          src={SharingBtn}
          alt=""
          className="AgentNotIPOedProfile__SharingBtn"
          onClick={() => {
            if (!SharePopUpDisplay) {
              setSharePopUpDisplay(true);
            }
          }}
        />
      </div>
      {SharePopUpDisplay && (
        <div
          className="AgentNotIPOedProfile__BackFromShare"
          onClick={() => {
            if (SharePopUpDisplay) {
              setSharePopUpDisplay(false);
            }
          }}
        />
      )}
      {SharePopUpDisplay && (
        <div>
          <SharePopUp
            Agencyid={store.getState().profile.agent.id}
            Agencyname={store.getState().profile.name}
          />
        </div>
      )}

      <p className="AgentNotIPOedProfile__Describe">{profile.bio}</p>

      <div className="AgentNotIPOedProfile__SocialMPos">
        <SocialMediaValues SocialMedia={null} dominatingPlatform={null} />
      </div>

      <div className="AgentNotIPOedProfile__StatsPrdctContainer">
        <div className="AgentNotIPOedProfile__PrdctStatsBtns">
         
          <p className="AgentNotIPOedProfile__MembersBtn" onClick={setAsProducts}>
            Members
          </p>
          <p className="AgentNotIPOedProfile__StatsBtn" onClick={setAsStats}>
            Stats
          </p>
        </div>
        <svg height="0.5vw" width="84vw" className="AgentNotIPOedProfile__BasicLine">
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#ffbf12"
            stroke-width="10%"
            stroke-linecap="butt"
          />
        </svg>
        <svg
          height="0.85vw"
          width={StatsOrPrdct === "Stats" ? "22vw" : "40vw"}
          className={
            StatsOrPrdct !== "Stats"
              ? "AgentNotIPOedProfile__StatsSwitchLine"
              : "AgentNotIPOedProfile__PrdctSwitchLine"
          }
        >
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#ffbf12"
            stroke-width="10%"
            stroke-linecap="butt"
          />
        </svg>
        {StatsOrPrdct === "Stats" && (
          <div className={"AgentNotIPOedProfile__StockInformation"}>
            <select
              className="AgentNotIPOedProfile__SelectStatsFilter headline"
              onChange={(e) => {
                setStatsAgencyOrClients(e.target.value);
              }}
            >
            
              <option value={"TotalMembers"} selected>Total members</option>
              <option value={"Members"}>Members</option>
            </select>

            {StatsAgencyOrClients !== "Members" && (
              <div style={{ width: "100%" }}>
                <GraphUserData Balance={wallet ? wallet.withdrawalValue : 0} />
                <div
                  className="AgentNotIPOedProfile__FlexContainer"
                  style={{ marginTop: "10vw" }}
                >
                  <div className="AgentNotIPOedProfile__SquareData">
                    <p>{"--"}</p>
                    <p>Days</p>
                  </div>
                  <div className="AgentNotIPOedProfile__SquareData">
                    <p>
                      {wallet ? wallet.currency.symbol : ""}
                      {"--"}
                    </p>
                    <p>Made this week</p>
                  </div>
                  <div className="AgentNotIPOedProfile__SquareData">
                    <p>{"--"}%</p>
                    <p>Growth Rate</p>
                  </div>
                </div>
              </div>
            )}
            {StatsAgencyOrClients === "Members" && (
              <div className="ClientsStatsDiv">
                <AgencyMembersSlider
                  agencyMembersList={profile.agent.influencers}
                  isAgentAccount={true}
                />

                {/* <Link to={"/Unavailable"}>
                                    <p id="AddSMIBtn">Add SMI</p>
                                </Link> */}
              </div>
            )}
          </div>
        )}

        {StatsOrPrdct !== "Stats" && (
          // <div style={{ position: "absolute", top: "5vw", left: "-8vw" }}>
          //   <div id="UDSMPos">
          //     <UpdateDeliveredStoryMention deliveryList={PPTDeliveryList} />
          //   </div>
          //   {PPTPendingList ? (
          //     <div id="PrdctPendingListDivAP">
          //       <p
          //         id={!profile ? "PrdctsPendingTxt" : "None"}
          //         className="headline"
          //       >
          //         Passionproducts pending:
          //       </p>

          //       {getPassionPrdctsList(profile)}
          //     </div>
          //   ) : null}
          //   {/*<div id="SMConsulation">
          //                   <p>Social Media<br/>Consulation</p>
          //                   <img src={EditIcon} alt="" id="SMConsulationEdit"/>
          //                   <p>{"3"}</p>
          //                   <p>Passion <br/> Stock</p>
          //               </div>*/}
          // </div>
        null)}
      </div>
    </div>
  ) : null;
  function setAsStats() {
    if (StatsOrPrdct !== "Stats") {
      setStatsOrPrdct("Stats");
    }
  }
  function setAsProducts() {
    if (StatsOrPrdct !== "Products") {
      setStatsOrPrdct("Products");
    }
  }
  function setAgencyClickedBtn() {
    if (StatsAgencyOrClients !== "Agency") {
      setStatsAgencyOrClients("Agency");
    }
  }
  function setMembersClickedBtn() {
    if (StatsAgencyOrClients === "Agency") {
      setStatsAgencyOrClients("Members");
    }
  }

  function getPassionPrdctsList() {
    let PPTPLElements = [];
    for (let i = 0; i < PPTPendingList.length; i++) {
      PPTPLElements.push(<PassionPrdct PPTData={PPTPendingList[i]} />);
    }

    return PPTPLElements;
  }
}
function getRateStars(Rating) {
  let FullStars = Rating;
  let EmptyStars = 5 - Rating;
  let StarsCounter = 5;
  let StarsLine = [];

  for (let i = 0; i < FullStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={FullStar}
        alt=""
        id="FullStar"
      />
    );
  }
  for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={EmptyStar}
        alt=""
        id="FullStar"
      />
    );
  }

  return StarsLine;
}
