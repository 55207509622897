import "./style.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import store from "../../../../../store/store";

const selectExchangeSearchDM = (state) => state.exchangeSearchDM;
const selectSalesFeedSelectedCategory = (state) =>
  state.salesFeedSelectedCategory;
const selectPriceCategoryDirection = (state) =>
  state.salesFeedPriceCategoryDirection;

export default function StocksCategorySwitcher() {
  const exchangeSearchDM = useSelector(selectExchangeSearchDM);
  const salesFeedSelectedCategory = useSelector(
    selectSalesFeedSelectedCategory
  );
  const priceCategoryDirection = useSelector(selectPriceCategoryDirection);

  const navigate = useNavigate();

  let { influencerUsername } = useParams();
  // Default category:
  useEffect(() => {
    // exclude when searching for invest in influencer - see 'SaleFeed' relevant useEffect
    if (!influencerUsername) { 
      store.dispatch({
        type: "app/salesFeedSelectedCategory/add",
        payload: 3,
      });
      store.dispatch({
        type: "app/salesFeedPriceCategoryDirection/add",
        payload: "High",
      });
      store.dispatch({ type: "app/salesFeedPageIndex/add", payload: 1 });
    }
    return () => {
      // store.dispatch({ type: "app/salesFeedAddNewPage/add", payload: [] });
      store.dispatch({ type: "app/salesFeedSelectedCategory/add", payload: 3 });
      store.dispatch({ type: "app/salesFeedPageIndex/add", payload: 1 });
      store.dispatch({
        type: "app/salesFeedPriceCategoryDirection/add",
        payload: "High",
      });
    };
  }, []);

  return (
    !exchangeSearchDM && (
      <div>
        <div
          id="SwitchStocksExchange"
          className="StocksCategorySwitcher headline"
        >
          <span
            className={
              salesFeedSelectedCategory === 3
                ? "StocksCategorySwitcher__SelectedCategory"
                : null
            }
            onClick={
              salesFeedSelectedCategory !== 3
                ? () => {
                    // clear url (after search smi and click invest)
                    navigate("/exchange");
                    //reset page index
                    store.dispatch({
                      type: "app/salesFeedPageIndex/add",
                      payload: 1,
                    });
                    // update category index
                    store.dispatch({
                      type: "app/salesFeedSelectedCategory/add",
                      payload: 3,
                    });
                  }
                : null
            }
          >
            STOCK PRICE
          </span>
          <span
            className={
              salesFeedSelectedCategory === 1
                ? "StocksCategorySwitcher__SelectedCategory"
                : null
            }
            onClick={
              salesFeedSelectedCategory !== 1
                ? () => {
                    navigate("/exchange");
                    store.dispatch({
                      type: "app/salesFeedPageIndex/add",
                      payload: 1,
                    });
                    store.dispatch({
                      type: "app/salesFeedSelectedCategory/add",
                      payload: 1,
                    });
                  }
                : null
            }
          >
            HOT STOCKS
          </span>
          <span
            className={
              salesFeedSelectedCategory === 2
                ? "StocksCategorySwitcher__SelectedCategory"
                : null
            }
            onClick={
              salesFeedSelectedCategory !== 2
                ? () => {
                    navigate("/exchange");
                    store.dispatch({
                      type: "app/salesFeedPageIndex/add",
                      payload: 1,
                    });
                    store.dispatch({
                      type: "app/salesFeedSelectedCategory/add",
                      payload: 2,
                    });
                  }
                : null
            }
          >
            NEW
          </span>
          <span
            className={
              salesFeedSelectedCategory === 4
                ? "StocksCategorySwitcher__SelectedCategory"
                : null
            }
            onClick={
              salesFeedSelectedCategory !== 4
                ? () => {
                    navigate("/exchange");
                    store.dispatch({
                      type: "app/salesFeedPageIndex/add",
                      payload: 1,
                    });
                    store.dispatch({
                      type: "app/salesFeedSelectedCategory/add",
                      payload: 4,
                    });
                  }
                : null
            }
          >
            POTENTIAL
          </span>
        </div>

        {salesFeedSelectedCategory !== 5 && (
          <div
            id={
              // !SearchInputText
              //   ? "ChartTrade"
              //   : SearchInputText.length === 0 ?
              "ChartTrade"
              // : "HideNF"
            }
          >
            <p
              className="unnamed-character-style-1"
              id={salesFeedSelectedCategory === 1 ? "HotTradeFTxt" : "Hide"}
            >
              TRADE FREQUENCY
            </p>
            <p
              className="unnamed-character-style-1"
              id={salesFeedSelectedCategory === 2 ? "NewHotIPOSTxt" : "Hide"}
            >
              HOT IPO'S
            </p>
            <p
              className="unnamed-character-style-1"
              id={salesFeedSelectedCategory === 3 ? "StockPHighLowTxt" : "Hide"}
            >
              <span
                onClick={
                  priceCategoryDirection !== "High"
                    ? () => {
                        store.dispatch({
                          type: "app/salesFeedPageIndex/add",
                          payload: 1,
                        });
                        store.dispatch({
                          type: "app/salesFeedPriceCategoryDirection/add",
                          payload: "High",
                        });
                      }
                    : null
                }
              >
                HIGH
              </span>{" "}
              <span
                onClick={
                  priceCategoryDirection !== "Low"
                    ? () => {
                        store.dispatch({
                          type: "app/salesFeedPageIndex/add",
                          payload: 1,
                        });
                        store.dispatch({
                          type: "app/salesFeedPriceCategoryDirection/add",
                          payload: "Low",
                        });
                      }
                    : null
                }
              >
                LOW
              </span>
            </p>
            <svg
              id={
                salesFeedSelectedCategory !== 3
                  ? "Hide"
                  : priceCategoryDirection === "High"
                  ? "HighLine"
                  : "LowLine"
              }
              height="0.4vw"
              width="10%"
            >
              <line
                x1="0"
                x2="100%"
                y1="0"
                y2="0"
                stroke="white"
                stroke-width="100%"
                stroke-linecap="butt"
              />
            </svg>
            <p
              className="unnamed-character-style-1"
              id={
                salesFeedSelectedCategory === 4 ? "PotentialGrowthFTxt" : "Hide"
              }
            >
              GROWTH
            </p>
          </div>
        )}
      </div>
    )
  );
}
