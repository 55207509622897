
import store from '../../../../store/store';
// import { persistor } from '../../../../../store/store';
import { API_URL } from '../../../../config';
// import unauthorized from "../../../functions/unauthorized";
// import { param } from "jquery";

export default function loadMySales(tokens) {

    // console.log('loadMySales');
    var axios = require('axios');

    var config = {
        method: 'get',
        url: API_URL + '/api/sales/my-sales',
        headers: {
            Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        }
    };

    axios(config)
        .then(function (response) {
            // console.log(JSON.stringify(response.data));
            store.dispatch({ type: 'domain/userSales/add', payload: response.data })
            // await persistor.purge();
        })
        .catch(function (error) {
            // console.log(error);
        });
}