import "./style.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";
import React from "react";
/*import {API_URL} from '@src/config.js';*/

import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
import AboutUsBckgImg from "../../../assets/images/about-us/background.png";
import CircleAU from "../../../assets/images/about-us/circle.png";
import RectaAU from "../../../assets/images/about-us/recta.png";
import ChartIceAU from "../../../assets/images/about-us/chart-ice.png";
import ArrowRightAU from "../../../assets/images/common/arrow-right.png";
import EmojiHumble from "../../../assets/images/common/emoji-humble.png";
import BreakLine from "../../../components/common/lines/BreakLine";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function About() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Home",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(-100vw)",
        "transform: translateX(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="About Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          style={{ top: "5vw" }}
          onClick={() => {
            navigate(-1);
          }}
        />

        <img src={AboutUsBckgImg} alt="" className="About__Img" />
        <p className="About__Headline1 unnamed-character-style-1">We Are</p>
        <p className="About__Headline2 unnamed-character-style-1">
          The
          <br />
          <span style={{ textDecoration: "line-through" }}>
            creator economy
          </span>
          <br />
          Brand Economy
        </p>

        <div className="About__Shadow" />

        <BreakLine type="GlowBlue" style={{ margin: "30px auto " }} />

        <p className="About__Paragraph1">
          <img src={CircleAU} alt="" className="About__Circle1" />
          <img src={RectaAU} alt="" className="About__Rect" />
 Fueled by Passion and Personal Branding:
          <br />
          The Creator Economy.
          <br />
          ________
          <br />
          <br />
          In the dynamic realm of the creator economy, passionate individuals
          are the driving force. Billions of people engage with one another
          daily, forming powerful connections that give rise to remarkable
          achievements. Central to these interactions is the captivating power
          of personal branding.
          <br />
          <br />
          Personal branding is the essence of what sets creators apart. It
          encompasses their introduction, vibes, values, passions, and
          dreams—the unique journey they undertake. It’s this amalgamation that
          fuels engagement, forging profound bonds between creators and their
          audiences.
          <br />
          <br />
          A compelling personal brand captivates attention and resonates deeply.
          It creates a distinct identity, standing out amidst a sea of content
          and personalities. Authenticity is key, as creators invite others to
          join their journey. Relatability and connection become the driving
          forces behind audience engagement, support, and the formation of
          vibrant communities.
          <br />
          <br />
          Personal branding in the creator economy unlocks limitless
          possibilities. It builds trust, establishes credibility, and
          cultivates a loyal following. Creators become beacons of inspiration
          and aspiration, going beyond entertainment to make a lasting impact.
          Audiences gravitate toward creators whose stories and passions align,
          fostering deep connections and a sense of community.
          <br />
          <br />
          The creator economy thrives when passionate individuals come together,
          birthing extraordinary achievements. Creators shape industries and
          transform lives, from art and music to education and entrepreneurship.
          By embracing personal branding, creators empower themselves to reach
          new heights, creating resonant content that leaves a lasting impact.
          <br />
          <br />
          As the creator economy evolves, recognizing the power of personal
          branding is essential. By cultivating an authentic and captivating
          presence, creators attract and engage audiences, fostering dedicated
          support and a vibrant community. In this thriving ecosystem, the
          journeys of creators intertwine with those of their audience, shaping
          the future of the creator economy.{" "}
          <img src={CircleAU} alt="" className="About__Circle2" />
        </p>
        <BreakLine type="GlowBlue" style={{ margin: "30px auto" }} />
        <p className="About__Paragraph3">
          At PassionStocks, we are dedicated to building a platform that
          empowers individuals to establish their personal brand in a way that
          is enjoyable, invigorating, and above all, authentic. We believe in
          creating an environment where people can truly be themselves,
          unlocking their potential through the power of personal branding.
          <br />
          <br />
          Our mission is rooted in the firm belief that everyone has the ability
          to inspire others, build a sustainable livelihood, and create wealth
          simply by embracing their true selves. We are passionate about
          enabling individuals to tap into their unique talents, passions, and
          aspirations to make a meaningful impact in the world.
          <br />
          <br />
          Through our platform, we provide the tools, resources, and guidance
          necessary to navigate the exciting and sometimes challenging journey
          of personal branding. We believe that self-expression, creativity, and
          embracing one’s individuality are the keys to unlocking success in the
          creator economy.
          <br />
          <br />
          Join us at PassionStocks as we embark on this transformative
          adventure, where individuals can discover their authentic voice,
          connect with like-minded communities, and achieve financial prosperity
          by being their true selves. Together, let’s build a world where
          personal branding becomes a pathway to empowerment, inspiration, and
          wealth creation.
        </p>

        <Link to="/creators-hub">
          <div className="About__LearnBtnContainer">
            <p className="About__LearnBtn unnamed-character-style-1">
              Creators Hub
            </p>
            <img src={ArrowRightAU} alt="" className="About__LearnBtnArrow" />
          </div>
        </Link>
      </div>
    </AnimateKeyframes>
  );
}
