import "./style.css";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";
import React from 'react';
import {API_URL} from '../../../config.js';

import Loader from "../../../components/common/Loader";

import store from "../../../store/store";
import { SERVICE, STATUS } from "../../../tools/tickets";

import BackPage from "../../../assets/images/common/back-page.png";
import BugReport from "../../../assets/images/menu/bug-report.png";
import SendArrow from "../../../assets/images/common/arrow-thin.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Report(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Menu",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  const [ReportText, setReportText] = useState(null);
  const [ShowSuccesedMessage, setShowSuccesedMessage] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);

  const [ServiceSelected, setServiceSelected] = useState(null);

  function getTicketsServicesOptions() {
    let optionsElements = [
      <option value={null} disabled selected>
        Category
      </option>,
    ];

    for (let i = 0; i < Object.keys(SERVICE).length; i++) {
      let str = Object.keys(SERVICE)[i];
      optionsElements.push(
        <option value={i}>
          {str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()}
        </option>
      );
    }
    return optionsElements;
  }

  return (
    <div
      className="Report Page headline"
      style={{ height: windowInnerSize.height }}
    >
      <img
        src={BackPage}
        alt=""
        className="back-btn"
        onClick={() => {
          navigate(-1);
        }}
      />
      <p className="Report__HelpTitle">Help</p>
      <div className="Page--flex" style={{ minHeight: windowInnerSize.height }}>
        <div
          style={{
            position: "relative",
            width: "84vw",
            margin: "23vw auto 0",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "10vw",
            }}
          >
            <img src={BugReport} alt="" className="Report__BugPic" />
            <p className="Report__ReportTxt">Report A Bug</p>
          </div>

          <p className="Report__ReportExp">
            Your feedback is important to us.
            <br />
            <br />
            Write your comment below and our team of tech wizards will be on it.
          </p>
          <select
            className={
              "Report__SelectServicesFilter headline " +
              (ServiceSelected === ""||ServiceSelected === "Category" ? "input-wrong" : "")
            }
            onChange={(e) => {
              // console.log(e.target.value);
              setServiceSelected(e.target.value);
            }}
          >
            {getTicketsServicesOptions()}
          </select>
          <textarea
            name="Text1"
            cols="40"
            rows="5"
            placeholder="How can we help you"
            value={ReportText}
            className={
              "Report__Input headline " +
              (ReportText === "" ? "input-wrong" : "")
            }
            onChange={(e) => {
              setReportText(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            position: "relative",
            width: "fit-content",
            margin: "0 auto",
          }}
        >
          <p className="Report__SendBtn" onClick={handleSendBtn}>
            Send
          </p>
          <img src={SendArrow} alt="" className="Report__SendArrow" />
        </div>

        <div id={ShowLoader ? "LoaderPosSizeRAB" : "None"}>
          <Loader />
        </div>

        <div id={ShowSuccesedMessage ? "SuccesedMessageBAA" : "Hide"}>
          <p>
            <span>
              Your report bug
              <br /> has been received!
            </span>
            <br />
            <br />
            Thank you!
          </p>
          <button
            className="headline"
            onClick={() => {
              navigate(store.getState().profile ? "/exchange" : "/");
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );

  async function handleSendBtn() {
    if (
      ServiceSelected &&
      ServiceSelected !== "" &&
      ReportText &&
      ReportText !== ""
    ) {
      if (ReportText.length > 0) {
        setShowLoader(true);
        var axios = require("axios");
        var data = {
          service: ServiceSelected,
          description: ReportText,
        };

        var config = {
          method: "post",
          url: API_URL + "/api/tickets",
          headers: {
            Authorization: store.getState().tokens.accessToken,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            // console.log(JSON.stringify(response.data));
            setShowSuccesedMessage(true);
          })
          .catch(function (error) {
            // console.log(error);
          });
      }

      setShowLoader(false);
    } else {
      !ReportText && setReportText("");
      !ServiceSelected && setServiceSelected("");
    }
  }
}
