import "./style.css";
import ArrowUp from "../../../../assets/images/common/arrow-up.png";
import ArrowDown from "../../../../assets/images/common/arrow-down.png";
import React from "react";

import { useState, useEffect } from "react";

import getRatingColour from "./ringColour.js";
import CreativeCard_TotalHolding from "../components/TotalHolding.jsx";

export default function StockCreativeCard(props) {
  const [SMIColour, setSMIColour] = useState("blue");

  let ratingColourValues, ringColour, ratingNumColour, ratingNum;
  if (props.SocialMedia) {
    ratingColourValues = getRatingColour(props.SocialMedia);
    ringColour = ratingColourValues.ringColour;
    ratingNumColour = ratingColourValues.ratingNumColour;
    ratingNum = ratingColourValues.ratingNumber;
  }

  let Stock = props.stock;
  const [cardData, setcardData] = useState(null);
  useEffect(() => {
    setcardData(props.cardData);
  }, [props.cardData]);

  let isGrowth = null;
  if (props.cardData) {
    if (props.cardData?.lastChange?.amount) {
      if (props.cardData?.lastChange?.amount > 0) {
        isGrowth = true;
      } else {
        if (props.cardData?.lastChange.amount < 0) {
          isGrowth = false;
        }
      }
    }
  }
  return cardData ? (
    <div className="StockCreativeCard headline">
     {props.totalHolding&& <CreativeCard_TotalHolding totalHolding={props.totalHolding} />}
    
      <div className="StockCreativeCard__FlexContainer">
        <div style={{ position: "relative" }}>
          <div
            // id="SMIPicCreative"
            className={
              "StockCreativeCard__BrandPic " +
              (cardData ? cardData.ringColour + "RC" : null)
              //  props.SocialMedia
              // ? ringColour
              // : SMIColour
            }
            style={{
              backgroundImage: cardData
                ? `url(${cardData.profilePic})`
                : props.EditImage
                ? `url(${props.EditImage})`
                : null,
              // `url(${
              //     Stock
              //       ? Stock.SMIPicURL
              //       : "https://web.passionstocks.com/" +
              //         (props.BuyStock
              //           ? props.BuyStock.influencer.profile_photo_path
              //           : props.StockHolding
              //           ? props.StockHolding.stock.influencer
              //               .profile_photo_path
              //           : props.AgencyMembers
              //           ? props.SMI3rdP
              //             ? props.AgencyMembers.profile_photo_path
              //             : props.AgencyMembers.influencer.profile_photo_path
              //           : props.InfluencersJoinOffer
              //           ? props.InfluencersJoinOffer.profile_photo_path
              //           : props.Influencers3rdProfile.profile_photo_path)
              //   })`,
            }}
          />

          <p
            className={
              "StockCreativeCard__Rating " +
              (cardData ? cardData.ringColour + "RN" : null)
              // : props.SocialMedia
              // ? ratingNumColour
              // : "")
            }
          >
            {
              cardData ? cardData.ranking : "#"
              // props.SocialMedia ? ratingNum : 1}
            }
          </p>
        </div>

        <div style={{ width: "45vw" }}>
          <p className="StockCreativeCard__BrandName">
            {
              cardData ? cardData.name : null
              // : Stock
              // ? Stock.SMIName
              // : props.BuyStock
              // ? props.BuyStock.influencer.name
              // : props.StockHolding
              // ? props.StockHolding.stock.influencer.name
              // : props.AgencyMembers
              // ? props.SMI3rdP
              //   ? props.AgencyMembers.name
              //   : props.AgencyMembers.influencer.name
              // : props.InfluencersJoinOffer
              // ? props.InfluencersJoinOffer.name
              // : props.Influencers3rdProfile.name}
            }
          </p>
          <p className="StockCreativeCard__Price">
            {isGrowth !== null ? (
              isGrowth ? (
                <img
                  src={ArrowUp}
                  alt=""
                  className="StockCreativeCard__Arrow"
                />
              ) : (
                <img
                  src={ArrowDown}
                  alt=""
                  className="StockCreativeCard__Arrow"
                />
              )
            ) : null}
            {cardData.currency ? cardData.currency : "--"}
            {
              cardData
                ? cardData.currentPrice % 1 === 0
                  ? cardData.currentPrice
                  : cardData.currentPrice.toFixed(2)
                : null
              // Stock
              // ? Stock.CurrentPrice
              // : props.BuyStock
              // ? props.BuyStock.price
              // : props.StockHolding
              // ? props.StockHolding.stock.price
              // : props.AgencyMembers
              // ? props.SMI3rdP
              //   ? props.AgencyMembers.current_stock_price
              //   : props.AgencyMembers.influencer.current_stock_price
              // : props.InfluencersJoinOffer
              // ? props.InfluencersJoinOffer.current_stock_price
              // : props.Influencers3rdProfile.current_stock_price}
            }
            <span
              style={{
                color: "#909090",
                fontSize: "10px",
                fontWeight: "lighter",
              }}
            >
              {" "}
              /Unit
            </span>
          </p>

          <p className="StockCreativeCard__Niches">
            {
              cardData ? getNiches(cardData.niches) : null
              //  getNiche(
              //     Stock
              //       ? Stock.Niches
              //       : props.stock
              //       ? props.stock.Niches
              //       : props.BuyStock
              //       ? props.BuyStock.influencer.niches
              //       : props.StockHolding
              //       ? props.StockHolding.stock.influencer.niches
              //       : props.AgencyMembers
              //       ? props.SMI3rdP
              //         ? props.AgencyMembers.niches
              //         : props.AgencyMembers.influencer.niches
              //       : props.InfluencersJoinOffer
              //       ? props.InfluencersJoinOffer.niches
              //       : props.Influencers3rdProfile.niches
              // )
            }
          </p>
          <p className="StockCreativeCard__Location">
            {
              cardData ? cardData.city : null
              // Stock
              // ? Stock.SMILocation
              // : props.stock
              // ? props.stock.SMILocation
              // : props.BuyStock
              // ? props.BuyStock.influencer.city
              // : props.StockHolding
              // ? props.StockHolding.stock.influencer.city
              // : props.AgencyMembers
              // ? props.SMI3rdP
              //   ? props.AgencyMembers.city
              //   : props.AgencyMembers.influencer.city
              // : props.InfluencersJoinOffer
              // ? props.InfluencersJoinOffer.city
              // : props.Influencers3rdProfile.city
            }
          </p>
        </div>
      </div>
    </div>
  ) : null;
}

function getNiches(nichesArr) {
  let niches = "SMI";
  for (let i = 0; i < nichesArr.length; i++) {
    if (i === 0) {
      niches = nichesArr[i];
    } else {
      niches += "|" + nichesArr[i];
    }
  }
  return niches;
}
