import "./style.css";
import React from "react";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import store from "../../../store/store";
import { ROLES } from "../../../tools/role";

import UpArrow from "../../../assets/images/common/arrow-up.png";
import DownArrow from "../../../assets/images/common/arrow-down.png";
import YoutubeCLogo from "../../../assets/images/common/youtube-icon.png";
import TikTokCLogo from "../../../assets/images/common/tiktok-icon.png";
import InstagramBigIcon from "../../../assets/images/common/instagram-icon.png";
import fixFractionDigits from "../../../helpers/fixFractionDigits";
// import S1HP from '../../assets/images/common/S1HP.png'

export default function SaleOfferCard(props) {
  let ringColour = props.SaleOffer.stock.influencer.ringColour + "RC";
  let rankingNumColour = props.SaleOffer.stock.influencer.ringColour + "RN";
  let rankingNum = props.SaleOffer.stock.influencer.ranking;

  const navigate = useNavigate();

  const handleClickOnSale = () => {
    if (!props.MVP && props.SaleOffer.id) {
      store.dispatch({
        type: "app/saleId/add",
        payload: props.SaleOffer.id,
      });

      navigate("/exchange/sale-view", { state: { process: "BUY" } });
    }
  };

  const [isAgentIpoedStock, setisAgentIpoedStock] = useState(null);
  useEffect(() => {
    if (props.SaleOffer) {
      let temp =
        props.SaleOffer.stock.influencer.user.accountType & ROLES.AGENT;
      setisAgentIpoedStock(temp);
    }
  }, [props.SaleOffer]);

  return (
    <div className="SaleOfferCard headline">
      <div className="StockCardSOC" onClick={handleClickOnSale}>
        <div
          style={{
            background: isAgentIpoedStock ? "white" : "#1F1F1F",
            height: "100%",
            width: "100%",
            zIndex: "-1",
          }}
        />
        <div
          className={
            (!props.SaleOffer.isIpo ? "SMIPicSmallSOC" : "SMIPicBigSOC") +
            " " +
            ringColour
          }
          style={{
            backgroundImage: `url(${props.SaleOffer.stock.influencer.user.profilePic})`,
            boxShadow: isAgentIpoedStock ? "0px 3px 6px 0px #b8b8b8" : "",
          }}
        />

        {!props.SaleOffer.isIpo ? (
          <div
            className="InvPicSOC"
            style={{
              backgroundImage: `url(${props.SaleOffer.seller.profilePic})`,
            }}
          />
        ) : null}
        <p
          className={
            (props.SaleOffer.isIpo ? "SMIRankingSOC" : "SMIIPORankingSOC") +
            " " +
            rankingNumColour
          }
        >
          {rankingNum}
        </p>

        <p
          className="SMINameSOC body-copy-bold"
          style={{ color: isAgentIpoedStock ? "black" : "white" }}
        >
          {props.SaleOffer.stock.influencer.user.name}
        </p>
        <p
          className="StocksForSaleSOC body-copy"
          style={{ color: isAgentIpoedStock ? "black" : "white" }}
        >
          <span className="TextYellow">
            {
              /*props.isIPOStockView*/ props.SaleOffer.isIpo
                ? /*1*/ props.SaleOffer.volume
                : props.CreateOfferSaleData
                ? props.CreateOfferSaleData.volume
                : props.SaleOffer.volume
            }
            
          </span>{" "}
          for{" "}
          <span className="TextYellow">
            {
              /*props.CreateOfferSaleData
              ? props.CreateOfferSaleData.price
              
              :*/ props.SaleOffer.currency.symbol +
                fixFractionDigits(
                  props.SaleOffer.isIpo
                    ? props.SaleOffer.price
                    : props.SaleOffer.price * props.SaleOffer.volume
                )
            }
            <span style={{ fontWeight: "lighter", fontSize: "3vw" }}>
              {props.SaleOffer.isIpo ? " each" : ""}
            </span>
          </span>
        </p>
        {props.SaleOffer.stock.marketPrice ? (
          <p
            className="StockPreviousPriceSOC body-copy"
            style={{ color: isAgentIpoedStock ? "black" : "white" }}
          >
            {props.SaleOffer.stock.currency.symbol +
              fixFractionDigits(props.SaleOffer.stock.marketPrice)}
            /PS
            {props.SaleOffer.stock.lastChange.amount > 0 ? (
              <img src={UpArrow} alt="" className="CurrentPriceArrow" />
            ) : props.SaleOffer.stock.lastChange.amount < 0 ? (
              <img src={DownArrow} alt="" className="CurrentPriceArrow" />
            ) : null}
          </p>
        ) : null}
        {/* <p className="SMILocation body-copy"><span className="LocationBckg">{'CPT'/*props.SaleOffer.stock.influencer.location</span></p> */}
        {/* {props.SaleOffer.influencer ?
                        <p className="StockStatusSOC body-copy"><span className={props.SaleOffer.blip.text.replace(/\s+/g, '') + 'Bckg' /*==='New'? 'NEWBckg' : props.stocks[i].StockStatus==='Hot Stock' ? 'HotStockBckg' : null}>{props.SaleOffer.blip.text}</span></p>
                        :
                        null} */}

        {props.SaleOffer.stock.influencer.dominatingPlatform.platform && (
          <div className="SMHighStats">
            <img
              src={
                props.SaleOffer.stock.influencer.dominatingPlatform.platform ===
                "YouTube"
                  ? YoutubeCLogo
                  : props.SaleOffer.stock.influencer.dominatingPlatform
                      .platform === "Instagram"
                  ? InstagramBigIcon
                  : TikTokCLogo
              }
              alt=""
              className={
                props.SaleOffer.stock.influencer.dominatingPlatform.platform +
                "HIcon"
              }
            />
            <p className="SMHighFollows">
              {props.SaleOffer.stock.influencer.dominatingPlatform.reach}{" "}
            </p>
            <p className="SMHighFollowsSpace">
              {props.SaleOffer.stock.influencer.dominatingPlatform.reach}{" "}
            </p>
          </div>
        )}
        <p
          className={
            (props.SaleOffer.stock.lastChange.amount > 0
              ? "PositiveGreenNum "
              : "NegativeNum ") + "PCChangeFromLastTrnsctnSOC body-copy"
          }
        >
          {(props.SaleOffer.stock.lastChange.amount > 0
            ? "+"
            : props.SaleOffer.stock.lastChange.amount < 0
            ? "-"
            : "") +
            props.SaleOffer.stock.currency.symbol +
            (props.SaleOffer.stock.lastChange.amount
              ? Math.abs(props.SaleOffer.stock.lastChange.amount)
              : 0)}
        </p>
        <p
          className={
            (props.SaleOffer.stock.lastChange.amount > 0
              ? "PositiveGreenNum "
              : "NegativeNum ") +
            "StockPercentChangeFromLastTrnsctnSOC body-copy"
          }
        >
          {(props.SaleOffer.stock.lastChange.amount > 0
            ? "+"
            : props.SaleOffer.stock.lastChange.amount < 0
            ? "-"
            : "") +
            (props.SaleOffer.stock.lastChange.percent
              ? Math.abs(props.SaleOffer.stock.lastChange.percent).toFixed(2)
              : 0) +
            "%"}
        </p>
      </div>
    </div>
  );
}
