import "./style.css";
import { AnimateKeyframes } from "react-simple-animate";

import BackPage from "../../../../../../assets/images/common/back-page.png";
import DeleteIcon from "../../../../../../assets/images/menu/delete.png";

import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import BulletProcess from "../../../../../../components/common/BulletProcess";

export default function DeleteAccount_IssuerGiveaway() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state !== "delete-account") {
      navigate("/menu");
    }
  }, [location]);

  const handleConfirmBtn = () => {
    navigate("/delete-account/we-are-sorry", {
      state: "delete-account",
    });
  };

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="DeleteAccount Page headline">
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            position: "relative",
            marginTop: "20vw",
          }}
        >
          <img
            src={BackPage}
            alt=""
            // id=""
            className="back-btn"
            style={{
              position: "relative",
              top: 0,
              left: 0,
              marginRight: "12px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <h1
            className="headline__menu"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete account"
              // className="Menu_AccountAndSetting__Icon"
            />
            Delete Account
          </h1>
        </div>

        <p
          style={{ fontSize: "16px", lineHeight: "22px", textAlign: "center" }}
        >
          If you have made any commitments regarding a revenue sharing
          agreement, it is important to fulfill them.
          <br />
          <br />
          However, please note that revenue sharing cannot occur directly on the
          platform at the moment.
          <br />
          <br />
          Therefore, it will be necessary for you to stay in touch with the
          relevant parties to arrange and manage the revenue sharing outside of
          the platform.
        </p>

        <button className="DeleteAccount__Btn" onClick={handleConfirmBtn}>
          Confirm
        </button>

        <BulletProcess amount={3} checkedIndex={1}/>
      </div>
    </AnimateKeyframes>
  );
}
