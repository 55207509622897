import './style.css';

import store from '../../../store/store';
import { useState ,useEffect} from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import BackPage from '../../../assets/images/common/back-page.png';

import NotificationNav from './components/notificationNav';
import UpdateForm from './components/UpdateForm';
import EducationForm from './components/EducationForm';
import { navOptions } from './components/notificationsConfig';

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function NotificationForm(props) {
  store.dispatch({
    type: 'ui/navBarsDM/toggle',
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: 'ui/navBarsSelectedSection/update',
    payload: "Notification",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);


  // store.dispatch({ type: 'appConfig/edit_image_false', payload: null });

  const navigate = useNavigate();

  const [ActionForm, setActionForm] = useState(props.displayCategory);


  //Animation:
  const [animateSlide, setanimateSlide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setanimateSlide(true);
    }, 0);
    return () => {
      setanimateSlide(false);
    };
  }, []);
  return (
    <div id='NotificationDiv' className={'Page headline '+ (!animateSlide ? "animate-to-left" : null)}
    style={{height: windowInnerSize.height}}>
      <img src={BackPage} alt='' id='BackPageNot' onClick={() => navigate(-1)} />

      <NotificationNav ActionForm={ActionForm} setForm={setForm} />

      {ActionForm === navOptions.updates && <UpdateForm hasUser={!!store.getState().profile} />}
      {ActionForm === navOptions.education && <EducationForm />}
    </div>
  );

  function setForm(selectedNavOptions) {
    if (ActionForm !== selectedNavOptions) setActionForm(selectedNavOptions);
  }
}
