import React from "react";
/*import {API_URL} from '@src/config.js';*/
import "./style.css";

import CheckBtn from "./components/CheckBtn";

import InstagramIcon from "../../../assets/images/common/instagram-white-icon.png";
import Share from "../../../assets/images/common/share.png";

export default function UpdateDeliveredStoryMention(props) {
  return props.deliveryList ? (
    <div className="UpdateDeliveredStoryMention headline">
      <div style={{ display: "flex", margin: "2px" }}>
        <img src={InstagramIcon} style={{ width: "20px", height: "20px" }} />
        <p style={{ fontSize: "13px",lineHeight:'12px', fontWeight: "lighter", margin: "0" }}>
{props.deliveryList.length}</p>
      </div>
      {GetFollowersList()}
      <div
        style={{
          display: "flex",
          margin: "5px",
          justifyContent: "space-between",alignItems:'baseline'
        }}
      >
        <p style={{ fontSize: "11px",lineHeight:'12px', fontWeight: "lighter", margin: "0" }}>
          Load more
        </p>
        <img
          src={Share}
          alt="Share"
          style={{ width: "36px", height: "30px" }}
        />
      </div>
    </div>
  ) : null;

  function GetFollowersList() {
    let FollowersListElements = [];
    for (let i = 0; i < props.deliveryList.length; i++) {
      FollowersListElements.push(
        <li key={i}>
          <p>{props.deliveryList[i].handle}</p>
          <spam id="CheckBtnPos">
            <CheckBtn deliveryItem={props.deliveryList[i]} />
          </spam>
        </li>
      );
    }

    return <ul>{FollowersListElements}</ul>;
  }
}
