import "./style.css";
import { useState } from "react";
import { useEffect } from "react";
import React from "react";
// import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";

import store from "../../../store/store";

// import FacebookLogoSign from "../../images/icons/facebookSign.png";

import LogIn from "./components/LogIn";
import SignUp from "./components/SignUpContainer";
import HeaderWithLogo from "../../../components/common/HeaderWithLogo";
import AuthNav from "./components/AuthNav";
import SignUpContainer from "./components/SignUpContainer";
import SignUpDemoForm from "./components/SignUpDemoForm";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectProfile = (state) => state.profile;

export default function Form(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  // prevent enter to the page by insert the url in undesirable cases
  const profile = useSelector(selectProfile);
  const [RenderPage, setRenderPage] = useState(null);
  useEffect(() => {
    if (profile) {
      navigate("/profile");
    } else {
      setRenderPage(true);
    }
  }, [profile]);

  const [ActionForm, setActionForm] = useState(
    props.action === "sign-up" ? "sign-up" : "log-in"
  );

  // Animation
  // animate when opening Form:
  const [animateSlide, setanimateSlide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setanimateSlide(true);
    }, 0);
  }, []);

  // animate LogIn/SignUp compnents.
  const [animateLogInForm, setanimateLogInForm] = useState(true);
  const [animateSignUpForm, setanimateSignUpForm] = useState(false);
  useEffect(() => {
    if (ActionForm === "sign-up") {
      setanimateLogInForm(false);
      setTimeout(() => {
        setanimateSignUpForm(true);
      }, 0);
    }
    if (ActionForm === "log-in") {
      setanimateSignUpForm(false);
      setTimeout(() => {
        setanimateLogInForm(true);
      }, 0);
    }
  }, [ActionForm]);

  // used to prevent animate Login and Signup components when opening the page.
  const [clickAuthNav, setclickAuthNav] = useState(false);
  useEffect(() => {
    return () => {
      setclickAuthNav(false);
    };
  }, []);

  // // useEffect(() => {
  // if (!store.getState().windowInnerSize) {
  //   store.dispatch({
  //     type: "ui/windowInnerSize/add",
  //     payload: { height: window.innerHeight, width: window.innerWidth },
  //   });
  // }
  // // }, []);

  // function handleGoogleResponse(response) {
  //   var axios = require("axios");
  //   var data = JSON.stringify({
  //     accessToken: response.credential,
  //     clientId: response.clientId,
  //   });

  //   var config = {
  //     method: "post",
  //     url: API_URL + "/auth/google-login",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       // console.log(JSON.stringify(response.data));
  //       store.dispatch({
  //         type: "domain/user/addData",
  //         payload: response.data.user,
  //       });

  //       if (response.data.user.accountType < 4) {
  //         store.dispatch({
  //           type: "domain/tokens/addRefreshToken",
  //           payload: response.data.refreshToken,
  //         });
  //         store.dispatch({
  //           type: "domain/signUpToken/addToken",
  //           payload: response.data.accessToken,
  //         });
  //         navigate("/auth/sign-up/confirmation/t" + `&` + "cs");
  //       } else {
  //         store.dispatch({
  //           type: "domain/tokens/addTokens",
  //           payload: response.data,
  //         });
  //         navigate("/profile", { replace: true });
  //       }
  //     })
  //     .catch(function (error) {
  //       // console.log(error);
  //     });
  // }
  // useEffect(() => {
  //   /* global google*/
  //   google.accounts.id.initialize({
  //     client_id:
  //       "60708295640-ojtlj1rfi2juh81521vttbbqt0ruq45s.apps.googleusercontent.com",
  //     callback: handleGoogleResponse,
  //   });

  //   google.accounts.id.renderButton(document.getElementById("GoogleSignIn"), {
  //     theme: "outline",
  //     size: "large",
  //     // width:store.getState().windowInnerSize.width/100*90,
  //     // type: "icon",
  //     select_by: "btn",
  //     width: (store.getState().windowInnerSize.width / 100) * 90,
  //     shape: "circle",
  //   });
  // }, []);

  return (
    RenderPage && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.65}
        direction="normal"
        keyframes={[
          "transform: translateY(100vh)",
          "transform: translateY(50vh)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          className={
            "AuthPage Page " + (!animateSlide ? "animate-slide-to-top" : null)
          }
          style={{ /*height: windowInnerSize.height,*/ overflow: "scroll" }}
        >
          <HeaderWithLogo />

          <SignUpDemoForm animateSignUpForm={animateSignUpForm}/>

          {/* <AuthNav
            ActionForm={ActionForm}
            setAsLogIn={setAsLogIn}
            setAsSignUp={setAsSignUp}
          />
          {ActionForm === "log-in" ? (
            <LogIn
              animateLogInForm={animateLogInForm}
              clickAuthNav={clickAuthNav}
            />
          ) : (
            <SignUpContainer animateSignUpForm={animateSignUpForm} clickAuthNav={clickAuthNav}/>
          )} */}

          {/* <div id="GoogleSignIn" style={{ display: "inline" }}></div> */}
          {/* </div> */}
        </div>
      </AnimateKeyframes>
    )
  );
  function setAsLogIn() {
    if (ActionForm !== "log-in") {
      setActionForm("log-in");
      navigate("/auth/log-in", { replace: true });
    }
    setclickAuthNav(true);
  }
  function setAsSignUp() {
    if (ActionForm !== "sign-up") {
      setActionForm("sign-up");
      navigate("/auth/sign-up/username", { replace: true });
    }
    setclickAuthNav(true);
  }
}
