import "./style.css";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/export default function Tabs(props) {
  return (
    <div className="Tabs">
      <div className="Tabs__Btns">
        <p className="Tabs__Btn" onClick={() => props.setState(props.tab1)}>
          {props.tab1}
        </p>
        <p className="Tabs__Btn" onClick={() => props.setState(props.tab2)}>
          {props.tab2}
        </p>
      </div>
      <svg height="0.25vw" width="90vw" className="Tabs__BasicLine">
        <line
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          stroke="#ffbf12"
          stroke-width="10%"
          stroke-linecap="butt"
        />
      </svg>
      <svg
        height="0.65vw"
        width="45vw"
        className={
          props.state === props.tab1
            ? "Tabs__Tab1SwitchLine"
            : "Tabs__Tab2SwitchLine"
        }
      >
        <line
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          stroke="#ffbf12"
          stroke-width="10%"
          stroke-linecap="butt"
        />
      </svg>
    </div>
  );
}
