import '../style.css';

import React from 'react';
/*import {API_URL} from '@src/config.js';*/
import { Link } from 'react-router-dom';
import NotificationComp from './notificationComp';
import SimleyUDemo from '../../../../assets/images/common/smiley-1.png';

export default function UpdateForm({ hasUser }) {
  return (
    <div id='UpdatesForm' className='headline'>
      {!hasUser ? (
        <div id='DemoUpdates'>
          <img src={SimleyUDemo} alt='' id='SimleyUDemo' />
          <p id='UpdatesDemoTxt'>
            Sign up, <br />
            {'&'} I’ll spill the beans.
          </p>

          <Link to='/auth/sign-up'>
            <p id='SignUpUDemoBtn'>Sign Up</p>
          </Link>
          <Link to='/auth/log-in'>
            <p id='SignInUDemoBtn'>Sign In</p>
          </Link>
        </div>
      ) : (
        <div id='UpdatesLogIn'>
          <NotificationComp />
        </div>
      )}
    </div>
  );
}
