import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {API_URL} from '../../../config.js';

import "./style.css";
import LongLogo from "../../../assets/images/common/ps-logo-one-row.png";
import Menu from "../../../assets/images/common/menu-icon-gray.png";
import MenuYellow from "../../../assets/images/common/menu-icon-yellow.png";
import NotificationPS from "../../../assets/images/common/notification-gray.png";
import NotificationYellow from "../../../assets/images/common/notification-yellow.png";
import axios from "axios";
import store from "../../../store/store";

const selectCurrentLink = (state) => state.navBarsSelectedSection;
const selectNavBarTop = (state) => state.navBarsDM.top;
const selectProfile = (state) => state.profile;

export default function TopNav() {
  const isDisplay = useSelector(selectNavBarTop);

  const profile = useSelector(selectProfile);

  const whichLinked = useSelector(selectCurrentLink);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const getNotificationCount = async () => {
      const token = store?.getState().tokens.accessToken;
      const axiosConfig = { headers: { authorization: token } };

      const result = await axios.get(
        `${API_URL}/api/notifications/count`,
        axiosConfig
      );

      setNotificationCount(result.data.notificationCount);
    };

    getNotificationCount();
  }, []);

  const setAsRead = () => setNotificationCount(0);

  return (
    isDisplay && (
      <div className="ShowTopMenu">
        <Link to={profile ? "/profile" : "/"}>
          <img alt="logo" src={LongLogo} id="TopLogo"></img>
        </Link>
        <Link to="/menu">
          <img
            alt="menu"
            src={whichLinked === "Menu" ? MenuYellow : Menu}
            id="Menu"
          />
        </Link>
        <Link
          to={notificationCount > 0 ? "/notification" : "/education"}
          onClick={setAsRead}
        >
          <img
            alt="notification"
            src={
              whichLinked === "Notification"
                ? NotificationYellow
                : NotificationPS
            }
            id="NotificationPS"
          />
          {notificationCount > 0 && (
            <span className="count headline">{notificationCount}</span>
          )}
        </Link>
      </div>
    )
  );
}
