import "./style.css";
import store from "../../../../store/store";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import BackPage from "../../../../assets/images/common/back-page.png";
import Done from "../../../../assets/images/common/confirm.png";

import { Link } from "react-router-dom";
export default function RequestReceived() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  return (
    <div className="WithdrawRequestReceived Page headline">
      <Link to="/wallet">
        <img
          src={BackPage}
          alt=""
          className="WithdrawRequestReceived__BackBtn"
        />
      </Link>
      <div className="Page--flex">
        <div style={{paddingTop:"15vw"}}>
          <p className="WithdrawRequestReceived__RequestReceivedTxt unnamed-character-style-1">
            WITHDRAWAL <br /> REQUEST <br /> SUCCESSFULLY
            <br /> RECEIVED
          </p>
          <p className="WithdrawRequestReceived__TheFundsTxt">
            The funds will reflect <br /> within 3 business days
          </p>
          <svg
            height="2vw"
            width="33%"
            className="WithdrawRequestReceived__Line"
          >
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="white"
              stroke-width="100%"
              stroke-linecap="butt"
            />
          </svg>
        </div>
        <Link to="/wallet">
          <img src={Done} alt="" className="WithdrawRequestReceived__Done" />
        </Link>
      </div>
    </div>
  );
}
