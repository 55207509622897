import React, { useState } from "react";
import axios from 'axios';
import {API_URL} from '../config.js';

import store from '../store/store';
import { persistor } from '../store/store';


export default function loadProfile(tokens) {
    let accountType;

    // console.log(process.env);
    axios({
        method: 'GET',
        url: `${API_URL}/api/users/profile`,
        headers: {
            Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        },

    }).then(async function (response) {
        await store.dispatch({ type: 'domain/profile/addData', payload: response.data })
        // await persistor.purge();
        // console.log(JSON.stringify(response.data));

        accountType = response.data.accountType;
        // if ((response.data.accountType === 14) || (response.data.accountType === 30)){
        //     var configIPO = {
        //         method: 'get',
        //         url: API_URL + '/api/influencers/shares-to-release',
        //         headers: {
        //             Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        //         }
        //     };
        //     await axios(configIPO)
        //         .then(async function (res) {
        //             // console.log(JSON.stringify(res.data));
        //                 await store.dispatch({ type: 'wallet/IPOrelease/loadData', payload: res.data })
        //             await persistor.purge();

        //         })
        //         .catch(function (error) {
        //             // console.log(error);
        //         });
        // }

    }).catch(async function (error) {

    });

    // load PPT pending list:
    // axios({
    //     method: 'GET',
    //     url: API_URL + '/api/ppt-trades/trader',
    //     headers: {
    //         Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
    //     },

    // }).then(async function (response) {
    //     store.dispatch({ type: 'domain/pptPendingList/add', payload: response.data })
    //     // await persistor.purge();
    //     // console.log(JSON.stringify(response.data));
    // }).catch(async function (error) {

    // });

    // load provider delivery list:
    // // console.log(accountType);
    // if ((accountType === 14) || (accountType === 30)) {
    //     // var axios = require('axios');

    // var config = {
    //     method: 'get',
    //     url: API_URL + '/api/ppt-trades/provider',
    //     headers: {
    //         Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
    //     },
    // };

    // axios(config)
    //     .then( function (response) {
    //         // console.log(JSON.stringify(response.data));
    //          store.dispatch({ type: 'domain/pptDeliveryList/add', payload: response.data })
    //         // await persistor.purge();
    //     })
    //     .catch(function (error) {
    //         // console.log(error);
    //     });

    // // }

}