import "./style.css";
import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import psIconAbove from "../../assets/images/common/ps-icon-above.png";

import CreatorsHubBtn from "./components/CreatorsHubBtn";
import MenuBtn from "./components/MenuBtn";
import { Link } from "react-router-dom";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Intro(props) {
  const windowInnerSize = useSelector(selectWindowInnerSize);

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <CreatorsHubBtn />
      <MenuBtn />
      <div
        className="Page headline"
        style={{
          minHeight: "97vh"/*windowInnerSize.height*/,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p
            className="glow-yellow-white unnamed-character-style-1"
            style={{ fontSize: "28px", lineHeight: "30px", margin: "88px 0 0" }}
          >
            Personal brand
            <br />
            Management
          </p>
          <p
            className="unnamed-character-style-1"
            style={{ fontSize: "13px", lineHeight: "22px", margin: "0" }}
          >
            {" "}
            For stock exchange listing
          </p>
        </div>

        <div>
          {/* <p
            className="unnamed-character-style-1"
            style={{ fontSize: "40px", color: "#ffbf12",margin:'0' }}
          >
            SIGN UP
          </p>
          <p
            className="unnamed-character-style-1"
            style={{ fontSize: "17px", color: "#707070",margin:0 }}
          >
            LOG IN
          </p> */}

          <Link to="/auth/sign-in">
            <p
              className="unnamed-character-style-1"
              style={{ fontSize: "40px", color: "#ffbf12", margin: "0" }}
            >
              SIGN IN
            </p>
          </Link>
        </div>

        <div>
          <img
            src={psIconAbove}
            alt="PassionStocks logo"
            style={{ display: "block", margin: "0 auto", width: "226px" }}
          />
          <p
            style={{
              color: "#707070",
              fontSize: "13px",
              lineHeight: "10px",
              margin: " 10px 0 25px",
            }}
          >
            Ts & Cs Apply
          </p>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
