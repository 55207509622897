import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AnimateKeyframes } from "react-simple-animate";

import axios from "axios";

import Bull from "../../../assets/images/common/going-up.png";
import Bear from "../../../assets/images/common/going-down.png";

// import Avatar_image from "../../Menu/Avatar_image";
// import StockCreativeCard from "../../StockCreativeCard";
// import AgencyMemberTag from "../../Profile/SMI/AgencyMemberTag";
import GraphUserData from "../../../components/common/graphs/GraphUserData";
import StockResponses from "../../../components/common/StockEngagement";
// import UpdateDeliveredStoryMention from "../../common/UpdateDeliveredStoryMention";
import SocialMediaValues from "../../../components/common/SocialMediaValues";
import AgencyCreativeCard from "../../../components/common/creative-cards/Agent";
import AgencyMembersSlider from "../../../pages/profile/profile/agentIPOed/components/MembersSlider";
import SharePopUp from "../../../components/common/SharePopUp";

import "./style.css";
import store from "../../../store/store";

import isVarNegative from "../../../helpers/isVarNegative";

// import EditIcon from "../../../images/icons/EditIcon.png";
import FullStar from "../../../assets/images/common/star-full.png";
import EmptyStar from "../../../assets/images/common/star-empty.png";
import AgentProfileBckgImage from "../../../assets/images/profile/cover-img-default.jpg";
import SharingBtn from "../../../assets/images/common/share.png";
import InstagramBigIcon from "../../../assets/images/common/instagram-icon.png";
import fixFractionDigits from "../../../helpers/fixFractionDigits";
import StoryMentionProduct from "../../../components/common/StoryMentionProduct";

// const selectProfile = (state) => state.profile;
const selectWindowInnerSize = (state) => state.windowInnerSize;
const select3rdProfile = (state) => state.influencer3rdProfile;
const selectProfile = (state) => state.profile;

export default function Profile3rd(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: true },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  let { influencerId } = useParams();

  const profile = useSelector(selectProfile);
  const Agency3rdProfile = useSelector(select3rdProfile);

  const [StatsOrPrdct, setStatsOrPrdct] = useState("Stats");
  const [StatsAgencyOrClients, setStatsAgencyOrClients] = useState("Agency");

  const [messageTxt, setmessageTxt] = useState(false);

  const [SharePopUpDisplay, setSharePopUpDisplay] = useState(false);

  function sendJoinRequest() {}
  const handleJoinBtn = () => {
    if (isVarNegative(profile.influencer.agent)) {
      setmessageTxt(
        <>
          In order to send a joining request, you must first remove your
          membership in the agency you are associated with.
          <br />
          <br />
          <Link to="/my-agency/details">
            <span>My Agency</span>
          </Link>
        </>
      );
    } else {
      if (isVarNegative(profile.influencer)) {
        sendJoinRequest();
        setmessageTxt(<>Your request has been successfully sent</>);
      } else {
        setmessageTxt(
          <>
            {" "}
            In the first step, you are welcome to add an influencer profile to
            your account,
            <br /> before you can continue
            <br />
            <br />
            <Link to="/money-calculator">
              <span>Join to exchange</span>
            </Link>
          </>
        );
      }
    }
  };

  const [cardData, setcardData] = useState(null);
  useEffect(() => {
    if (Agency3rdProfile) {
      let tempCardData = {
        ringColour: Agency3rdProfile.ringColour,
        profilePic: Agency3rdProfile.user.profilePic,
        ranking: Agency3rdProfile.ranking,
        name: Agency3rdProfile.user.name,
        currentPrice: Agency3rdProfile.stock.marketPrice,
        lastChange: Agency3rdProfile.stock.lastChange,
        currency: Agency3rdProfile.stock.currency.symbol,
        city: Agency3rdProfile.user.city,
      };
      setcardData(tempCardData);
    }
  }, [Agency3rdProfile]);

  return (
    profile &&
    Agency3rdProfile &&
    cardData && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(100vw)",
          "transform: translateX(50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          className="AgentIPOed3rdProfile Page headline"
          style={{ height: windowInnerSize.height }}
        >
          <img
            src={AgentProfileBckgImage}
            alt=""
            className="AgentIPOed3rdProfile__Background"
          />

          {/* <p id="StockPriceChangesTxt">{"+0.0032"}<br/>{"+0.02%"}</p> */}
          <div className="AgentIPOed3rdProfile__CreativeCardPosition">
            <AgencyCreativeCard cardData={cardData} />
          </div>
          <div className="AgentIPOed3rdProfile__ResponsesPos">
            <StockResponses engagement={Agency3rdProfile.engagement} />
          </div>
          <div className="AgentIPOed3rdProfile__InteractBtnsContainer">
            <Link to={"/exchange/" + Agency3rdProfile.user.username}>
              <p className="AgentIPOed3rdProfile__InvestBtn">Invest</p>
            </Link>
            <p
              className="AgentIPOed3rdProfile__JoinBtn"
              onClick={handleJoinBtn}
            >
              Join
            </p>
            <img
              src={SharingBtn}
              alt=""
              className="AgentIPOed3rdProfile__SharingBtn"
              onClick={() => {
                if (!SharePopUpDisplay) {
                  setSharePopUpDisplay(true);
                }
              }}
            />
          </div>
          {SharePopUpDisplay && (
            <div
              className="AgentIPOed3rdProfile__BackFromShare"
              onClick={() => {
                if (SharePopUpDisplay) {
                  setSharePopUpDisplay(false);
                }
              }}
            />
          )}
          {SharePopUpDisplay && (
            <div className="AgentIPOed3rdProfile__SharePUS">
              <SharePopUp
                Agencyid={influencerId}
                Agencyname={""}
                brandUsername={Agency3rdProfile.user.username}
                sharingType={0x2}
              />
            </div>
          )}
          <div className="AgentIPOed3rdProfile__StarsContainer">
            {getRateStars(
              Agency3rdProfile.user.stars ? Agency3rdProfile.user.stars : 1
            )}
          </div>

          <p className="AgentIPOed3rdProfile__Metrics headline">
            Growth{" "}
            <span>{fixFractionDigits(Agency3rdProfile.stock.growth)}%</span>
            ROI made for investors{" "}
            <span>{fixFractionDigits(Agency3rdProfile.ROI) + "% "}</span>
            <img
              src={
                Agency3rdProfile.ROI > 0
                  ? Bull
                  : Agency3rdProfile.ROI < 0
                  ? Bear
                  : null
              }
              alt=""
              id="TradeVolumePic"
            />{" "}
            <br />
            Trade Frequency <span>{Agency3rdProfile.tradeFrequency}</span>
            Highest price{" "}
            <span>
              {Agency3rdProfile.stock.currency.symbol +
                fixFractionDigits(Agency3rdProfile.stock.highestPrice)}
            </span>{" "}
            <br />
            Trade Volume <span>{Agency3rdProfile.tradeVolume}</span>{" "}
            <img
              src={Agency3rdProfile.tradeVolume > 0 ? Bull : null}
              alt=""
              id="TradeVolumePic"
            />{" "}
            {"  "}
            Stock views <span>{Agency3rdProfile.engagement.stockViews}</span>
          </p>

          <p className="AgentIPOed3rdProfile__Describe">
            {Agency3rdProfile.user.bio}
          </p>

          <p className="AgentIPOed3rdProfile__RemainingStocks">
            Remaining Passionstocks
            <span>
              {" "}
              /{" "}
              {Agency3rdProfile.release.onSale
                ? Agency3rdProfile.release.onSale
                : "#"}
            </span>
          </p>

          <div className="AgentIPOed3rdProfile__SocialMPos">
            <SocialMediaValues
              SocialMedia={
                Agency3rdProfile ? Agency3rdProfile.socialMedia : null
              }
            />
          </div>

          <div className="AgentIPOed3rdProfile__StatsPrdctContainer">
            <div className="AgentIPOed3rdProfile__PrdctStatsBtns">
              <p
                className="AgentIPOed3rdProfile__StatsBtn"
                onClick={setAsStats}
              >
                Stats
              </p>
              <p
                className="AgentIPOed3rdProfile__ProductsBtn"
                onClick={setAsProducts}
              >
                Passion Products & Projects
              </p>
            </div>
            <svg
              height="0.5vw"
              width="84vw"
              className="AgentIPOed3rdProfile__BasicLine"
            >
              <line
                x1="0"
                x2="100%"
                y1="0"
                y2="0"
                stroke="#ffbf12"
                stroke-width="10%"
                stroke-linecap="butt"
              />
            </svg>
            <svg
              height="0.85vw"
              width={StatsOrPrdct === "Stats" ? "22vw" : "40vw"}
              className={
                StatsOrPrdct === "Stats"
                  ? "AgentIPOed3rdProfile__StatsSwitchLine"
                  : "AgentIPOed3rdProfile__PrdctSwitchLine"
              }
            >
              <line
                x1="0"
                x2="100%"
                y1="0"
                y2="0"
                stroke="#ffbf12"
                stroke-width="10%"
                stroke-linecap="butt"
              />
            </svg>
            {StatsOrPrdct === "Stats" && (
              <div className={"AgentIPOed3rdProfile__StockInformation"}>
                <select
                  className="AgentIPOed3rdProfile__SelectStatsFilter headline"
                  onChange={(e) => {
                    setStatsAgencyOrClients(e.target.value);
                  }}
                >
                  <option value={"AgencyStock"} selected>
                    Agency stock
                  </option>
                  <option value={"TotalMembers"}>Total members</option>
                  <option value={"Members"}>Members</option>
                </select>
                {/* <p
                  id="AgencyStatsBtnA3P"
                  onClick={setAgencyClickedBtn}
                  className={
                    StatsAgencyOrClients === "Agency"
                      ? "P3BckgBtnWhiteAMA3P"
                      : null
                  }
                >
                  Agency
                </p>
                <p
                  id="P3MembersStatsBtnA3P"
                  onClick={setMembersClickedBtn}
                  className={
                    StatsAgencyOrClients !== "Agency"
                      ? "P3BckgBtnWhiteAMA3P"
                      : null
                  }
                >
                  Members (
                  {props.Agency3rdProfileData1
                    ? props.Agency3rdProfileData1.agency.influencers.length
                    : 0}
                  )
                </p> */}
                {StatsAgencyOrClients !== "Members" && (
                  <div style={{ width: "100%" }}>
                    <GraphUserData SMI3rdP={true} />
                    <div className="AgentIPOed3rdProfile__FlexContainer">
                      <div className="AgentIPOed3rdProfile__SquareData">
                        <p>{"--"}</p>
                        <p>Days</p>
                      </div>
                      <div className="AgentIPOed3rdProfile__SquareData">
                        <p>
                          {Agency3rdProfile.stock.currency.symbol}
                          {"--"}
                        </p>
                        <p>Made this week</p>
                      </div>
                      <div className="AgentIPOed3rdProfile__SquareData">
                        <p>{"--"}%</p>
                        <p>Growth Rate</p>
                      </div>
                    </div>
                  </div>
                )}
                {StatsAgencyOrClients === "Members" && (
                  <div>
                    {Agency3rdProfile ? (
                      Agency3rdProfile.agency?.influencers ? (
                        Agency3rdProfile.agency.influencers.length > 0 ? (
                          <AgencyMembersSlider
                            agencyMembersList={
                              Agency3rdProfile.agency.influencers
                            }
                            SMI3rdP={true}
                          />
                        ) : null
                      ) : null
                    ) : null}
                  </div>
                )}
              </div>
            )}

            {StatsOrPrdct !== "Stats" && (
              // <div className={"AgencyOPProductsInformationDivA3P"}>

              <StoryMentionProduct />
            )}
          </div>

          {messageTxt && (
            <div className="AgentIPOed3rdProfile__Message">
              <p id="messageTxt">{messageTxt}</p>
            </div>
          )}
          {messageTxt && (
            <div
              className="AgentIPOed3rdProfile__BackFromShare"
              onClick={() => {
                if (messageTxt) {
                  setmessageTxt(false);
                }
              }}
            />
          )}
        </div>
      </AnimateKeyframes>
    )
  );
  function setAsStats() {
    if (StatsOrPrdct !== "Stats") {
      setStatsOrPrdct("Stats");
    }
  }
  function setAsProducts() {
    if (StatsOrPrdct !== "Products") {
      setStatsOrPrdct("Products");
    }
  }
  function setAgencyClickedBtn() {
    if (StatsAgencyOrClients !== "Agency") {
      setStatsAgencyOrClients("Agency");
    }
  }
  function setMembersClickedBtn() {
    if (StatsAgencyOrClients === "Agency") {
      setStatsAgencyOrClients("Members");
    }
  }
}
function getRateStars() {
  let FullStars = 4;
  let EmptyStars = 1;
  let StarsCounter = 5;
  let StarsLine = [];

  for (let i = 0; i < FullStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={FullStar}
        alt=""
        id="FullStar"
      />
    );
  }
  for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={EmptyStar}
        alt=""
        id="FullStar"
      />
    );
  }

  return StarsLine;
}
function getCurrentPrice(SMIId, Token) {
  let stockdata = async () => {
    await axios({
      method: "GET",
      url:
        `https://web.passionstocks.com/api/influencers/` +
        SMIId +
        "/stocks?=api_token=" +
        Token,
    })
      .then(function (response) {
        // console.log(response);
        let StockData = response.data;
        return StockData[0].current_price;
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
}
