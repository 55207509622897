import './style.css';
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import LongLogo from "../../../assets/images/common/ps-logo-one-row.png";

export default function HeaderWithLogo() {
  return (
    <div className="HeaderWithLogo">
      <img src={LongLogo} alt="" className="HeaderWithLogo__Logo" />
    </div>
  );
}
