import React from "react";

import "./style.css";
import FullStar from "../../../../assets/images/common/star-full.png";
import EmptyStar from "../../../../assets/images/common/star-empty.png";

export default function AgentNotIPOedCreativeCard(props) {
  return (
    <div className="AgentNotIPOedCreativeCard headline">
      <div className="AgentNotIPOedCreativeCard__Background" />

      <div
        className="AgentNotIPOedCreativeCard__Avatar"
        style={{
          backgroundImage: `url(${
            props.cardData ? props.cardData.profilePic : ""
          })`,
        }}
      />

      <div className="AgentNotIPOedCreativeCard__DataContainer">
        <div className="AgentNotIPOedCreativeCard__StarsContainer">
          {getRateStars(
            props.cardData.stars || props.cardData.stars === 0
              ? props.cardData.stars
              : 1
          )}
        </div>
        <p
          className={
            props.cardData
              ? props.cardData.name.length <= 13
                ? "AgentNotIPOedCreativeCard__Name"
                : "AgentNotIPOedCreativeCard__NameLong"
              : null
          }
        >
          {props.cardData ? props.cardData.name : ""}
        </p>

        <p className="AgentNotIPOedCreativeCard__AgencyTxt">Agency</p>
        {/* <p className="AgentNotIPOedCreativeCard__Location">
          {props.cardData
            ? props.cardData.city
            : props.agencyUserProfile
            ? props.agencyUserProfile.city
            : props.AgencyExchangeSearch
            ? ""
            : ""}
        </p> */}
      </div>
    </div>
  );
}
function getRateStars(Rating) {
  let FullStars = Rating;
  let EmptyStars = 5 - Rating;
  let StarsCounter = 5;
  let StarsLine = [];

  for (let i = 0; i < FullStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={FullStar}
        alt=""
        id="FullStar"
      />
    );
  }
  for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={EmptyStar}
        alt=""
        id="FullStar"
      />
    );
  }

  return StarsLine;
}
