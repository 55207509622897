import './style.css';

import axios from 'axios';
import React from 'react';
import { API_URL } from '../../../config';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import hourglass from '../../../assets/images/common/hourglass.png';

const selectTokens = (state) => state.tokens;

export default function SalesLine(props) {

    const tokens = useSelector(selectTokens);

    const handleRemoveSale = async (saleId) => {
        await axios({
            method: 'DELETE',
            url: `${API_URL}/api/sales/` + saleId,
            headers: {
                Authorization: tokens.accessToken
            }
        }).then(function (response) {
            // // console.log('delete offer sale' + saleId);
            // loadWallet(tokens, store.getState().profile.data.accountType);
            // loadMySales(tokens);
            props.loadStockH();
        }).catch(function (error) {
            // // console.log('error to delete offer sale' + saleId);

        });
    }

    const [sales, setsales] = useState(null);
    useEffect(() => {
        setsales(props.unit.saleOffers);
    }, [props.saleOffers])

    return (
        sales &&<div>{
        sales.map(
            (ForSaleItem, i) => {
                let date = new Date();
                let createdAt = ForSaleItem.createdAt ? new Date(ForSaleItem.createdAt) : date
                let diff = createdAt.getTime() + 24 * 60 * 60 * 1000 - date.getTime();
                let diff_as_date = new Date(diff);
                let hours = diff_as_date.getHours(); // hours
                let minutes = diff_as_date.getMinutes(); // minutes
                minutes = minutes < 10 ? "0" + minutes : minutes;
                let temp =
                    (
                        <div className="SaleLine" >
                            <p style={{ fontSize: '15px', paddingLeft: '4px' }}>{i + 1}</p>
                            <p style={{ fontSize: '19px', fontWeight: 'bolder' }}><img src={hourglass} style={{ width: '9px', paddingRight: '2px' }} />{hours}:{minutes}</p>
                            <p style={{ fontSize: "10px", display: 'flex', }}>

                                <span>{ForSaleItem.volume}</span> Units for <span>{ForSaleItem.currency.symbol + ForSaleItem.price}</span>
                            </p>
                            <div className='SaleLine__BtnsContainer'>
                                <p className="SaleLine__UpdateBtn" onClick={() =>{}}>Update</p>
                                <p style={{ fontSize: '20px', fontWeight: 'bolder', color: '#fff', lineHeight: '0', marginLeft: '15px' }}>X</p>
                            </div>
                        </div>
                    );
                
                return (temp);
            })}
            <div className="SaleLine" style={{borderRadius:'0'}}>
                    <p style={{fontSize:'19px',fontWeight:'bold',padding:'0 25px'}}>Total</p>
                    <p style={{ fontSize: "10px", display: 'flex', }}>

                        <span>{props.unit.onSale}</span> Units for <span>{}</span>
                    </p>
                    <div className='SaleLine__BtnsContainer'>
                        <p className="SaleLine__UpdateBtn" onClick={() => {}}>Update</p>
                        <p style={{ fontSize: '20px', fontWeight: 'bolder', color: '#fff', lineHeight: '0', marginLeft: '15px' }}>X</p>
                    </div>
                </div>
        </div>
        // <div className="ForSaleStS" style={{ zIndex: (props.AllSalesCount - props.SaleIndex), height: `${26 + (14 * props.SaleIndex)}vw` }}>
        //     <p className="ForSaleTxtStS"><i>{props.SaleIndex + 1}</i><spam>{props.Sale.volume + 'PS'}</spam> for <spam>{'PC' + props.Sale.price}</spam></p>
        //     <p className="RemoveBtnStS" onClick={() => handleRemoveSale(props.Sale.id)}>Remove Sale</p>
        // </div>
    )


}