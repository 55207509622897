import { Link } from "react-router-dom";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/


import "./MenuBtn.css";

import MenuIcon from '../../../assets/images/common/menu-icon.png'
export default function MenuBtn(props) {
  return (
    <div className="MenuBtn">
      <Link to="/menu">
        <div className="MenuBtn__Blur" />
        <img src={MenuIcon} alt="" className="MenuBtn__Icon" />
      </Link>
    </div>
  );
}
