import "./style.css";
import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
import SorryImage from "../../../assets/images/common/smiley-1.png";
// import { Link } from 'react-router-dom';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimateKeyframes } from "react-simple-animate";
import React from "react";
/*import {API_URL} from '@src/config.js';*/

import isValidateEmail from "../../../helpers/isValidateEmail";

import Loader from "../../../components/common/Loader";

export default function IssuerReg_NonEligible() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });

  const navigate = useNavigate();

  const [ShowSuccesedMessage, setShowSuccesedMessage] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ShowLoader, setShowLoader] = useState(false);

  const [Email, setEmail] = useState(null);
  const handleEmailInput = (event) => {
    ErrorMessage !== "" && setErrorMessage("");
    if (isValidateEmail(event.target.value)) {
      setEmail(event.target.value);
      // console.log(event.target.value);
    } else {
      setEmail("");
    }
  };

  const handleSubmit = () => {
    setShowLoader(true);
    if (Email && Email !== "") {
      setShowLoader(false);
      setShowSuccesedMessage(true);
    } else {
      setShowLoader(false);
      setErrorMessage("Please insert your email.");
      !Email && setEmail("");
    }
  };

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.48}
      direction="normal"
      keyframes={[
        "transform: translateY(-100vw)",
        "transform: translateY(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="SorryMC Page Page--padding-8vw headline">
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          style={{ top: "5vw" }}
          onClick={() => {
            navigate("/");
          }}
        />
        <div className="Page--flex">
          <div style={{ padding: "21vw 0 3vh" }}>
            <img
              src={SorryImage}
              alt="emoji"
              className="IssuerReg_NonEligible__Emoji"
            />
            <p className="IssuerReg_NonEligible__ItsTxt unnamed-character-style-1">
              It’s not you
              <br /> it’s me.
            </p>
            <p className="IssuerReg_NonEligible__AtTxtSorry">
              Our platform currently requires a minimum community size of 40k
              and at least 10k TikTok followers for creators to join.
              <br />
              <br />
              If you’re eager to join, please provide your email for our
              waitlist. We’ll keep you informed and let you know when you can
              join.
            </p>
            <input
              className={
                "input-default headline " + (Email === "" ? "input-wrong" : "")
              }
              type="email"
              placeholder="Your Email"
              onChange={handleEmailInput}
              onBlur={() => {
                if (!Email) {
                  setEmail("");
                }
              }}
            />
          </div>
          <div className="IssuerReg_NonEligible__indicate-container">
            {ShowLoader ? (
              <div className="loader--5vw">
                <Loader />
              </div>
            ) : (
              <p className="error-message" style={{ margin: "0" }}>
                {ErrorMessage}
              </p>
            )}
          </div>
          <button
            className="btn btn--yellow-white headline"
            style={{
              marginBottom: "5vh",
              padding: "0 15px",
              width: "fit-content",
              margin: "0 auto",
            }}
            onClick={handleSubmit}
          >
            Join the waitlist
          </button>

          {ShowSuccesedMessage && (
            <div
              className="IssuerReg_NonEligible__SuccessMsg
            "
            >
              <p>
                <span>Your request has been received!</span>
                <br />
                <br />
                You have successfully added to
                <br /> the waiting list{" "}
              </p>
              <button
                className="btn btn--white-yellow IssuerReg_NonEligible__SuccessMsgDoneBtn headline"
                onClick={() => {
                  navigate(store.getState().profile ? "/Exchange" : "/");
                }}
              >
                Done
              </button>
            </div>
          )}
        </div>

        <div style={{ margin: "50px 0vw 5px" }}>
          <svg height="1px" width="60vw">
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="#909090"
              stroke-linecap="butt"
            />
          </svg>
          <p
            style={{
              color: "#909090",
              fontWeight: "lighter",
              fontSize: "14px",
              lineHeight: "19px",
            }}
          >
            If you don’t have a TikTok account or face any issues, contact us at
            support@passionstocks.com.
            <br /> If you’re a creator manager, please click here.{" "}
          </p>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
