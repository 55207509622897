import "./style.css";
import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
// import TermsOfUseIcon from '../../images/icons/TermsIcon.png';
import LegalIcon from "../../../assets/images/menu/legal.png";
import PrivatePolicyIcon from "../../../assets/images/menu/protector.png";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import React from "react";
/*import {API_URL} from '@src/config.js';*/

export default function Legal(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Menu",
  });

  const navigate = useNavigate();

  return (
    <div className="Page headline">
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          position: "relative",
          marginTop: "20vw",
        }}
      >
        <img
          src={BackPage}
          alt=""
          // id=""
          className="back-btn"
          style={{
            position: "relative",
            top: 0,
            left: 0,
            marginRight: "12px",
          }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <h1
          className="headline__menu"
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <img
            src={LegalIcon}
            alt="Legal"
            // className="Menu_AccountAndSetting__Icon"
          />
          Legal
        </h1>
      </div>

      <div style={{fontSize:'16px',lineHeight:'20px',fontWeight:'lighter', marginLeft:'14vw'}}>
        <Link to={`/t&cs-policy`}>
          <p>Terms And Conditions</p>
        </Link>
        <Link to="/privacy-policy">
          <p>Privacy policy</p>
        </Link>
        <Link to="/cookies-policy">
          <p>Cookies policy</p>
        </Link>
      </div>
    </div>
  );
}
