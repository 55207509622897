import "./style.css";
import { useSelector } from "react-redux";
import React from "react";

import StarsRanking from "../../../../../components/common/StarsRanking";
import NetWorth from "./components/NetWorth";
import { Link } from "react-router-dom";

const selectProfile = (state) => state.profile;

export default function WalletHeader(props) {
  const profile = useSelector(selectProfile);

  return (
    <div className="WalletHeader headline">
      {profile.accountType === 4 && (
        <div>
          <StarsRanking ranking={profile ? profile.ranking : 0} />
          <p
            className="text-15 text-center text-lighter"
            style={{ margin: "5px 0", color: "#909090" }}
          >
            {profile ? profile.ranking : 0} stars performance ranking{" "}
          </p>
        </div>
      )}

      <NetWorth />

      <div className="WalletHeader__BtnsContainer">
        <Link to="/wallet/deposit">
          <button className="WalletHeader__DepositBtn">Deposit</button>
        </Link>

        <Link to="/wallet/withdrawal/beneficiary-selection">
          <button className="WalletHeader__WithdrawBtn">Withdraw</button>
        </Link>
      </div>
    </div>
  );
}
