import "./style.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import React from "react";

import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
import ChatIcon from "../../../assets/images/menu/chat.png";

export default function Support() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Menu",
  });
  const navigate = useNavigate();

  return (
    <div
      className="Support Page headline"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          position: "relative",
          marginTop: "20vw",
        }}
      >
        <img
          src={BackPage}
          alt=""
          // id=""
          className="back-btn"
          style={{
            position: "relative",
            top: 0,
            left: 0,
            marginRight: "12px",
          }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <h1
          className="headline__menu"
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <img
            src={ChatIcon}
            alt="Support"
            // className="Menu_AccountAndSetting__Icon"
          />
          Support
        </h1>
      </div>

      <div className="Support__txt">
        <span>please contact us at our support center here.</span>
        <br />
        <br />
        <p>
          or
          <br />
          <br />
          send us an email to
          <br />
          <span>info@passionstocks.com</span>
        </p>
      </div>
    </div>
  );
}
