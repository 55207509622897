import "./style.css";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/import ShortLogo from "../../../assets/images/common/ps-logo-2-rows.png";

import store from "../../../store/store";
import { Link } from "react-router-dom";
import { useState } from "react";
import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import GeneralAppMessage from "../../../components/common/GeneralAppMessage";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Terms() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  const [DeclineMenu, setDeclineMenu] = useState(false);

  let SMFollows = store.getState().moneyCalcUserSMFollows;
  let FollowsNumber =
    SMFollows.Instagram + SMFollows.TikTok + SMFollows.YouTube;
  // console.log(FollowsNumber);
  let SMIOffering = {
    ipo_starting_price: 0,
    authorized_stocks: 0,
    tier_color: "",
  };

  if (FollowsNumber <= 40000) {
    SMIOffering.ipo_starting_price = 2 * 10;
    SMIOffering.authorized_stocks = 4000;
    SMIOffering.tier_color = "blue";
  }
  if (FollowsNumber > 40000 && FollowsNumber <= 100000) {
    SMIOffering.ipo_starting_price = 3 * 10;
    SMIOffering.authorized_stocks = 6500;
    SMIOffering.tier_color = "blue";
  }
  if (FollowsNumber > 100000 && FollowsNumber <= 250000) {
    SMIOffering.ipo_starting_price = 5 * 10;
    SMIOffering.authorized_stocks = 13000;
    SMIOffering.tier_color = "purple";
  }
  if (FollowsNumber > 250000 && FollowsNumber <= 500000) {
    SMIOffering.ipo_starting_price = 7.5 * 10;
    SMIOffering.authorized_stocks = 19500;
    SMIOffering.tier_color = "purple";
  }
  if (FollowsNumber > 500000 && FollowsNumber <= 1000000) {
    SMIOffering.ipo_starting_price = 10 * 10;
    SMIOffering.authorized_stocks = 26000;
    SMIOffering.tier_color = "purple";
  }
  if (FollowsNumber > 1000000 && FollowsNumber <= 2500000) {
    SMIOffering.ipo_starting_price = 12 * 10;
    SMIOffering.authorized_stocks = 32500;
    SMIOffering.tier_color = "yellow";
  }
  if (FollowsNumber > 2500000 && FollowsNumber <= 5000000) {
    SMIOffering.ipo_starting_price = 15 * 10;
    SMIOffering.authorized_stocks = 39000;
    SMIOffering.tier_color = "yellow";
  }
  if (FollowsNumber > 5000000 && FollowsNumber <= 7500000) {
    SMIOffering.ipo_starting_price = 20 * 10;
    SMIOffering.authorized_stocks = 45500;
    SMIOffering.tier_color = "yellow";
  }
  if (FollowsNumber > 7500000 && FollowsNumber <= 10000000) {
    SMIOffering.ipo_starting_price = 50 * 10;
    SMIOffering.authorized_stocks = 52000;
    SMIOffering.tier_color = "yellow";
  }
  if (FollowsNumber > 10000000) {
    SMIOffering.ipo_starting_price = 50 * 10;
    SMIOffering.authorized_stocks = 52000;
    SMIOffering.tier_color = "yellow";
  }

  store.dispatch({ type: "money_calc/AuthorizeStocks", payload: SMIOffering });

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(-100vw)",
        "transform: translateX(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="MoneyCalcTAndCs Page"
        style={{ height: windowInnerSize.height }}
      >
        <img src={ShortLogo} alt="" className="MoneyCalcTAndCs__ShortLogo" />
        <div
          className="MoneyCalcTAndCs__TermTxtContainer headline"
          style={{ height: windowInnerSize.height * 0.65 }}
        >
          <p>
            <span>TERMS AND CONDITIONS</span>
            <i>
              <br />
              Definitions
            </i>
            PS Or Passionstocks Shall Mean Passionstocks Platform Or The <br />
            Corporation That Owns Passionstocks Brand And Its Assets.
            <br />
            <br />
            User Shall Mean Every Entity Who Is Using The Platform In Any Form.
            <br />
            <br />
            SMI Shall Mean Social Media Influencer Or Any Entity That Stock
            <br /> Is Sold In Its Social Media Accounts.
            <br />
            <br />
            <i>
              Introduction To The Terms And Conditions (Also Part Of The Terms
              And Conditions)
            </i>
            This Website That You Are Using Or Going To Use Is The First
            <br /> Version Of Passionstocks Platform Known As The MVP (“Minimum
            <br /> Valuable Product”).
            <br />
            <br />
            Passionstocks Is The World’s First Social Stock Exchange, It Is A
            <br /> Brand New Concept. This Means, There Are Potential Unforeseen
            <br /> Risks That Could Affect The Use
            <br /> Of The Platform. It Is The Nature Of New Technological
            Advancements And The Evolution Of The
            <br /> Society; That Currently, There Are No Laws Or Legislations
            That
            <br /> Explicitly Exist Or That Directly Govern The Development Of
            The
            <br /> Passionstocks Platform. At This Juncture,We Have Been And{" "}
            <br />
            Currently Are Being Advised By Our Law Firm, Cranko Karp
            <br /> Attorneys, That We Are Complying With All Current South
            African
            <br /> Constitutional Laws.
            <br />
            <br />
            We Are Always In The Process Of Adapting And Improving Our
            <br /> Platform To Make The World A Better Place For Our Users.
            <br />
            <br />
            However, To Do That, We Do Anticipate Minor Adjustments To
            <br /> The Ts And Cs, Private Policies And User Information
            Protection. <br />
            To Our Best Ability, Our Aim Is To Shrink The Probability Of Major{" "}
            <br />
            Changes, But At The Same Time In Order To Develop The Best
            <br /> Product Possible - To Be Innovative, We Need To Take
            Responsible
            <br /> Risks To Make It Accessible For Everyone With Just A
            Smartphone
            <br /> To Make A Living For Themselves And Their Communities By
            <br /> Creating A New Stream Of Income.
            <br />
            <br />
            As Mentioned, We Are Still Working On Refining Our Policies And
            <br /> Terms And Conditions In A More Structured Fashion To Protect
            All <br />
            Our Users.
            <br />
            <br />
            In The Interim, You Can Familiarise Yourself With The Terms And
            <br /> Conditions By Visiting The FAQ
            <br />
            Page On The Website; Www.Passionstocks.Com, On Our Social <br />
            Media Platforms Or By Emailing Marcel@Passionstocks.Com
            <br />
            <br />
            Passionstocks Is A High Risk Investment Platform And Your
            <br /> Capital Is At Risk. Use This Service Responsibly.
            <br />
            <br />
            As Previously Mentioned, We Anticipate Future Laws And <br />
            Legislation To Be Constructed In Order To Regulate The
            <br /> Passionstocks Exchange, Hereunder Are A Few Reasons Of The
            <br /> Worst Cases:
            <br />
            Your Investment Portfolio May Be Up For Review Or Termination
            <br />
            Security Or Privacy Leaks
            <br />
            Legality Since The Technology Always Is In Advance Of The Law.
            <br />
            <br />
            <br />
            <br />
            <br />
            Additionally, Here Are A Few Anticipated Issues That Might Occur And
            Its Reasoning:
            <br />
            <br />
            Absence Of Long-Term Investors: A Very Small Proportion Of Purchases
            And Sales Effected In A Stock Exchange Are By These Investors.
            Speculators Constitute A Major Portion Of The Market. Many Of The
            Transactions Are Carried Out By Speculators Who Plan To Derive
            Profits From Short Term Fluctuations In Prices Of Securities.
            <br />
            <br />
            Fraudulent Social Media Information: Frauds Involving Forged Share
            Certificates Are Quite Common. Investors Who Buy Shares
            Unfortunately May Get Such Fake Certificates. They Would Not Be Able
            To Trace The Seller And Their Entire Investment In Such Fake Shares
            Would Be Lost.
            <br />
            <br />
            Insider Trading: Insider Trading Is A Common Occurrence In Many
            Stock Exchanges. Insiders Who Come To Know Privileged Information
            Use It Either To Buy Or Sell Shares And Make A Quick Profit At The
            Expense Of Common Investors.
            <br />
            <br />
            Prevalence Of Price Rigging: Companies Which Plan To Issue
            Securities Artificially Try To Increase The Share Prices, To Make
            Their Issue Attractive As Well As Enable Them To Price Their Issue
            At A High Premium. Promoters Enter Into A Secret Agreement With The
            Investor. Bottom Line: We Could Have A Problem Of Fraud Between
            SMI(Social Media Influencer) And Investors.
            <br />
            <br />
            Thin Trading: Though Many Companies (Smis) Are Listed In Stock
            Exchange, Many Are Not Traded. Trading Is Confined To Only Around
            25% Of The Shares Listed On A Stock Exchange. Therefore The
            Investors Have Restricted Choice. This Means That Only Big Names
            Like Justin Bieber Will Get Investment. Smaller Smis Are Left Out.
            <br />
            <br />
            Excessive Speculation: There Is Excessive Speculation In Some Shares
            Which Artificially Results In Increasing Or Decreasing The Prices.
            This Ties In About It Being Like Gambling.
            <br />
            <br />
            Poor Liquidity: The Main Goal Of Listing Shares In A Stock Exchange
            Is To Provide Liquidity. In The Light Of The Previous Points, There
            Might Be A Problem With Liquidity.
            <br />
            <br />
            Poor Broker Services: The Service Of Brokers In A Stock Exchange Has
            Been Seen To Be Poor. Most Of The Time, Brokers Are Unregulated. If
            The Brokers Commit Fraud, Then Investors Will Lose Their Money.
            <br />
            <br />
            <br />
            <i>GENERAL TERMS AND CONDITIONS</i>
            <br />
            <br />
            <br />
            By Registering With And Using PS You Hereby Accept And Agree To Be
            Bound By And Abide By All Its Terms And Conditions Which Are
            Available For Viewing Upon Request.
            <br />
            <br />
            You Acknowledge That PS Involves High Risk Transactions Which You
            Agree Will Be Carried Out At Your Own Risk And Responsibility.
            <br />
            <br />
            PS Reserves The Right To Amend At Any Time To Its Terms And
            Conditions And At Its Sole Discretion Which Are Effective
            Immediately From The Time PS Changes Them, And Apply To Any Use Of
            The Website Thereafter. By Continuing To Use PS, You Agree To The
            Terms And Conditions As Amended Or As They Currently Appear.
            <br />
            <br />
            PS Reserves The Right At Any Time And Without Notice To:
            <br />
            <br />
            Modify, Suspend Or Terminate Its Services Or Any Portion Thereof;
            <br />
            <br />
            Restrict, Limit, Suspend Or Terminate Your Access To PS Or Any
            Portion Thereof;
            <br />
            <br />
            Delete Any Content You Post On PS As A User If In Its Sole
            Discretion Such Does Not Comply With These Terms And Conditions
            And/Or Any Applicable Law;
            <br />
            <br />
            Disclose Information About Your Use Of PS In Connection With Law
            Enforcement Investigation Of Any Suspected Or Alleged Illegal
            Activity, Or In Response To A Lawful Court Order; Or
            <br />
            <br />
            Change The Payment Methods.
            <br />
            <br />
            <br />
            <i>ACCOUNT REGISTRATION</i>
            <br />
            <br />
            To Become A User You Must Register And Create A User Account On PS.
            You Must Provide A Valid Email Address, A Username, And A Password
            Or A Valid Twitter Account. It Is A Condition Of Your Use Of The
            Website That All The Information You Provide On The Website Is
            Correct, Current, And Complete.
            <br />
            <br />
            You Agree That All Information You Provide As A User Or Otherwise,
            Including But Not Limited To Information Provided Through The Use Of
            Any Interactive Features On The Website, Is Governed By Ps’s Privacy
            Policy At Https://PS???.Com/Privacy (/Privacy).AVIGDOR ??
            <br />
            <br />
            If You Wish To View User Content You Will Need To Provide Details Of
            A Payment Card To A Payment Provider. Your Payment Card Information
            Is Stored By The Payment Provider.
            <br />
            <br />
            You Are Fully Responsible For Any And All Activities That Occur On
            Your Account And You Are Responsible For Keeping Your Login Details
            Confidential And Secure. You Agree Not To Disclose These Details To
            Any Other Person Or Entity And Immediately Notify PS At
            Marcel@Passionstocks.Com.
            <br />
            <br />
            PS Reserves The Right To Disable Any User Name, Password, Or Other
            Identifier, Whether Chosen By You Or Provided By PS, At Any Time And
            At Ps’s Sole Discretion.
            <br />
            <br />
            <br />
            <span>DISCLAIMER OF WARRANTIES; LIMITATIONS OF LIABILITY</span>
            <br />
            <br />
            By Using PS , You Acknowledge And Agree As Follows:
            <br />
            <br />
            PS And All Of Its Services And Features Are Provided Without
            Warranties Of Any Kind, Express Or Implied. To The Fullest Extent
            Permitted By Law, PS Disclaim Any And All Warranties, Express Or
            Implied, With Respect To PS And All Of Its Services And Features,
            Including, And Without Limitation, Implied Warranties Of
            Merchantability And Fitness For A Particular Purpose. PS Will Not Be
            Liable For Any Loss Or Damage Caused By A Distributed
            Denial-Of-Service Attack, Viruses, Or Other Technologically Harmful
            Material That May Infect Your Computer Equipment, Computer Programs,
            Data, Or Other Proprietary Material Due To Your Use Of The Website
            Or Any Services Or Items Obtained Through The Website Or To Your
            Downloading Of Any Material Posted On It, Or On Any Website Linked
            To It. Any Use Of PS And Its Services And Features Will Be Solely
            And Entirely At Your Own Risk. The Foregoing Does Not Affect Any
            Warranties That Cannot Be Excluded Or Limited Under Applicable Law;
            <br />
            <br />
            PS Will Not Be Liable In Contract, Tort, Negligence, Strict
            Liability Or Under Any Other Legal Or Equitable Principle, For Any
            Indirect, Incidental, Exemplary, Special, Punitive Or Consequential
            Damages (Including, And Without Limitation, Loss Of Use, Profits,
            Data Or Information, Or Loss Of Business Goodwill Or Opportunity)
            Arising Out Of Or Related To Your Use Of (Or Your Inability To Use)
            PS Or Any Of Its Services Or Features, Nor Shall PS Be Held Liable
            In The Event Your User Content Is Illegally Distributed By Another
            User, However Where Such Distribution Does Occur Please Contact Us
            At Marcel@Passionstocks.Com;
            <br />
            <br />
            In No Event Shall PS Total And Aggregate Liability To You And/Or
            Others For Any And All Claims Arising Out Of Or Related To Your Use
            Of (Or Your Inability To Use) PS Or Any Of Its Services Or Features,
            Exceed One Thousand Rand (ZAR 1000.00). This Does Not Affect Any
            Liability That Cannot Be Excluded Or Limited Under Applicable Law.
            <br />
            <br />
            <span>USER INDEMNIFICATION</span>
            <br />
            <br />
            By Using PS You Agree To Indemnify And Hold Harmless PS Its
            Controlling Entities And All And Any Directors And Shareholders
            Thereof, Including Its Employees, Agents, Representatives,
            Successors And Assigns From And Against Any And All Claims, Demands,
            Causes Of Action, Actions, Suits, Proceedings, Judgments, Orders,
            Damages, Liabilities, Losses, Costs And Expenses (Including, Without
            Limitation, Reasonable Attorneys' Fees And Legal Costs) Arising Out
            Of Or Related To Any Of The Following:
            <br />
            <br />
            Your Use Of PS Or Any Of Its Services Or Features;
            <br />
            <br />
            Any User Content Created, Published, Or Otherwise Made Available On
            PS By You;
            <br />
            <br />
            Any Transaction Or Interaction Between You And Any Other User Of PS;
            And/Or
            <br />
            <br />
            Your Violation Of The Terms And Conditions Or Any Applicable Law.
            <br />
            <br />
          </p>
        </div>
        <div
          className="MoneyCalcTAndCs__BtnsContainer"
          style={{ marginTop: windowInnerSize.height * 0.04 }}
        >
          {/* <Link to="/"> */}
          <p
            className="MoneyCalcTAndCs__DeclineBtn headline"
            onClick={() => {
              setDeclineMenu(true);
            }}
          >
            Decline
          </p>
          {/* </Link> */}
          <Link to="/money-calculator/congratulations">
            <p className="MoneyCalcTAndCs__AcceptBtn headline">Accept</p>
          </Link>
        </div>
        {DeclineMenu && (
          <GeneralAppMessage
            text={"Are you sure?"}
            onClickNo={() => {
              setDeclineMenu(false);
            }}
            onClickYes={() => {
              navigate("/");
            }}
            class={"Width-90vw"}

          />
        )}
      </div>
    </AnimateKeyframes>
  );
}
