import './Comment.css'

import store from '../../../../store/store';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import React from 'react';
import {API_URL} from '../../../../config.js';

import RedHurtAC from '../../../../assets/images/exchange/hurt-full.png';
import EmptyHurtAC from '../../../../assets/images/exchange/hurt-empty.png';

const selectTokens = state => state.tokens;

export default function Comment(props) {
    const tokens = useSelector(selectTokens);

    const [displayRepliesComments, setdisplayRepliesComments] = useState(false);
    const [replyCommentPage, setreplyCommentPage] = useState(1);
    const [repliesComments, setrepliesComments] = useState([]);

    const [commentData, setcommentData] = useState(null)
    useEffect(() => {
        if (props.commentData) {
            setcommentData(props.commentData)
        }
    }, [props.commentData])

    async function handleAddLike(commentId) {
        await axios({
            method: 'POST',
            url: `${API_URL}/api/sales/comments/` + commentId + `/like`,
            headers: {
                Authorization: tokens.accessToken
            },
        }).then(async function (response) {
            // console.log('post like ' + props.stockId)
            setcommentData(response.data);
            // console.log(response.data);
        }).catch(function (error) {
            // console.log('error to post like');
            // console.log(error);
        });
    }

    async function handleDeleteLike(commentId) {
        await axios({
            method: 'DELETE',
            url: `${API_URL}/api/sales/comments/` + commentId + `/like`,
            headers: {
                Authorization: tokens.accessToken
            },
        }).then(async function (response) {
            // console.log('post like ' + props.stockId);
            // getAllComments();
            // console.log(response.data);
            setcommentData(response.data);
        }).catch(function (error) {
            // console.log('error to post like');
            // console.log(error);
        });
    }

    const handleScrollComment = () => {
        let commentScrollElement = document.getElementById("CommentFrame" + commentData.id);
        if (commentScrollElement.scrollLeft > 120) {
            // console.log('gfgffggf');
            commentScrollElement.style.overflow = "hidden";
            commentScrollElement.scrollLeft = 0;
            store.dispatch({ type: 'app/saleRepliedComment/add', payload: commentData });
            setTimeout(() => { commentScrollElement.style.overflow = "scroll"; }, 2000)
        }
    }

    // calc post date in days /hours/minutes:
    const [postDate, setpostDate] = useState(null)
    useEffect(() => {
        if (commentData) {
            // let postDateData = new Date(moment(commentData.createdAt).format('lll'));
            // // console.log(postDateData);
            // let postDateInMinutes = Math.abs(Math.round((new Date().getTime() - postDateData.getTime()) / 1000 / (60)));
            // let postDateInHours = +((postDateInMinutes / 60).toFixed(0)) > 0 ? moment(commentData.createdAt).format('LT') : '';
            // let postDateInDays = +((postDateInMinutes / 24 * 60).toFixed(0)) > 0 ? `${postDateData.getDate()} ${new Intl.DateTimeFormat('en-US', { month: 'short' }).format(postDateData.getMonth())}` : '';
            // let postDateInYears = +((postDateInMinutes / 24 * 60 * 365).toFixed(0)) > 0 ? postDateData.getFullYear() : '';
            // postDateInMinutes = postDateInHours ? '' : postDateInMinutes;
            setpostDate(moment(commentData.createdAt).calendar());
        }
    }, [commentData])

    return (
        commentData
            ?
            <div id="CommentDiv">
                <div className="CommentFrameC" id={'CommentFrame' + commentData.id} onScroll={handleScrollComment}>
                    <div id="CommentScrollContainerC" >
                        <div id="ReplyCommentTxtScroll">
                            <p className='headline'>Reply</p>
                        </div>
                        <div id="CommentElementC">
                            <p id="CommentTextElementC" className="headline">
                                <span id={"UserNameComment"} className="headline">{props.isUser ? "You" : commentData.username}</span>
                                {commentData.text}</p>
                            <p id="CommentLikesAC" className="headline"><img src={commentData.lastAction ? RedHurtAC : EmptyHurtAC} alt="" id="LikeHurtAC" onClick={commentData.lastAction ? () => handleDeleteLike(commentData.id) : () => handleAddLike(commentData.id)} /> <span>{commentData.likes}</span></p>
                            <p id="UpdatedAtAC" className="headline">{postDate}</p>
                        </div>
                    </div>
                </div>

                {
                    commentData.replies > 0
                        ?
                        <div id="ViewReplyBtnAC" className="headline" onClick={() => getReplyComments(commentData.id)}>
                            <svg height="0.35vw" width="5.5%" id="ViewReplyLineAC">
                                <line x1="0" x2="100%" y1="0" y2="0" stroke="white" stroke-width="100%" stroke-linecap="butt" />
                            </svg>
                            {displayRepliesComments ? <p style={{ display: "inline" }} onClick={() => { setdisplayRepliesComments(false); setrepliesComments([]) }}>Hide replies</p> : <p style={{ display: "inline" }} onClick={() => { setdisplayRepliesComments(true) }}>View reply ({commentData.replies})</p>}
                        </div>
                        :
                        null
                }
                {
                    displayRepliesComments
                        ?
                        repliesComments
                        :
                        null
                }

            </div>

            :
            null

    )
    function getReplyComments(rootCommentId) {
        var config = {
            method: 'get',
            url: `${API_URL}/api/sales/comments`,
            headers: {
                Authorization: tokens.accessToken
            },
            params: {
                page: replyCommentPage,
                saleId: props.stockId,
                replyTo: rootCommentId
            }
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));

                let userId = store.getState().profile.id;
                let replyComments = response.data.comments;

                let repliesCommentsElements = [];
                for (let i = 0; i < replyComments.length; i++) {
                    repliesCommentsElements.push(
                        <Comment commentData={replyComments[i]} isUser={replyComments[i].userId === userId} stockId={props.stockId} />
                    )
                }
                setrepliesComments(repliesCommentsElements);
                // console.log(repliesCommentsElements);
            })
            .catch(function (error) {
                // console.log(error);
            });

    }

    function getComment() {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: API_URL + '/api/sales/comments/' + commentData.id,
            headers: {
                Authorization: tokens.accessToken
            }
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));

                setcommentData(response.data);
            })
            .catch(function (error) {
                // console.log(error);
            });

    }

}