import store from "../store/store";
// import { persistor } from '../store/store';
import {API_URL} from '../config.js';

export default function loadAgents(tokens, page) {
  var axios = require("axios");

  var config = {
    method: "get",
    url: API_URL + "/api/" + "agents/",
    headers: {
      Authorization: tokens.accessToken, // can't call hooks from this function (to get the accessToken)
    },
    data: {
      page: page,
    },
  };

  axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      store.dispatch({
        type: "app/my-agent/find-agency/agentsListNewPage/add",
        payload: response.data,
      });
    })
    .catch(function (error) {
      // console.log(error);
    });
}
