import "./style.css";
import React from "react";
export default function BulletProcess(props) {
  function getBullet() {
    let bulletArray = [];
    for (let i = 0; i < props.amount; i++) {
      bulletArray.push(
        <svg height="7px" width="7px">
          <circle
            cx="60%"
            cy="60%"
            r="23%"
            stroke={props.checkedIndex === i ? "#ffbf12" : "#ffffff"}
            strokeWidth="3"
          />
        </svg>
      );
    }
    return bulletArray;
  }
  return (
    props.amount &&
    props.checkedIndex>=0 && <div className="BulletProcess">{getBullet()}</div>
  );
}
