import "./style.css";
import React from 'react';


export default function Loader(props) {
  return (
    <div
      id="Loader"
      style={{
        border: `${props.width ? props.width : "1vw"} solid #f3f3f3`,
        borderTop: `${props.width ? props.width : "1vw"} solid #ffbf12`,
        boxShadow: props.glow ? "0px -2px 9px #ffbf12" : undefined,
      }}
    />
  );
}
