import "./style.css";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/import store from '../../../store/store';

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useState, useEffect } from "react";

import StockHolding from "../wallet/components/StockHolding";
import StockDataMetrix from "../../../components/common/StockDataMetrix/index.jsx";

import {StockTradeHistory} from "../../../components/common/StockTradeHistory/index.jsx";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function PortfolioExtendedView(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const location = useLocation();

  const [share, setShare] = useState(null);
  useEffect(() => {
    if (location.state?.share) {
      setShare(location.state.share);
    }
  }, [location]);

  const [metrixData, setMetrixData] = useState(null);
  useEffect(() => {
    if (share) {
      var metrix;
      if (location.state.isUserOwnStock) {
         metrix = {
          Growth: "someVal",
          Profit: "someVal",
          Frequency: "Highest Price",
          "Highest Price": "someVal",
          Views: "someVal",
          Volume: "otherVal",
        };
      } else {
        metrix = {
          Growth: share.stock.growth,
          Profit: share.totalProfit,
          Frequency: "0",
          "Highest Price": share.stock.highestPrice,
          Views: "0",
          Volume: share.volume,
        };

      }
      setMetrixData(metrix);
    }
  }, [share]);

  return (
    share && metrixData&& (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(100vw)",
          "transform: translateX(50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          className="Page headline"
          style={{ height: windowInnerSize.height, paddingTop: "20vw" }}
        >
          <StockHolding stockHolding={share} />

          <StockDataMetrix metrix={metrixData} />
{/* //jlk */}
          <StockTradeHistory investmentData={share} />
          
        </div>
      </AnimateKeyframes>
    )
  );
}
