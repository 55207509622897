import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import classnames from "classnames";

import "./style.css";
import store from "../../../store/store";

import loadBalance from "../../../loaders/totalWithdrawValue";
import loadWallet from "../../../loaders/wallet";

import StocksCategorySwitcher from "./components/StocksCategorySwitcher";
import ChartExchange from "./components/ChartExchange";
import SalesFeed from "./components/SalesFeed";
import ExSearchBar from "./components/ExSearchBar";
import ExSearchResults from "./components/ExSearchResults";

const selectTokens = (state) => state.tokens;
const selectBalance = (state) => state.walletWithdrawValue;
const selectAgencyTokenStorage = (state) => state.token_storage;
const selectProfile = (state) => state.profile;
const selectToRefreshed = (state) => state.toRefresh;
const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectWallet = (state) => state.wallet;

export default function Exchange(props) {
  //show Nav
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: true },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Exchange",
  });

  const tokens = useSelector(selectTokens);
  const balance = useSelector(selectBalance);
  const wallet = useSelector(selectWallet);
  const profile = useSelector(selectProfile);
  const isRefreshed = useSelector(selectToRefreshed);
  const windowInnerSize = useSelector(selectWindowInnerSize);

  // store.dispatch({ type: 'appConfig/edit_image_false', payload: null })

  // Balance banner:
  useEffect(() => {
    if (tokens && profile) {
      loadBalance(tokens);
      loadWallet(tokens, profile.accountType);
    }
    // console.log(tokens);
    store.dispatch({
      type: "ui/exchangeSearchDM/add",
      payload: false,
    });
    // store.dispatch({
    //   type: "app/salesFeedNewPageData/add",
    //   payload: null,
    // });
  }, []);

  // Balance banner animate:
  const [StateScroll, setStateScroll] = useState(0);
  const [visible, setvisible] = useState(false);
  useEffect(() => {
    // Hide or show the banner.
    const handleScroll = () => {
      // const { prevScrollpos } = scrollPos;

      let prevScrollpos = StateScroll;

      const currentScrollPos = document.getElementById("ExchangeDiv").scrollTop;
      const visible1 = prevScrollpos < currentScrollPos;
      // // console.log(visible1);
      setStateScroll(currentScrollPos);
      if (visible !== visible1) {
        store.dispatch({
          type: "agency/updateIsUserScrollUp",
          payload: visible1,
        });
      }
      setvisible(visible1);
    };
    document
      .getElementById("ExchangeDiv")
      .addEventListener("scroll", handleScroll);

    // return function cleanup() {
    //     document.getElementById('ExchangeDiv').removeEventListener("scroll", handleScroll);
    // };
  }, []);

  //Animate:
  const [animateSlide, setanimateSlide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setanimateSlide(true);
    }, 0);
    return () => {
      setanimateSlide(false);
    };
  }, []);

  const isAgencyStats = useSelector(selectAgencyTokenStorage);

  // useEffect(() => {
  //   setSearchInputText(null);
  // }, [isRefreshed]);

  // scroll feed event - load new sales / new search results:
  useEffect(() => {
    const exchangePage = document.getElementById("ExchangeDiv");
    exchangePage.addEventListener("scroll", () => {
      if (
        exchangePage.offsetHeight + exchangePage.scrollTop >=
        exchangePage.scrollHeight - 2
      ) {
        store.dispatch({
          type: "app/exchangeScrollEvent/toggle",
          payload: !store.getState().exchangeScrollEvent,
        });
      }
    });
  }, []);
  return (
    isRefreshed && ( // isRefreshed - using to reset the slide when clicking on the 'Exchange' icon
      <div
        id="ExchangeDiv"
        style={{
          top: isAgencyStats ? "12vw" : "0",
          height: windowInnerSize.height,
        }}
        className={
          "Exchange Page headline " + (!animateSlide ? "animate-to-left" : "")
        }
      >
        {/* balance banner: */}
        <p
          id="BalanceEllipse"
          className={classnames(
            store.getState().token_storage ? "navbarbAV" : "navbarb",
            {
              "navbar--hiddenb": visible,
            }
          )}
        >
          {(wallet ? wallet.currency.symbol : "") +
            (balance
              ? balance % 1 === 0
                ? balance
                : balance.toFixed(2)
              : balance === 0
              ? 0
              : "--")}
        </p>

        <svg
          id="EllipseBalance"
          height="25vw"
          width="100%"
          className={classnames(
            store.getState().token_storage ? "navbareAV" : "navbare",
            {
              "navbar--hiddene": visible,
            }
          )}
        >
          <ellipse
            cx="50%"
            cy="0vw"
            rx={store.getState().token_storage ? "45%" : "50%"}
            ry="25vw"
            fill="#FFBF12"
            stroke-width="2"
          />
        </svg>

        <ExSearchBar />
        <ExSearchResults />
        <StocksCategorySwitcher />
        <ChartExchange />
        <SalesFeed />

        <Link to="/exchange/portfolio">
          <p className="Exchange__TradeBtn headline">Trade</p>
        </Link>
      </div>
    )
  );
}
