import "./style.css";
import React from 'react';
import {API_URL} from '../../../config.js';
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";

import loadSale from "../../../loaders/sale";

import SaleOfferCard from "../../../components/common/SaleOfferCard";
import StockComment from "../exchange/components/StockComment";
import Comment from "./components/Comment";

import store from "../../../store/store";

import SendBtn from "../../../assets/images/common/send.svg";
import BackPage from "../../../assets/images/common/back-page.png";

// import unauthorized from "../../functions/unauthorized";

const selectTokens = (state) => state.tokens;
const selectStockView = (state) => state.sale;
const selectCommentReplied = (state) => state.saleRepliedComment;
const selectProfile = (state) => state.profile;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function SaleComments() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  const tokens = useSelector(selectTokens);
  const profile = useSelector(selectProfile);
  const stockView = useSelector(selectStockView);
  const commentReplied = useSelector(selectCommentReplied);

  let { saleId } = useParams();

  const [page, setpage] = useState(1);

  useEffect(() => {
    if (tokens) {
      loadSale(tokens, saleId);
      getAllComments();
    }
    return () => {
      store.dispatch({ type: "app/saleRepliedComment/add", payload: null });
    };
  }, []);

  useEffect(() => {
    let IntervalUpdate = setInterval(() => {
      loadSale(tokens, saleId);
    }, 5000);

    return () => {
      clearInterval(IntervalUpdate);
    };
  });

  const [IncreaseTextbox, setIncreaseTextbox] = useState(false);
  // const handleAddComment = (event) => {
  //     if (event.target.value.length > 80) {
  //         if (!IncreaseTextbox) {
  //             setIncreaseTextbox(true);
  //         }
  //     }
  //     else {
  //         if (IncreaseTextbox) {
  //             setIncreaseTextbox(false);
  //         }
  //     }
  //     if (event.target.value.length !== 0) {
  //         // if(event.target.value.charAt(event.target.value.length - 1)== 13)
  //         setAddCommentText(event.target.value);
  //     }
  //     else {
  //         setAddCommentText(null);
  //     }
  // }

  const [AddCommentText, setAddCommentText] = useState(null);
  async function submitComment() {
    var config = {
      method: "post",
      url:
        API_URL + "/api/sales/" + saleId + "/comment",
      headers: {
        Authorization: tokens.accessToken,
      },
      data: {
        comment: AddCommentText,
        replyTo: commentReplied ? commentReplied.id : null,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        getAllComments();
        setAddCommentText("");
      })
      .catch(function (error) {
        // console.log(error);
      });

    store.dispatch({ type: "app/saleRepliedComment/add", payload: null });
  }
  const [CommentsListE, setCommentsListE] = useState(null);

  const [UpdateSaleOfferData, setUpdateSaleOfferData] = useState(null);

  useEffect(() => {
    if (commentReplied) {
      document.getElementById("SaleComments__Input").focus();
    }
  }, [commentReplied]);

  return stockView ? (
    <div
      className="SaleComments Page"
      style={{
        height: windowInnerSize.height,
      }}
    >
      <img
        src={BackPage}
        alt=""
        className="back-btn"
        style={{zIndex:"5555",boxShadow: "inset 0px 4vw 14.5vw 0vw black",
        /* border-left: 0; */
        borderBottomRightRadius: "10vw",
        borderTopRightRadius: "10vw"}}
        onClick={() => {
          navigate("/exchange");
        }}
      />

      <div className="SaleComments__SaleDataContainer">
        <SaleOfferCard
          SaleOffer={stockView}
          UpdateSaleOfferData={UpdateSaleOfferData}
        />
        <StockComment
          SaleOffer={stockView}
          WithoutShortcutAddComment={true}
          UpdateSaleOfferData={UpdateSaleOfferData}
        />
      </div>
      <svg className="SaleComments__Line1" height="0.8vw" width="100%">
        <line
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          stroke="#5D5D5D"
          stroke-width="8vw"
          stroke-spannecap="butt"
        />
      </svg>
      <div
        className="SaleComments__CommentsFeed"
        style={{
          height:
            window.innerHeight -
            (window.innerHeight * 4.5) / 100 -
            (window.innerWidth * 90) / 100,
        }}
      >
        {CommentsListE}
     
      </div>
      <div className="SaleComments__InputContainer">
          {commentReplied && (
            <div id="CommentRepliedAC" className="headline">
              <p>
                {commentReplied.username}{" "}
                <span style={{ fontWeight: "normal" }}>
                  {commentReplied.text.length + commentReplied.username.length >
                  32
                    ? commentReplied.text.substring(
                        0,
                        39 - commentReplied.username.length
                      ) + " ..."
                    : commentReplied.text}
                </span>
                <span
                  style={{ float: "right" }}
                  onClick={() => {
                    store.dispatch({
                      type: "app/saleRepliedComment/add",
                      payload: null,
                    });
                  }}
                >
                  x
                </span>
              </p>
            </div>
          )}
          <textarea
            name="Text1"
            cols="40"
            rows="2"
            placeholder={"Add a comment..."}
            id="SaleComments__Input"
            className={
              (!IncreaseTextbox ? "SaleComments__Input--small " : "SaleComments__Input--large ") +
              "headline"
            }
            value={AddCommentText}
            onChange={(event) => setAddCommentText(event.target.value)}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                if (AddCommentText?.length > 0) {
                  setAddCommentText("");
                  await submitComment();
                  updateCounters();
                }
              }
            }}
          ></textarea>
          <img
            src={SendBtn}
            alt=""
            id="SendBtnAllC"
            onClick={async () => {
              if (AddCommentText?.length > 0) {
                setAddCommentText("");
                await submitComment();
                updateCounters();
              }
            }}
          />
          <div
            className="SaleComments__ProfilePic"
            style={{ backgroundImage: `url(${profile.profilePic})` }}
          />
        </div>
      {/* <div id="ProfileAvatarAC" style={{ backgroundImage: `url(${`https://web.passionstocks.com` + store.getState().profile.data.profile_photo_path})` }} /> */}
    </div>
  ) : null;

  async function updateCounters() {}

  async function getAllComments() {
    let AllCommentsList = [];
    await axios({
      method: "GET",
      url: `${API_URL}/api/sales/comments`,
      headers: {
        Authorization: tokens.accessToken,
      },
      params: {
        page: page,
        saleId: saleId,
      },
    })
      .then(async function (response) {
        // console.log("got comments list");
        // console.log(response.data);

        AllCommentsList = response.data.comments;
      })
      .catch(async function (error) {
        // console.log("error comments");
        // console.log(error);
      });

    let userId = store.getState().profile.id;
    let CommntsElements = [];
    let lastUserId;
    for (let i = 0; i < AllCommentsList.length; i++) {
      if (!AllCommentsList[i].replyToId) {
        CommntsElements.push(
          <Comment
            commentData={AllCommentsList[i]}
            isUser={AllCommentsList[i].userId === userId}
            stockId={saleId}
          />
          // <div id={'C' + i} className={"CommentElement"/*lastUserId !== AllCommentsList[i].userId ? "PaddingComment" : ""*/}>

          //     {/* {AllCommentsList[i].userId !== userId
          //     ?
          //     <p id={lastUserId !== AllCommentsList[i].userId ? "UserNameComment" : "HideAllC"} className="headline">{AllCommentsList[i].userId === userId ? "You" : AllCommentsList[i].username}</p>
          //     :
          //     null
          // } */}
          //     {/* <div id={lastUserId !== AllCommentsList[i].userId ? (AllCommentsList[i].userId !== userId ? "ElementProfilePicAllC" : "ElementProfilePicAllCOwn") : "HideAllC"} style={{ backgroundImage: `url(${`https://web.passionstocks.com/` + (AllCommentsList[i].userId === userId ? store.getState().profile.data.profile_photo_path : AllCommentsList[i].user.profile_photo_path)})` }} /> */}
          //     {/* {AllCommentsList[i].userId === userId
          //     ?

          //     <p id={lastUserId !== AllCommentsList[i].userId ? "UserNameOwnComment" : "HideAllC"} className="headline">{AllCommentsList[i].userId === userId ? "You" : AllCommentsList[i].userId}</p>
          //     :
          //     null
          // } */}
          //     <p id="CommentTextElement" className="headline">
          //         <span id={/*lastUserId !== AllCommentsList[i].userId ? */"UserNameComment" /*: "HideAllC"*/} className="headline">{AllCommentsList[i].userId === userId ? "You" : AllCommentsList[i].username}</span>
          //         {AllCommentsList[i].text}</p>
          //     <p id="CommentLikesAC" className="headline"><img src={AllCommentsList[i].lastAction ? RedHurtAC : EmptyHurtAC} alt="" id="LikeHurtAC" onClick={AllCommentsList[i].lastAction ? () => handleDeleteLike(AllCommentsList[i].id) : () => handleAddLike(AllCommentsList[i].id)} /> <span>{AllCommentsList[i].likes}</span></p>
          //     <p id="UpdatedAtAC" className="headline">{Math.abs(Math.round((new Date().getTime() - new Date(AllCommentsList[i].updatedAt).getTime()) / 1000 / (60 * 60))) + 'h'}</p>
          //     {
          //         AllCommentsList[i].replies > 0
          //             ?
          //             <p id="ViewReplyBtnAC" className="headline" onClick={() => getReplyComments(i, AllCommentsList[i].id)}>
          //                 <svg height="0.35vw" width="5.5%" id="ViewReplyLineAC">
          //                     <line x1="0" x2="100%" y1="0" y2="0" stroke="white" stroke-width="100%" stroke-linecap="butt" />
          //                 </svg>
          //                 View reply ({AllCommentsList[i].replies})
          //             </p>
          //             :
          //             null
          //     }
          // </div>
        );
      }

      lastUserId = AllCommentsList[i].userId;
    }
    setCommentsListE(CommntsElements);
  }
}
