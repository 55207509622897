import StockHolding from "../../StockHolding.jsx";
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { API_URL } from "../../../../../../config.js";
import { ROLES } from "../../../../../../tools/role.js";
import ArrowToSlide from "../../ArrowToSlide.jsx";
import ReactImageGallery from "react-image-gallery";
import Loader from "../../../../../../components/common/Loader/index.jsx";

const selectTokens = (state) => state.tokens;
const selectProfile = (state) => state.profile;

export default function InvestedUnitsSlide(props) {
  const tokens = useSelector(selectTokens);
  const profile = useSelector(selectProfile);

  const [investedUnitsData, setinvestedUnitsData] = useState(null);
  const [investedUnitsItems, setinvestedUnitsItems] = useState(null);

  useEffect(() => {
    setinvestedUnitsData(null);
    setinvestedUnitsItems(null);

    if (
      (props.portfolioMode === 0 || props.portfolioMode === 1) &&
      profile.accountType & ROLES.INVESTOR
    ) {
      setinvestedUnitsItems(0);
      fetchData();
    }
  }, [props.portfolioMode]);

  async function fetchData() {
    console.log("fetch issuer units");
    try {
      const response = await axios.get(API_URL + "/api/investors/shares", {
        headers: {
          Authorization: tokens.accessToken,
        },
      });
      console.log(response.data);
      setinvestedUnitsData(response.data);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("Response data:", error.response.data); // Response data
        console.log("Status code:", error.response.status); // Status code
        console.log("Headers:", error.response.headers); // Response headers
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Request made but no response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error during request setup:", error.message);
      }
      setinvestedUnitsItems(null);
    }
  }

  useEffect(() => {
    if (investedUnitsData) {
      generateSlides();
    }
  }, [investedUnitsData]);

  const imageGallery = React.createRef();

  function generateSlides() {
    let temp = investedUnitsData.map((unit, index) => {
      console.log("999" + unit);
      return {
        renderItem: () => {
          return (
            <StockHolding
              className={`WalletSlider__StockHolding`}
              key={index + 1}
              stockHolding={unit}
            />
          );
        },
      };
    });
    setinvestedUnitsItems(temp);
  }

  const [arrowsSliderStatus, setarrowsSliderStatus] = useState({});
  useEffect(() => {
    if (investedUnitsItems) {
      setarrowsSliderStatus({
        left: false,
        right: investedUnitsItems.length - 1 > 0 ? true : false,
      });
    }
  }, [investedUnitsItems]);

  function updateArrowSlideStatus(i) {
    if (i === 0) {
      setarrowsSliderStatus({
        left: false,
        right: investedUnitsItems.length - 1 ? true : false,
      });
    } else {
      if (i === investedUnitsItems.length - 1) {
        setarrowsSliderStatus({
          left: investedUnitsItems.length - 1 ? true : false,
          right: false,
        });
      } else {
        setarrowsSliderStatus({
          left: true,
          right: true,
        });
      }
    }
  }
  function slideToRight() {
    imageGallery.current.slideToIndex(
      imageGallery.current.getCurrentIndex() + 1
    );
  }

  function slideToLeft() {
    imageGallery.current.slideToIndex(
      imageGallery.current.getCurrentIndex() - 1
    );
  }
  return investedUnitsItems ? (
    <div
      className="BrandUnits__investedUnits"
      style={{ position: "relative", marginTop: "30px" }}
    >
      <ArrowToSlide
        arrowsSliderStatus={arrowsSliderStatus}
        slideToRight={slideToRight}
        slideToLeft={slideToLeft}
      />

      <ReactImageGallery
        ref={imageGallery}
        items={investedUnitsItems}
        showNav={false}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        autoPlay={false}
        infinite={false}
        onSlide={(index) => {
          console.log(index);
          updateArrowSlideStatus(index);
        }}
      />
    </div>
  ) : investedUnitsItems === 0 ? (
    <div
      style={{
        position: "relative",
        margin: "0 auto ",
        padding: "90px 0",
        boxSizing:'initial',
        height: "40px",
        width: "40px",
      }}
    >
      <Loader />
    </div>
  ) : null;
}
