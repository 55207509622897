import "./style.css";
import store from "../../store/store";

import UnvPic from "../../assets/images/common/building-construction.png";
import BackPage from "../../assets/images/common/back-page.png";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

const selectWindowInnerSize = (state) => state.windowInnerSize;
export default function Unvailable() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();
  return (
    <div className="Unvailable Page" style={{ height: windowInnerSize.height }}>
      <img
        src={BackPage}
        alt=""
        className="back-btn"
        style={{ top: "5vw" }}
        onClick={() => {
          navigate(-1);
        }}
      />
      <div
        className="Page--flex"
        style={{ justifyContent: "center", minHeight: windowInnerSize.height }}
      >
        <img
          src={UnvPic}
          className="Unvailable__Img"
          alt="Building construction"
        />
        <p className="Unvailable__Txt unnamed-character-style-1">
          Under construction
          <br />
          Coming soon
        </p>
      </div>
    </div>
  );
}
