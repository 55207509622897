
import store from '../store/store';
// import { persistor } from '../../../store';
import {API_URL} from '../config.js';

// import unauthorized from "../../../functions/unauthorized";
// import { param } from "jquery";

export default function loadBalance(tokens) {

    var axios = require('axios');

    var config = {
        method: 'get',
        url: API_URL + '/api/investors/balance',
        headers: {
            Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        }
    };

    axios(config)
        .then(async function (response) {
            // console.log(JSON.stringify(response.data));
            await store.dispatch({ type: 'app/walletWithdrawValue/add', payload: response.data.balance })
            // await persistor.purge();
        })
        .catch(function (error) {
            // console.log(error);
        });

}