import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import { ROLES } from "../../../tools/role";

import AgentIPOed from "./agentIPOed";
import AgentNotIPOed from "./agentNotIPOed";
import Influencer from "./influencer";
import Investor from "./investor";

import loadUser from "../../../loaders/user";
import ListingApproval from "../listing-approval";

const selectUser = (state) => state.user;
const selectTokens = (state) => state.tokens;

export default function ProfileLayout() {
  const user = useSelector(selectUser);
  const tokens = useSelector(selectTokens);

  const navigate = useNavigate();
  useEffect(() => {
    loadUser(tokens);
  }, []);

  // console.log(user);
  if (user) {
    if (user.accountType & ROLES.MANAGER) {
      if (user.accountType & ROLES.ISSUER) {
        return <AgentIPOed />;
      } else {
        return <AgentNotIPOed />;
      }
    }
    if (user.accountType & ROLES.ISSUER) {
      navigate("/listing-approval");
      return /*<Influencer />*/;
    }
    if (user.accountType & ROLES.INVESTOR) {
      return <Investor />;
    }
  }
}
