import "./style.css";
import { AnimateKeyframes } from "react-simple-animate";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLES } from "../../../../../../tools/role";
import store from "../../../../../../store/store";

import BackPage from "../../../../../../assets/images/common/back-page.png";
import DeleteIcon from "../../../../../../assets/images/menu/delete.png";

const selectProfile = (state) => state.profile;
export default function DeleteAccount_Message(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="DeleteAccount Page headline">
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            position: "relative",
            marginTop: "20vw",
          }}
        >
          <img
            src={BackPage}
            alt=""
            // id=""
            className="back-btn"
            style={{
              position: "relative",
              top: 0,
              left: 0,
              marginRight: "12px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <h1
            className="headline__menu"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete account"
              // className="Menu_AccountAndSetting__Icon"
            />
            Delete Account
          </h1>
        </div>
        <p style={{ fontSize: "16px", lineHeight: "22px",fontWeight:'lighter',textAlign:'center',margin:'20px 5vw' }}>
          Before proceeding with deleting your account, please note that this
          action is permanent and cannot be undone.
        </p>
        <Link
          to={
            profile.accountType & ROLES.INVESTOR
              ? "/delete-account/investor"
              : profile.accountType & ROLES.ISSUER
              ? "/delete-account/issuer-story-mention"
              : "/delete-account/we-are-sorry"
          }
          state={"delete-account"}
        >
          <button className="DeleteAccount__Btn headline">Delete account</button>
        </Link>
      </div>
    </AnimateKeyframes>
  );
}
