import "./style.css";
import { AnimateKeyframes } from "react-simple-animate";
import { useState } from "react";
import { useNavigate } from "react-router";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";

import Tabs from "../../../components/common/Tabs";
import MyBrandStats from "./components/Stats";
import MyBrandManagement from "./components/Management";

export default function MyBrand(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });

  const navigate = useNavigate();

  const [tabSelected, settabSelected] = useState("Stats");

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="MyBrand Page headline"
        // style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          onClick={() => {
            navigate("/profile");
          }}
          style={{ top: "5vw" }}
        />

        <p className="MyBrand__Headline glow-yellow-white unnamed-character-style-1">
          MY BRAND
        </p>

        <Tabs
          tab1={"Stats"}
          tab2={"Management"}
          state={tabSelected}
          setState={settabSelected}
        />
        {tabSelected === "Stats" ? <MyBrandStats /> : <MyBrandManagement />}
      </div>
    </AnimateKeyframes>
  );
}
