import "./style.css";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/export default function StockDataMetrix(props) {
  function createItem(key, value) {
    return (
      <div className="StockDataMetrix__Item headline">
        <p>{key}</p>
        <span>{value}</span>
      </div>
    );
  }
  function getItems() {
    // var metrix = { Growth: "someVal", Profit: "someVal",Frequency: "Highest Price","Highest Price": "someVal", Views: "someVal",Volume: "otherVal" }; /*props.metrix*/
    let elements = [];
    for (const [key, value] of Object.entries(props.metrix)) {
      // // console.log(key + ": " + value); // 'first: someVal', 'second: otherVal'
      elements.push(createItem(key, value));
    }
    return elements;
  }
  return <div className="StockDataMetrix">{getItems()}</div>;
}
