import "./style.css";
import React from "react";
/*import {API_URL} from '@src/config.js';*/ import store from "../../../store/store";
import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { Widget } from "@typeform/embed-react";
import { useNavigate } from "react-router";
import BackPage from "../../../assets/images/common/back-page.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function IssuerRegTypeForm(props) {
  const navigate = useNavigate();
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const widgetContainerStyle = {
    width: windowInnerSize.width,
    height: windowInnerSize.height,
    margin: "0 auto",
  };
  const handleOnSubmit = ({ formId, responseId }) => {
    console.log(`Form ${formId} submitted, response id: ${responseId}`);
    store.dispatch({
      type: "app/typeFormIds/add",
      payload: { formId: formId, responseId: responseId },
    });
    navigate("/creator-registration/brand-calculator-response",{replace:true});
  };
  return (
    <div>
      <img
        src={BackPage}
        alt=""
        className="back-btn"
        style={{ top: "10vw" }}
        onClick={() => {
          navigate(-1);
        }}
      />
      <Widget
        id={"o47Ud6KY"}
        top={0}
        inlineOnMobile={true}
        fullScreen={true}
        style={widgetContainerStyle}
        medium="demo-test"
        hidden={{ foo: "foo value", bar: "bar value" }}
        transitiveSearchParams={["foo", "bar"]}
        iframeProps={{ title: "Foo Bar" }}
        onSubmit={handleOnSubmit}
      />
    </div>
  );
}
