import "./SignUpDemoForm.css";

import store from "../../../../store/store";
import React from 'react';
import {API_URL} from '../../../../config.js';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";

// import isProcessConsistent from "../../../../utiles/isProcessConsistent";

import "react-phone-input-2/lib/style.css";

import smiley from "../../../../assets/images/common/smiley-1.png";

// import PhoneNumCountries from "./PhoneNumCountries.json";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function SignUpDemoForm(props) {
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  //LoaderSU
  const [ShowLoader, setShowLoader] = useState(false);
  //signup server error
  const [ServerError, setServerError] = useState("");


  useEffect(() => {
    console.log(API_URL);
    let input = document.getElementById("sign-up-form");
    input.addEventListener("focusout", (event) => {
      window.scrollTo(0, 0);
    });
  }, []);

  function handleGoogleResponse(response) {
    var axios = require("axios");
    var data = JSON.stringify({
      accessToken: response.credential,
      clientId: response.clientId,
    });

    var config = {
      method: "post",
      url: API_URL + "/auth/google-login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        store.dispatch({
          type: "domain/user/addData",
          payload: response.data.user,
        });

        if (response.data.user.accountType < 4) {
          store.dispatch({
            type: "domain/tokens/addRefreshToken",
            payload: response.data.refreshToken,
          });
          store.dispatch({
            type: "domain/signUpToken/addToken",
            payload: response.data.accessToken,
          });
          navigate("/auth/sign-up/confirmation/t" + `&` + "cs", {
            state: { process: "SIGNUP" },
          });
        } else {
          store.dispatch({
            type: "domain/tokens/addTokens",
            payload: response.data,
          });
          navigate("/profile", { replace: true });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  useEffect(() => {
    /* global google*/
    if (google) {
      google.accounts.id.initialize({
        client_id:
          "60708295640-ojtlj1rfi2juh81521vttbbqt0ruq45s.apps.googleusercontent.com",
        callback: handleGoogleResponse,
      });

      google.accounts.id.renderButton(document.getElementById("GoogleSignIn"), {
        theme: "outline",
        size: "large",
        // width:store.getState().windowInnerSize.width/100*90,
        // type: "icon",
        select_by: "btn",
        width: (windowInnerSize.width / 100) * 84,
        shape: "circle",
      });
    }
  }, []);

  function logInByRole(role) {
    var axios = require("axios");

    var config = {
      method: "get",
      url: API_URL + "/auth/fast-login",
      params: {
        role: role,
      },
    };

    axios(config)
      .then(async function (response) {
        console.log(JSON.stringify(response.data));
        store.dispatch({
          type: "domain/user/addData",
          payload: response.data.user,
        });
        if (response.data.user.accountType < 4) {
          store.dispatch({
            type: "domain/signUpToken/addToken",
            payload: response.data.accessToken,
          });
          store.dispatch({
            type: "domain/tokens/addRefreshToken",
            payload: response.data.refreshToken,
          });
          // setdisplayContinueRegisterMsg(true);
        } else {
          store.dispatch({
            type: "domain/tokens/addTokens",
            payload: response.data,
          });
          navigate("/profile", { replace: true });
        }
      })
      .catch(function (error) {
        // console.log(error);
        // setShowLoader(false);
        // if (error.response?.data?.message) {
        //   setServerError(error.response?.data?.message);
        // } else {
        //   setServerError("An error occurred, please try again.");
        // }
      });
  }

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={props.clickAuthNav ? 1 : 0}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(-100vw)",
        "transform: translateX(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        id="sign-up-form"
        className={
          "SignUpDemoForm " +
          (!props.animateSignUpForm ? "animate-to-right" : "") +
          " headline"
        }
        style={{
          minHeight: windowInnerSize.height*0.95,
          // paddingBottom: "3vh",
        }}
      >
        <p className="SignUpForm__HiTxt">
          Hi
          <img src={smiley} alt="" className="SignUpForm__Emoji" />
        </p>
        <div>
          {/* <p
            style={{
              fontSize: "6vw",
              lineHeight: "7.5vw",
              fontWeight: "lighter",
              textAlign: "center",
            }}
          >
            Demo version
          </p> */}
          <p
            style={{
              fontSize: "4vw",
              lineHeight: "4.5vw",
              fontWeight: "lighter",
              textAlign: "center",
              // marginBottom:"0",
              color: "b3b3b3",
            }}
          >
            Sample accounts
          </p>

          <div className="SignUpDemoForm__BtnsContainer ">
            <button
              className="SignUpDemoForm__SampleAcoountBtn btn--white-yellow headline"
              onClick={() => {
                logInByRole("issuer");
              }}
            >
              Creator
            </button>
            <button
              className="SignUpDemoForm__SampleAcoountBtn btn--white-yellow headline"
              onClick={() => {
                logInByRole("imanager");
              }}
            >
              Manager
            </button>
            <button
              className="SignUpDemoForm__SampleAcoountBtn btn--white-yellow headline"
              onClick={() => {
                logInByRole("investor");
              }}
            >
              Investor
            </button>
          </div>
          <p
            style={{
              fontSize: "4vw",
              lineHeight: "4.5vw",
              fontWeight: "lighter",
              textAlign: "center",
              // marginBottom:"0",
              color: "b3b3b3",
            }}
          >
            or
          </p>
          <div id="GoogleSignIn" style={{ display: "inline" }}></div>
        </div>
        {/* <span id="SignUpByFacebbok">
                    <p id="FacebbokTxtBtn">Facebook</p>
                    <img src={FacebookLogoSign} alt="" id="FacebookLogoLog" />
                </span>
                <p id="LogInByGoogle">Google</p> */}
      </div>
    </AnimateKeyframes>
  );
}
function revelPassword(inputId) {
  var x = document.getElementById(inputId);
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
}
// function getPhoneNumCountries() {
//   let PNCElements = [];
//   for (let i = 0; i < PhoneNumCountries.countries.length; i++) {
//     PNCElements.push(
//       <option
//         value={PhoneNumCountries.countries[i].code}
//         selected={PhoneNumCountries.countries[i].code === "+27" ? true : null}
//       >
//         {PhoneNumCountries.countries[i].name}
//         {PhoneNumCountries.countries[i].code}
//       </option>
//     );
//   }
//   return PNCElements;
// }
function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
