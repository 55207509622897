import "./style.css";
import { useSelector } from "react-redux";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import store from "../../../../../store/store";

import Search from "../../../../../assets/images/common/search.png";

import searchForSMI from "../../loaders/searchForSMI";

const selectTokens = (state) => state.tokens;

export default function ExSearchBar(props) {
  const tokens = useSelector(selectTokens);

  return (
    <div className="ExSearchBar">
      <input
        className="Exchange__SearchSMI headline"
        type="search"
        id="Exchange__SearchSMI"
        style={{ backgroundImage: `url(${Search})` }}
        name="SearchInv"
        placeholder="Search for your next investment"
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            if (e.target.value.length > 0) {
              // switch to search board:
              store.dispatch({
                type: "ui/exchangeSearchDM/toggle",
                payload: true,
              });
              // setShowLoaderSearch(true);
              // setSearchInputText(e.target.value);
              
              // clear old data:
              store.dispatch({
                type: "app/salesFeedWaitingForData/toggle",
                payload: true,
              });
              store.dispatch({
                type: "app/salesFeedPageIndex/add",
                payload: 1,
              });
              store.dispatch({
                type: "app/exchangeSearchSMIName/add",
                payload: e.target.value,
              });
              searchForSMI(e.target.value, tokens, 1);
            }
          } else {
            //   setShowLoaderSearch(false);
            //   setSearchInputText(null);
            //   navigate("/exchange"); // to restart the board
            // setSearchInputText(e.target.value);
          }
        }}
      />
    </div>
  );
}
