import { Link, useLocation, useNavigate } from "react-router-dom";
import { AnimateKeyframes } from "react-simple-animate";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import "./style.css";
import store from "../../../../store/store";

import SaleOfferCard from "../../../../components/common/SaleOfferCard";

import BackPage from "../../../../assets/images/common/back-page.png";
// import ArrowUp from "../../images/icons/ArrowUp.png";
// import ArrowDown from "../../images/icons/ArrowDown.png";
import Done from "../../../../assets/images/common/confirm.png";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import isVarNegative from "../../../../helpers/isVarNegative";

const selectAddP2Psale = (state) => state.createTradeP2P;
const selectProfile = (state) => state.profile;

export default function CreateTradeConfirmation(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });

  const navigate = useNavigate();
  // prevent enter to the page by insert the url in undesirable cases
  const profile = useSelector(selectProfile);
  const location = useLocation();
  const [processController, setProcessController] = useState(null);
  useEffect(() => {
    if (!isVarNegative(profile)) {
      navigate("/");
    } else {
      if (location.state?.process !== "CELL") {
        navigate("/exchange");
      } else {
        setProcessController(true);
      }
    }
  }, [location, profile]);

  //   let { Stock } = useParams();

  const sale = useSelector(selectAddP2Psale);
  // console.log(sale);

  // const [VolumePrice, setVolumePrice] = useState({ volume: "", price: "" })
  // useEffect(() => {
  //     if (props.CreateOffersaleData !== null) {
  //         setVolumePrice(props.CreateOffersaleData);
  //         // console.log(props.CreateOffersaleData.volume);
  //     }
  // }, [props.CreateOffersaleData]);

  const [cardData, setcardData] = useState(null);
  useEffect(() => {
    if (sale) {
      //praper the stock data for 'saleOfferCard':
      let tempCardData = {
        stock: sale.stock.stock,
        id: sale.sale.id,
        isIpo: false,
        seller: {
          profilePic: store.getState().profile.profilePic,
        },
        volume: sale.sale.volume,
        price: sale.sale.price,
        currency: sale.sale.currency.symbol,
      };
      setcardData(tempCardData);
    }
  }, [sale]);

  return (
    processController &&
    cardData&&(
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(100vw)",
          "transform: translateX(50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div className="CreateTradeConfirmation Page headline">
          <div className="Page--flex">
            <Link to="/exchange">
              <img
                src={BackPage}
                alt=""
                className="CreateTradeConfirmation__BackBtn"
              />
            </Link>
            <div>
              <p className="CreateTradeConfirmation__TradeTxt unnamed-character-style-1">
                TRADE <br /> SUCCESFULLY <br /> CREATED
              </p>
              <SaleOfferCard SaleOffer={cardData} />
            </div>
            <Link to={"/exchange"}>
              <img
                src={Done}
                alt=""
                className="CreateTradeConfirmation__DoneBtn"
              />
            </Link>
          </div>
        </div>
      </AnimateKeyframes>
    )
  );
}
