import './style.css';
import React from 'react';
/*import {API_URL} from '@src/config.js';*/

import store from '../../../../store/store';

import BackPage from '../../../../assets/images/common/back-page.png';
import Done from '../../../../assets/images/common/confirm.png';

import { Link } from 'react-router-dom';
export default function DepositSuccess(props) {
 store.dispatch({
        type: 'ui/navBarsDM/toggle',
        payload: { top: true, bottom: false },
      });
      
    return (
        <div id="DepositSuccesedDiv" className="Page headline">
            <Link to="/wallet">
                <img src={BackPage} alt="" className='WithdrawRequestReceived__BackBtn'/>
            </Link>
            <p id="DepositSTxt" className="unnamed-character-style-1">DEPOSIT <br /> SUCCESSFULLY <br /> RECEIVED</p>
            <p id="HappyTTxt">Happy trading!</p>
            <svg height="2vw" width="33%" id="LineDepositSuccesed">
                <line x1="0" x2="100%" y1="0" y2="0" stroke="white" stroke-width="100%" stroke-linecap="butt" />
            </svg>
            <Link to="/wallet">
                <img src={Done} alt="" id="DoneDS" />
            </Link>
        </div>
    )
}
