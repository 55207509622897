import "./style.css";
import OpeningLogo from "../../assets/images/common/ps-logo-2-rows.png";
import React from 'react';
/*import {API_URL} from '@src/config.js';*/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

import store from "../../store/store";

const selectOpeningViewDM = (state) => state.openingViewDM;

export default function OpeningView(props) {
  // const isDisplay = useSelector(selectOpeningViewDM);
  // const navigate = useNavigate();

  //   useEffect(async () => {
  //     // console.log("ooo " + navigate.length);
  //     if (+navigate.length <= 7) {
  //       await store.dispatch({
  //         type: "appConfig/hasOpeningViewDisplayed",
  //         payload: false,
  //       });
  //       await persistor.purge();
  //     }
  //     setTimeout(async () => {
  //       await store.dispatch({
  //         type: "appConfig/hasOpeningViewDisplayed",
  //         payload: true,
  //       });
  //       await persistor.purge();
  //     }, 4000);
  //   }, []);

  //   useEffect(() => {
  //     // console.log(openingView);
  //   }, [openingView]);

  const [isDisplay, setisDisplay] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      // store.dispatch({ type: "ui/openingViewDM/toggle", payload: false });
      setisDisplay(false);
    }, 4000);
  }, []);

  return (
    isDisplay && (
      <div id="OpeningViewDiv">
        <img src={OpeningLogo} alt="PassionStocks logo" id="OpeningLogo" />
      </div>
    )
  );
}
