import "./style.css";
import { AnimateKeyframes } from "react-simple-animate";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import store from "../../../../../../store/store";
import BackPage from "../../../../../../assets/images/common/back-page.png";
import DeleteIcon from "../../../../../../assets/images/menu/delete.png";

export default function DeleteAccount_Confirmation(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state !== "delete-account") {
      navigate("/menu");
    }
  }, [location]);
  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="DeleteAccount Page headline">
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            position: "relative",
            marginTop: "20vw",
          }}
        >
          <img
            src={BackPage}
            alt=""
            // id=""
            className="back-btn"
            style={{
              position: "relative",
              top: 0,
              left: 0,
              marginRight: "12px",
            }}
            onClick={() => {
              navigate("/");
            }}
          />
          <h1
            className="headline__menu"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete account"
              // className="Menu_AccountAndSetting__Icon"
            />
            Delete Account
          </h1>
        </div>

        <p
          style={{
            fontSize: "20px",
            lineHeight: "27px",
            color: "#34c100",
            fontWeight: "lighter",
            margin: "20px 20vw",
            textAlign: "center",
          }}
        >
          Your account has been successfully deleted.
        </p>
        <p style={{ fontSize: "15px", lineHeight: "20px",  fontWeight: "lighter",
            margin: "40px 12vw",
            textAlign: "center",}}>
          If you encountered any issues or have any feedback, please do not
          hesitate to contact us.
        </p>
        <div className="Support__txt">
          <span>please contact us at our support center here.</span>
          <br />
          <br />
          <p>
            or
            <br />
            <br />
            send us an email to
            <br />
            <span>info@passionstocks.com</span>
          </p>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
