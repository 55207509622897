import React from 'react';
/*import {API_URL} from '@src/config.js';*/
import './style.css'

// import AgencyAvatarImage from '../../../images/icons/AgencyAvatarImage.png';
import FullStar from '../../../assets/images/common/star-full.png';
import EmptyStar from '../../../assets/images/common/star-empty.png';
import store from '../../../store/store';


export default function AgencyMemberTag(props) {

    return (
        <div id="AgencyMemberTagContainer">
            <p id="AgencyNameTxt" className="headline">{props.AgencyDataAV ? props.AgencyDataAV.agency_name : props.OwnProfile ? store.getState().profile.influencer.agent.user.name : props.Influencers3rdProfile ? props.Influencers3rdProfile.agency.agency_name : "Alpha 1 "} Member</p>

            <div id="AgencyAvatarImage" style={{ backgroundImage: `url(${props.AgencyDataAV ? 'https://web.passionstocks.com/' + props.AgencyDataAV.profile_photo_path : props.OwnProfile ? store.getState().profile.influencer.agent.user.profilePic : props.Influencers3rdProfile ? 'https://web.passionstocks.com/' + props.Influencers3rdProfile.agency.profile_photo_path : null})` }} />

            {/* <img src={props.AgencyDataAV ? 'https://web.passionstocks.com/' + props.AgencyDataAV.profile_photo_path : props.OwnProfile ? 'https://web.passionstocks.com/' + store.getState().profile.influencer.agency.profile_photo_path : AgencyAvatarImage} alt="" id="AgencyAvatarImage" /> */}
            <div id="StarsPosition">
                {getRateStars(props.AgencyDataAV ? props.AgencyDataAV.ranking : props.OwnProfile ? 1/*store.getState().profile.influencer.agent.user.ranking*/ : props.Influencers3rdProfile ? props.Influencers3rdProfile.ranking : 2)}
            </div>
        </div>
    );
}
function getRateStars(Rating) {

    let FullStars = Rating;
    let EmptyStars = 5 - Rating;
    let StarsCounter = 5;
    let StarsLine = [];

    for (let i = 0; i < FullStars; i++, StarsCounter--) {
        StarsLine.push(
            <img key={StarsCounter} className="AgencyTagRateStars" src={FullStar} alt="" id="FullStar" />
        )
    }
    for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
        StarsLine.push(
            <img key={StarsCounter} className="AgencyTagRateStars" src={EmptyStar} alt="" id="FullStar" />
        )
    }

    return StarsLine;
}