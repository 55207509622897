import "./style.css";
import store from "../../../../../store/store.js";
import { AUTH } from "../../../../../tools/authentication.js";
import React from 'react';
import {API_URL} from '../../../../../config.js';

import BackPage from "../../../../../assets/images/common/back-page.png";
import Password from "../../../../../assets/images/menu/key.png";

import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useState } from "react";
import validatePasswordRules from "../../../../../helpers/validatePasswordRules.js";
import { useEffect } from "react";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectProfile = (state) => state.profile;
const selectUser = (state) => state.user;
const selectTokens = (state) => state.tokens;

export default function AccountAndSetting_ChengePassword(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Menu",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  const profile = useSelector(selectProfile);
  const user = useSelector(selectUser);
  const tokens = useSelector(selectTokens);

  const [currentPassword, setcurrentPassword] = useState(null);
  const [newPassword, setnewPassword] = useState(null);
  const [confirmPassword, setconfirmPassword] = useState(null);
  const [isAuthWith3rdParty, setisAuthWith3rdParty] = useState(null);

  function handleGoogleResponse(response) {
    var axios = require("axios");
    var data = JSON.stringify({
      accessToken: response.credential,
      clientId: response.clientId,
    });

    var config = {
      method: "post",
      url: API_URL + "/auth/google-login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if (response.data.user.id === user.id) {
          // console.log(true);
          store.dispatch({
            type: "domain/user/addData",
            payload: response.data.user,
          });
          store.dispatch({
            type: "domain/tokens/addTokens",
            payload: response.data,
          });
          setisAuthWith3rdParty(true);
        } else {
          //not the same account was auth...
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  useEffect(() => {
    /* global google*/
    if (profile.authentication & AUTH.GOOGLE) {
      google.accounts.id.initialize({
        client_id:
          "60708295640-ojtlj1rfi2juh81521vttbbqt0ruq45s.apps.googleusercontent.com",
        callback: handleGoogleResponse,
      });

      google.accounts.id.renderButton(document.getElementById("GoogleSignIn"), {
        theme: "outline",
        size: "large",
        // width:store.getState().windowInnerSize.width/100*90,
        // type: "icon",
        select_by: "btn",
        width: (windowInnerSize.width / 100) * 84,
        shape: "circle",
      });
    }
  }, []);

  const handleSubmitNewPassword = () => {
    if (
      newPassword &&
      newPassword !== "" &&
      newPassword === confirmPassword &&
      ((currentPassword && currentPassword !== "") || isAuthWith3rdParty)
    ) {
      // console.log(currentPassword);
      var axios = require("axios");
      var data = JSON.stringify({
        oldPassword:
          currentPassword && currentPassword !== "" ? currentPassword : undefined,
        password: newPassword,
      });

      var config = {
        method: "post",
        url: API_URL + "/auth/change-password",
        headers: {
          "Content-Type": "application/json",
          Authorization: tokens.accessToken, // can't call hooks from this function (to get the accessToken)
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          navigate("/profile");
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };
  return (
    <div
      className="AcAnsSe_ChangePassword Page headline"
      style={{ height: windowInnerSize.height }}
    >
      <img
        src={BackPage}
        alt=""
        className="back-btn"
        onClick={() => {
          navigate(-1);
        }}
      />
      <p className="AcAnsSe_ChangePassword__Title">Change Password</p>
      <div className="Page--flex" style={{ minHeight: windowInnerSize.height }}>
        <div style={{ margin: "25vw 0 5vh" }}>
          <div style={{ position: "relative", height: "9vw" }}>
            <img src={Password} alt="" className="AcAnsSe_ChangePassword__KeyIcon" />
            <p className="AcAnsSe_ChangePassword__PasswordTxt">Password</p>
          </div>
          {profile.authentication & AUTH.PASSWORD ? (
            <input
              type="password"
              placeholder="Current password"
              className={
                "AcAnsSe_ChangePassword__CurrentPInput input-default " +
                (validatePasswordRules(currentPassword) ||
                currentPassword === null
                  ? ""
                  : "input-wrong")
              }
              onChange={(e) => {
                setcurrentPassword(e.target.value);
              }}
            />
          ) : (
            <p className="AcAnsSe_ChangePassword__YourAccountTxt">
              Your account has been verified by{" "}
              {profile.authentication & AUTH.GOOGLE ? "Google " : ""}
              {profile.authentication & AUTH.GOOGLE &&
              profile.authentication & AUTH.FACEBOOK
                ? " and "
                : ""}
              {profile.authentication & AUTH.FACEBOOK ? "Facebook " : ""}
              Verification.
              <br />
              If you want to add a login password manually, please verify your
              account again and enter a new password.
            </p>
          )}
          {profile.authentication & AUTH.GOOGLE &&
            profile.authentication & AUTH.PASSWORD && (
              <p className="AcAnsSe_ChangePassword__YourAccountTxt">or</p>
            )}
          {profile.authentication & AUTH.GOOGLE && !isAuthWith3rdParty ? (
            <div
              id="GoogleSignIn"
              style={{ marginLeft: "10vw", width: "90vw" }}
            ></div>
          ) : (
            <p
              className="AcAnsSe_ChangePassword__YourAccountTxt"
              style={{ fontWeight: "bolder", color: "#ffbf12" }}
            >
              Verified!
            </p>
          )}

          <input
            type="password"
            placeholder="New password"
            className={
              "AcAnsSe_ChangePassword__NewPInput input-default " +
              (validatePasswordRules(newPassword) || newPassword === null
                ? ""
                : "input-wrong")
            }
            onChange={(e) => {
              setnewPassword(e.target.value);
            }}
          />
          <input
            type="password"
            placeholder="New password, again"
            className={
              "AcAnsSe_ChangePassword__ConfirmPInput input-default " +
              ((validatePasswordRules(confirmPassword) &&
                confirmPassword === newPassword) ||
              confirmPassword === null
                ? ""
                : "input-wrong")
            }
            onChange={(e) => {
              setconfirmPassword(e.target.value);
            }}
          />
        </div>
        <p className="AcAnsSe_ChangePassword__UpdatePBtn" onClick={handleSubmitNewPassword}>
          Update Password
        </p>
      </div>
    </div>
  );
}
