import {API_URL} from '../config.js';

import store from '../store/store';
// import { persistor } from '../store/store';

export default function load3rdProfile(tokens, influencerId) {
    var axios = require('axios');

    var config = {
        method: 'get',
        url: API_URL + '/api/influencers/'+influencerId,
        headers: {
            Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        }
    };

    axios(config)
        .then( function (response) {
            // console.log(JSON.stringify(response.data));
            store.dispatch({ type: 'domain/influencer3rdProfile/add', payload: response.data })
            // await persistor.purge();
        })
        .catch(function (error) {
            // console.log(error);
        });

}